import styled from "@emotion/styled";

export const HomePage = styled.div`
  color: white;
  font-family: "Inter", sans-serif;
  text-align: center;
  overflow: hidden;

  * {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }
`;
export const TopSection = styled.div`
  padding: 150px 20px 0;
`;
export const TopSectionMainText = styled.h1`
  font-size: 3rem;
  line-height: 1.33;

  span {
    color: var(--home-text-green);
  }

  ${({ theme }) => theme.breakpoints.up("sm")} {
    font-size: 3.75rem;
  }
`;
export const TopSectionSubText = styled.p`
  font-size: 1.25rem;
  max-width: 850px;
  margin: 100px auto 0;
  font-weight: 500;
  line-height: 1.78;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    font-size: 1.75rem;
  }

  a {
    color: var(--home-text-green);
    text-decoration: underline;
    font-weight: 900;
  }
`;
export const TopSectionButtonsContainer = styled.div`
  margin: 100px auto 0;
  display: flex;
  justify-content: center;
`;
const StyledButton = `
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  flex-shrink: 0;
  border-radius: 4px;
  text-decoration: none;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
`;
export const CreateProjectButton = styled.button`
  width: 150px;
  background-color: var(--home-text-green);
  color: var(--home-text-gray);
  margin-right: 20px;
  cursor: pointer;
  border: none;

  ${StyledButton}

  ${({ theme }) => theme.breakpoints.up("sm")} {
    width: 200px;
    font-size: 1.25rem;
    margin-right: 30px;
  }
`;
export const LogInLink = styled.a`
  width: 150px;
  background-color: var(--home-text-green);
  color: var(--home-text-gray);
  margin-right: 20px;

  ${StyledButton}

  ${({ theme }) => theme.breakpoints.up("sm")} {
    width: 200px;
    font-size: 1.25rem;
    margin-right: 30px;
  }
`;

export const LearnMMLLink = styled.a`
  width: 150px;
  background-color: var(--home-text-gray);
  color: var(--home-button-green);
  ${StyledButton}

  ${({ theme }) => theme.breakpoints.up("sm")} {
    width: 200px;
    font-size: 1.25rem;
  }
`;
export const TopSectionTopImageContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100px;
`;
export const TopSectionTopImage = styled.img`
  width: 1068px;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    max-width: 1458px;
    width: 100%;
  }
`;
export const TomSectionTopImageOpacityOverlay = styled.div`
  height: 601px;
  width: 60px;
  position: absolute;
  top: 100px;
  right: -20px;
  background: linear-gradient(270deg, #121212 0%, rgba(18, 18, 18, 0) 100%);

  ${({ theme }) => theme.breakpoints.up("sm")} {
    display: none;
  }
`;
export const TopSectionBottomImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 601px;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    display: none;
  }
`;
export const TopSectionBottomImage = styled.img`
  width: 1068px;
  position: absolute;
  right: 0;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    display: none;
  }
`;
export const TopSectionBottomImageOpacityOverlay = styled(
  TomSectionTopImageOpacityOverlay,
)`
  top: 0;
  background: linear-gradient(270deg, rgba(18, 18, 18, 0) 0%, #121212 100%);
  left: -20px;
`;
export const MidSection = styled.div`
  background-color: var(--home-background-green);
  margin-top: 150px;
  padding: 120px 20px 150px;
`;

export const SecondaryText = styled.h2`
  margin: 0 auto;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75;
  white-space: pre-wrap;

  span {
    color: var(--home-text-green);
  }

  ${({ theme }) => theme.breakpoints.up("sm")} {
    font-size: 2.5rem;
  }
`;

export const LearnMMLLinkContainer = styled.a`
  margin-top: 20px;
  display: inline-flex;
  border-radius: 4px;
  width: 140px;
  height: 40px;
  border: 2px solid var(--home-text-green);
  color: var(--home-text-green);
  text-align: center;
  font-weight: 700;
  align-items: center;
  justify-content: center;
`;

export const ExamplesSection = styled.div`
  margin: 120px 0;
  padding: 0 20px;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    padding: 0 40px;
  }
`;

export const ExamplesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  ${({ theme }) => theme.breakpoints.up("md")} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 14px;

    max-width: 1456px;

    > * {
      flex: 0 0 350px;
    }
  }
`;
