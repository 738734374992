import styled from "@emotion/styled";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import * as React from "react";

import Icon from "~/components/Icon";
import URLField from "~/components/URLField";
import config from "~/config";
import appState from "~/library/appState";
import { projectSlug } from "~/library/projectSlug";
import serverApi from "~/library/serverApi/serverApi";

const Divider = styled.hr({
  opacity: 0.2,
  margin: "24px 0",
});

const Notice = styled.div({
  width: "100%",
  padding: "10px",
  backgroundColor: "#2D2600",
  display: "flex",
  alignItems: "center",
  borderRadius: "4px",
});

const NoticeText = styled(DialogContentText)({
  color: "#EB8D2F",
  fontWeight: 500,
  fontSize: "14px",
});

const CustomText = styled(DialogContentText)({
  a: {
    color: "white",
  },
});

const BottomButtonsContainer = styled.div({
  display: "flex",
  gap: 10,
});

const ShareProjectModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  if (!appState.project) return null;

  const project = appState.project;

  const playViewUrl = `${window.location.href}/play`;
  const documentWebsocketUrl = `${window.serverConfig.EXECUTION_URL}/${appState.project.id}`;

  const toggleProjectPublic = async () => {
    const res = await serverApi.updateProjectPublic({
      parameters: {
        projectId: project.id,
      },
      body: {
        isPublic: !project.isPublic,
      },
    });
    if (res.code === 200) {
      project.isPublic = res.body.isPublic;
    }
  };

  const toggleProjectPublished = async () => {
    const res = await serverApi.updateProjectPublished({
      parameters: {
        projectId: project.id,
      },
      body: {
        isPublished: !project.isPublished,
      },
    });
    if (res.code === 200) {
      project.isPublished = res.body.isPublished;
    }
  };

  const handleCopyProjectUrl = () => {
    const url = `${window.location.origin}/projects/${projectSlug(project)}`;
    navigator.clipboard.writeText(url);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Project sharing</DialogTitle>

      <DialogContent>
        <Stack spacing={1}>
          <Typography>Public Play URL</Typography>
          <CustomText fontSize={13}>
            Let others easily connect to and interact with your live document.
          </CustomText>

          <URLField url={playViewUrl} />
        </Stack>

        <Divider />

        <Stack spacing={1}>
          <Typography>Document WebSocket URL</Typography>
          <CustomText fontSize={13}>
            Use this URL to add your live document into various spaces such as
            the{" "}
            <a
              href="https://github.com/mml-io/mml-playground"
              target="_blank"
              rel="noreferrer"
            >
              MML Playground
            </a>{" "}
            and{" "}
            <a
              href="https://construct.msquared.io/"
              target="_blank"
              rel="noreferrer"
            >
              Construct
            </a>
            , or to embed it into another document using an{" "}
            <a
              href="https://mml.io/docs/reference/elements/m-frame"
              target="_blank"
              rel="noreferrer"
            >
              m-frame
            </a>
            .
          </CustomText>

          <URLField url={documentWebsocketUrl} />

          <Notice>
            <NoticeText>
              This document is executing{" "}
              <em>
                <strong>in your browser</strong>
              </em>
              . Keep this window/tab open and visible on your screen to ensure
              continuous and performant document execution.
            </NoticeText>
          </Notice>
        </Stack>

        <Divider />

        <Stack spacing={1}>
          <Typography>
            Public access (currently {project.isPublic ? "public" : "private"})
          </Typography>
          <CustomText fontSize={13}>
            A public project allows anyone with the link to see its source code
            and fork it.
          </CustomText>
          <BottomButtonsContainer>
            <Button variant="contained" onClick={toggleProjectPublic}>
              Make project {project.isPublic ? "private" : "public"}
            </Button>
            {project.isPublic && (
              <Button
                startIcon={<Icon icon="copy" />}
                color="secondary"
                onClick={handleCopyProjectUrl}
              >
                Copy project url
              </Button>
            )}
          </BottomButtonsContainer>
        </Stack>

        {project.isPublic && config.features.publishProject && (
          <>
            <Divider />

            <Stack spacing={1}>
              <Typography>
                Discoverability (currently{" "}
                {project.isPublished ? "published" : "not published"})
              </Typography>
              <CustomText fontSize={13}>
                A published project will be discoverable via the{" "}
                <a href="/explore" target="_blank">
                  Explore
                </a>{" "}
                section of MML Editor.
              </CustomText>
              <div>
                <Button variant="contained" onClick={toggleProjectPublished}>
                  {project.isPublished ? "Unpublish" : "Publish"}
                </Button>
              </div>
            </Stack>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default observer(ShareProjectModal);
