import { mmlEditorAPISchema } from "mml-editor-api-schema";
import {
  createOpenAPIClient,
  OpenAPIAuthMiddleware,
} from "openapi-typescript-helpers";

import appState from "../appState";

const mmlEditorAPIUrl = window.serverConfig.API_SERVER_URL;

export async function getAccessToken() {
  if (
    !appState.accessToken ||
    (appState.accessTokenExpiresAt &&
      new Date(appState.accessTokenExpiresAt).getTime() <
        Date.now() + 1000 * 60)
  ) {
    // Token is expired or missing, refresh it
    await refreshApiAuth();
  }
  if (!appState.accessToken) {
    return null;
  }
  return appState.accessToken;
}

export const logOut = async () => {
  await fetch("/auth/logout", {
    method: "POST",
    credentials: "include",
  });

  appState.accessToken = undefined;
  appState.user = undefined;

  // Log out other tabs/windows
  localStorage.setItem("loggedOut", new Date().toString());

  window.location.href = "/";
};

const serverApi = createOpenAPIClient(
  mmlEditorAPISchema,
  {
    baseUrl: mmlEditorAPIUrl,
  },
  [OpenAPIAuthMiddleware(getAccessToken, logOut)],
);

const internalRefreshApiAuth = async () => {
  try {
    const res = await fetch("/auth/refresh", {
      method: "GET",
      credentials: "include",
    });
    const data = await res.json();

    if (!data.accessToken) {
      throw new Error("No access token in response");
    }

    appState.accessToken = data.accessToken;
    appState.accessTokenExpiresAt = Date.now() + data.expiresIn;
  } catch (e) {
    console.error("Failed to refresh API auth", e);
    appState.accessToken = undefined;
    appState.accessTokenExpiresAt = undefined;
  }
};

let latestRefreshPromise: Promise<void> | undefined;
export const refreshApiAuth = async () => {
  if (!latestRefreshPromise) {
    latestRefreshPromise = internalRefreshApiAuth().finally(() => {
      latestRefreshPromise = undefined;
    });
  }
  return latestRefreshPromise;
};

export default serverApi;
