import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import * as React from "react";

const Container = styled.div({
  width: "100%",
  height: "26px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const Text = styled(Typography)({
  textTransform: "uppercase",
  fontSize: "12px",
  color: "rgba(255, 255, 255, 0.5)",
  fontWeight: 700,
  lineHeight: 1,
});

const PanelHeading = ({
  children,
  rightContent,
}: {
  children?: React.ReactNode;
  rightContent?: React.ReactNode;
}) => {
  return (
    <Container>
      <div>
        <Text>{children}</Text>
      </div>
      <div>{rightContent}</div>
    </Container>
  );
};

export default PanelHeading;
