import { Change, Diff } from "diff";

const diffByAttribute = (oldStr: string, newStr: string) => {
  const attributeDiffer = new Diff();
  attributeDiffer.tokenize = function (value) {
    return value.split(/(["]|\s+|\r+)/);
  };

  return attributeDiffer.diff(oldStr, newStr);
};

export type Range = {
  start: number;
  end: number;
  value?: string;
};

const groupDiffChanges = (changes: Change[]): Range[] => {
  const diffGroups: Range[] = [];

  let index = 0;
  let currentGroup: Change[] = [];

  const processCurrentGroup = () => {
    // Process current group of changes
    if (currentGroup.length) {
      const addedChange = currentGroup.find((c) => c.added);
      const removedChange = currentGroup.find((c) => c.removed);

      // Replaced
      if (addedChange && removedChange) {
        diffGroups.push({
          start: index,
          end: index + removedChange.value.length,
          value: addedChange.value,
        });
        index += addedChange.value.length;
      }

      // Added
      else if (addedChange) {
        diffGroups.push({
          start: index,
          end: index,
          value: addedChange.value,
        });
        index += addedChange.value.length;
      }

      // Removed
      else if (removedChange) {
        diffGroups.push({
          start: index,
          end: index + removedChange.value.length,
        });
      }

      currentGroup = [];
    }
  };

  for (let i = 0; i < changes.length; i++) {
    const change = changes[i];
    const isLast = i === changes.length - 1;

    if (change.added || change.removed) {
      currentGroup.push(change);

      if (isLast) {
        processCurrentGroup();
      }
    } else {
      processCurrentGroup();

      index += change.value.length;
    }
  }

  return diffGroups;
};

export const attributeDiff = (
  stringA: string,
  stringB: string,
): Array<Range> => {
  const diff = diffByAttribute(stringA, stringB);
  const grouped = groupDiffChanges(diff);

  return grouped;
};
