const rootSchema = {
  openapi: "3.0.3",
  info: {
    title: "MML Editor Server API",
    description:
      "This API powers the MML Editor authentication and content management.",
    version: "0.5.0",
  },
  servers: [
    {
      url: "http://localhost:3000",
    },
  ],
  components: {
    securitySchemes: {
      BearerAuth: {
        type: "http",
        scheme: "bearer",
      },
    },
    schemas: {
      User: {
        title: "User",
        description: "Represents a user.",
        type: "object",
        properties: {
          id: {
            type: "string",
          },
          email: {
            type: "string",
          },
          name: {
            type: "string",
          },
          picture: {
            type: "string",
          },
        },
        required: ["id", "email"],
      },
      Change: {
        title: "Change",
        description: "A change made to a file.",
        type: "object",
        properties: {
          op: {
            type: "array",
            items: {
              anyOf: [
                {
                  type: "string",
                },
                {
                  type: "number",
                },
              ],
            },
          },
        },
        required: ["op"],
      },
      Revision: {
        title: "Revision",
        description: "A set of changes made to a file by a single user.",
        type: "object",
        properties: {
          id: {
            type: "string",
          },
          changes: {
            type: "array",
            items: {
              $ref: "#/components/schemas/Change",
            },
          },
          createdBy: {
            type: "string",
          },
          createdAt: {
            type: "string",
          },
        },
        required: ["id", "changes", "createdBy", "createdAt"],
      },
      Asset: {
        title: "Asset",
        description: "An asset such as an image that is part of a project.",
        type: "object",
        properties: {
          id: {
            type: "string",
          },
          name: {
            type: "string",
          },
          type: {
            type: "string",
          },
          size: {
            type: "number",
          },
          url: {
            type: "string",
          },
          createdBy: {
            type: "string",
          },
          createdAt: {
            type: "string",
          },
        },
        required: ["id", "name", "type", "createdBy", "createdAt"],
      },
      File: {
        title: "File",
        description: "A single MML source file.",
        type: "object",
        properties: {
          id: {
            type: "string",
          },
          name: {
            type: "string",
          },
          content: {
            type: "string",
          },
          createdBy: {
            type: "string",
          },
          createdAt: {
            type: "string",
          },
          updatedAt: {
            type: "string",
          },
          revisions: {
            type: "array",
            items: {
              $ref: "#/components/schemas/Revision",
            },
          },
        },
        required: [
          "id",
          "name",
          "content",
          "createdBy",
          "createdAt",
          "updatedAt",
        ],
      },
      StaticVersion: {
        title: "StaticVersion",
        description: "A published static state of a project.",
        type: "object",
        properties: {
          id: {
            type: "string",
          },
          name: {
            type: "string",
          },
          size: {
            type: "number",
          },
          path: {
            type: "string",
          },
          url: {
            type: "string",
          },
          createdBy: {
            type: "string",
          },
          createdAt: {
            type: "string",
          },
        },
        required: ["id", "name", "size", "path", "createdBy", "createdAt"],
      },
      Project: {
        title: "Project",
        description: "Top level entity within MML Editor.",
        type: "object",
        properties: {
          id: {
            type: "string",
          },
          name: {
            type: "string",
          },
          description: {
            type: "string",
          },
          createdBy: {
            type: "string",
          },
          createdByInfo: {
            type: "object",
            properties: {
              name: {
                type: "string",
              },
              picture: {
                type: "string",
              },
            },
          },
          forkedFrom: {
            type: "string",
          },
          createdAt: {
            type: "string",
          },
          updatedAt: {
            type: "string",
          },
          thumbnailId: {
            type: "string",
          },
          thumbnailUrl: {
            type: "string",
          },
          isPublic: {
            type: "boolean",
          },
          isPublished: {
            type: "boolean",
          },
          files: {
            type: "array",
            items: {
              $ref: "#/components/schemas/File",
            },
          },
          assets: {
            type: "array",
            items: {
              $ref: "#/components/schemas/Asset",
            },
          },
          tags: {
            type: "array",
            items: {
              type: "string",
            },
          },
        },
        required: [
          "id",
          "name",
          "description",
          "createdBy",
          "createdAt",
          "updatedAt",
          "isPublic",
          "isPublished",
        ],
      },
      Feedback: {
        title: "Feedback",
        description: "Represents a user feedback.",
        type: "object",
        properties: {
          userId: {
            type: "string",
          },
          feedback: {
            type: "string",
          },
          canContact: {
            type: "boolean",
          },
        },
        required: ["userId", "feedback", "canContact"],
      },
      Error: {
        type: "object",
        additionalProperties: false,
        required: ["code", "message"],
        properties: {
          message: {
            type: "string",
          },
        },
      },
    },
    responses: {
      Error: {
        description: "An error occurred",
        content: {
          "application/json": {
            schema: {
              $ref: "#/components/schemas/Error",
            },
          },
        },
      },
    },
  },
  paths: {
    "/v1/admin/users": {
      get: {
        operationId: "getAllUsers",
        summary: "Get all users",
        tags: ["Admin"],
        security: [
          {
            BearerAuth: [],
          },
        ],
        responses: {
          200: {
            description: "Default Response",
            content: {
              "application/json": {
                schema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    users: {
                      type: "array",
                      items: {
                        type: "object",
                        properties: {
                          id: {
                            type: "string",
                          },
                          email: {
                            type: "string",
                          },
                          isBanned: {
                            type: "boolean",
                          },
                        },
                        required: ["id", "email"],
                      },
                    },
                  },
                  required: ["users"],
                },
              },
            },
          },
          default: {
            $ref: "#/components/responses/Error",
          },
        },
      },
    },
    "/v1/admin/stats": {
      get: {
        operationId: "getStats",
        summary: "Get stats",
        tags: ["Admin"],
        security: [
          {
            BearerAuth: [],
          },
        ],
        responses: {
          200: {
            description: "Default Response",
            content: {
              "application/json": {
                schema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    stats: {
                      type: "object",
                      properties: {
                        dailyActiveUsers: {
                          type: "array",
                          items: {
                            type: "object",
                            properties: {
                              date: {
                                type: "string",
                              },
                              userIds: {
                                type: "array",
                                items: {
                                  type: "string",
                                },
                              },
                              userCount: {
                                type: "number",
                              },
                            },
                            required: ["date", "userIds", "userCount"],
                          },
                        },
                      },
                      required: ["dailyActiveUsers"],
                    },
                  },
                  required: ["stats"],
                },
              },
            },
          },
          default: {
            $ref: "#/components/responses/Error",
          },
        },
      },
    },
    "/v1/admin/users/{userId}/banned": {
      put: {
        operationId: "updateUserBanned",
        summary: "Update user banned",
        tags: ["Admin"],
        parameters: [
          {
            schema: {
              type: "string",
            },
            in: "path",
            name: "userId",
            required: true,
          },
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                additionalProperties: false,
                type: "object",
                properties: {
                  isBanned: {
                    type: "boolean",
                  },
                },
                required: ["isBanned"],
              },
            },
          },
          required: true,
        },
        security: [
          {
            BearerAuth: [],
          },
        ],
        responses: {
          200: {
            description: "Default Response",
            content: {
              "application/json": {
                schema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    id: {
                      type: "string",
                    },
                    email: {
                      type: "string",
                    },
                    isBanned: {
                      type: "boolean",
                    },
                  },
                  required: ["id", "email"],
                },
              },
            },
          },
          default: {
            $ref: "#/components/responses/Error",
          },
        },
      },
    },
    "/v1/feedback/submit-feedback": {
      post: {
        operationId: "submitFeedback",
        summary: "Submit feedback",
        tags: ["Feedback"],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                additionalProperties: false,
                type: "object",
                properties: {
                  userId: {
                    type: "string",
                  },
                  feedback: {
                    type: "string",
                  },
                  canContact: {
                    type: "boolean",
                  },
                },
                required: ["userId", "feedback", "canContact"],
              },
            },
          },
          required: true,
        },
        security: [
          {
            BearerAuth: [],
          },
        ],
        responses: {
          200: {
            description: "Default Response",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/Feedback",
                },
              },
            },
          },
          default: {
            $ref: "#/components/responses/Error",
          },
        },
      },
    },
    "/v1/projects/{projectId}/files/{fileId}/revisions": {
      post: {
        operationId: "createFileRevision",
        summary: "Create file revision",
        tags: ["Projects"],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                type: "object",
                properties: {
                  fileContent: {
                    type: "string",
                  },
                  changes: {
                    type: "array",
                    items: {
                      $ref: "#/components/schemas/Change",
                    },
                  },
                },
                required: ["fileContent", "changes"],
              },
            },
          },
          required: true,
        },
        parameters: [
          {
            schema: {
              type: "string",
            },
            in: "path",
            name: "projectId",
            required: true,
          },
          {
            schema: {
              type: "string",
            },
            in: "path",
            name: "fileId",
            required: true,
          },
        ],
        security: [
          {
            BearerAuth: [],
          },
        ],
        responses: {
          200: {
            description: "A set of changes made to a file by a single user.",
            content: {
              "application/json": {
                schema: {
                  title: "Revision",
                  description:
                    "A set of changes made to a file by a single user.",
                  type: "object",
                  properties: {
                    id: {
                      type: "string",
                    },
                    changes: {
                      type: "array",
                      items: {
                        $ref: "#/components/schemas/Change",
                      },
                    },
                    createdBy: {
                      type: "string",
                    },
                    createdAt: {
                      type: "string",
                    },
                  },
                  required: ["id", "changes", "createdBy", "createdAt"],
                  additionalProperties: false,
                },
              },
            },
          },
          default: {
            $ref: "#/components/responses/Error",
          },
        },
      },
      get: {
        operationId: "getFileRevisions",
        summary: "Get file revisions",
        tags: ["Projects"],
        parameters: [
          {
            schema: {
              type: "string",
            },
            in: "path",
            name: "projectId",
            required: true,
          },
          {
            schema: {
              type: "string",
            },
            in: "path",
            name: "fileId",
            required: true,
          },
        ],
        security: [
          {},
          {
            BearerAuth: [],
          },
        ],
        responses: {
          200: {
            description: "Default Response",
            content: {
              "application/json": {
                schema: {
                  type: "array",
                  items: {
                    $ref: "#/components/schemas/Revision",
                  },
                },
              },
            },
          },
          default: {
            $ref: "#/components/responses/Error",
          },
        },
      },
    },
    "/v1/projects/": {
      post: {
        operationId: "createProject",
        summary: "Create project",
        tags: ["Projects"],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                type: "object",
                properties: {
                  name: {
                    type: "string",
                  },
                },
                required: ["name"],
              },
            },
          },
          required: true,
        },
        security: [
          {
            BearerAuth: [],
          },
        ],
        responses: {
          200: {
            description: "Top level entity within MML Editor.",
            content: {
              "application/json": {
                schema: {
                  title: "Project",
                  description: "Top level entity within MML Editor.",
                  type: "object",
                  properties: {
                    id: {
                      type: "string",
                    },
                    name: {
                      type: "string",
                    },
                    description: {
                      type: "string",
                    },
                    createdBy: {
                      type: "string",
                    },
                    createdByInfo: {
                      type: "object",
                      properties: {
                        name: {
                          type: "string",
                        },
                        picture: {
                          type: "string",
                        },
                      },
                    },
                    forkedFrom: {
                      type: "string",
                    },
                    createdAt: {
                      type: "string",
                    },
                    updatedAt: {
                      type: "string",
                    },
                    thumbnailId: {
                      type: "string",
                    },
                    thumbnailUrl: {
                      type: "string",
                    },
                    isPublic: {
                      type: "boolean",
                    },
                    isPublished: {
                      type: "boolean",
                    },
                    files: {
                      type: "array",
                      items: {
                        $ref: "#/components/schemas/File",
                      },
                    },
                    assets: {
                      type: "array",
                      items: {
                        $ref: "#/components/schemas/Asset",
                      },
                    },
                    tags: {
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: [
                    "id",
                    "name",
                    "description",
                    "createdBy",
                    "createdAt",
                    "updatedAt",
                    "isPublic",
                    "isPublished",
                  ],
                  additionalProperties: false,
                },
              },
            },
          },
          default: {
            $ref: "#/components/responses/Error",
          },
        },
      },
      get: {
        operationId: "getProjects",
        summary: "Get projects",
        tags: ["Projects"],
        security: [
          {
            BearerAuth: [],
          },
        ],
        responses: {
          200: {
            description: "Default Response",
            content: {
              "application/json": {
                schema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    projects: {
                      type: "array",
                      items: {
                        $ref: "#/components/schemas/Project",
                      },
                    },
                  },
                  required: ["projects"],
                },
              },
            },
          },
          default: {
            $ref: "#/components/responses/Error",
          },
        },
      },
    },
    "/v1/projects/{projectId}/assets/{assetId}": {
      delete: {
        operationId: "deleteAsset",
        summary: "Delete project asset",
        tags: ["Projects"],
        parameters: [
          {
            schema: {
              type: "string",
            },
            in: "path",
            name: "projectId",
            required: true,
          },
          {
            schema: {
              type: "string",
            },
            in: "path",
            name: "assetId",
            required: true,
          },
        ],
        security: [
          {
            BearerAuth: [],
          },
        ],
        responses: {
          200: {
            description: "Default Response",
            content: {
              "application/json": {
                schema: {
                  type: "object",
                  properties: {
                    assetId: {
                      type: "string",
                    },
                  },
                  required: ["assetId"],
                },
              },
            },
          },
          default: {
            $ref: "#/components/responses/Error",
          },
        },
      },
    },
    "/v1/projects/{projectId}": {
      delete: {
        operationId: "deleteProject",
        summary: "Delete project",
        tags: ["Projects"],
        parameters: [
          {
            schema: {
              type: "string",
            },
            in: "path",
            name: "projectId",
            required: true,
          },
        ],
        security: [
          {
            BearerAuth: [],
          },
        ],
        responses: {
          200: {
            description: "Default Response",
            content: {
              "application/json": {
                schema: {
                  type: "object",
                  properties: {
                    projectId: {
                      type: "string",
                    },
                  },
                  required: ["projectId"],
                },
              },
            },
          },
          default: {
            $ref: "#/components/responses/Error",
          },
        },
      },
      get: {
        operationId: "getProject",
        summary: "Get project",
        tags: ["Projects"],
        parameters: [
          {
            schema: {
              type: "boolean",
            },
            in: "query",
            name: "revisions",
            required: false,
          },
          {
            schema: {
              type: "string",
            },
            in: "path",
            name: "projectId",
            required: true,
          },
        ],
        security: [
          {},
          {
            BearerAuth: [],
          },
        ],
        responses: {
          200: {
            description: "Default Response",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/Project",
                },
              },
            },
          },
          default: {
            $ref: "#/components/responses/Error",
          },
        },
      },
      put: {
        operationId: "updateProject",
        summary: "Update project",
        tags: ["Projects"],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                additionalProperties: false,
                type: "object",
                properties: {
                  name: {
                    type: "string",
                  },
                  description: {
                    type: "string",
                  },
                },
              },
            },
          },
        },
        parameters: [
          {
            schema: {
              type: "string",
            },
            in: "path",
            name: "projectId",
            required: true,
          },
        ],
        security: [
          {
            BearerAuth: [],
          },
        ],
        responses: {
          200: {
            description: "Default Response",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/Project",
                },
              },
            },
          },
          default: {
            $ref: "#/components/responses/Error",
          },
        },
      },
    },
    "/v1/projects/{projectId}/staticVersions/{staticVersionId}": {
      delete: {
        operationId: "deleteStaticVersion",
        summary: "Delete static version",
        tags: ["Projects"],
        parameters: [
          {
            schema: {
              type: "string",
            },
            in: "path",
            name: "projectId",
            required: true,
          },
          {
            schema: {
              type: "string",
            },
            in: "path",
            name: "staticVersionId",
            required: true,
          },
        ],
        security: [
          {
            BearerAuth: [],
          },
        ],
        responses: {
          200: {
            description: "Default Response",
            content: {
              "application/json": {
                schema: {
                  type: "object",
                  properties: {
                    staticVersionId: {
                      type: "string",
                    },
                  },
                  required: ["staticVersionId"],
                },
              },
            },
          },
          default: {
            $ref: "#/components/responses/Error",
          },
        },
      },
    },
    "/v1/projects/{projectId}/forks": {
      post: {
        operationId: "forkProject",
        summary: "Fork project",
        tags: ["Projects"],
        parameters: [
          {
            schema: {
              type: "string",
            },
            in: "path",
            name: "projectId",
            required: true,
          },
        ],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                type: "object",
                properties: {},
              },
            },
          },
        },
        security: [
          {
            BearerAuth: [],
          },
        ],
        responses: {
          200: {
            description: "Top level entity within MML Editor.",
            content: {
              "application/json": {
                schema: {
                  title: "Project",
                  description: "Top level entity within MML Editor.",
                  type: "object",
                  properties: {
                    id: {
                      type: "string",
                    },
                    name: {
                      type: "string",
                    },
                    description: {
                      type: "string",
                    },
                    createdBy: {
                      type: "string",
                    },
                    createdByInfo: {
                      type: "object",
                      properties: {
                        name: {
                          type: "string",
                        },
                        picture: {
                          type: "string",
                        },
                      },
                    },
                    forkedFrom: {
                      type: "string",
                    },
                    createdAt: {
                      type: "string",
                    },
                    updatedAt: {
                      type: "string",
                    },
                    thumbnailId: {
                      type: "string",
                    },
                    thumbnailUrl: {
                      type: "string",
                    },
                    isPublic: {
                      type: "boolean",
                    },
                    isPublished: {
                      type: "boolean",
                    },
                    files: {
                      type: "array",
                      items: {
                        $ref: "#/components/schemas/File",
                      },
                    },
                    assets: {
                      type: "array",
                      items: {
                        $ref: "#/components/schemas/Asset",
                      },
                    },
                    tags: {
                      type: "array",
                      items: {
                        type: "string",
                      },
                    },
                  },
                  required: [
                    "id",
                    "name",
                    "description",
                    "createdBy",
                    "createdAt",
                    "updatedAt",
                    "isPublic",
                    "isPublished",
                  ],
                  additionalProperties: false,
                },
              },
            },
          },
          default: {
            $ref: "#/components/responses/Error",
          },
        },
      },
    },
    "/v1/projects/explore": {
      get: {
        operationId: "getExploreProjects",
        summary: "Get explore projects",
        tags: ["Projects"],
        parameters: [
          {
            schema: {
              type: "string",
            },
            in: "query",
            name: "tag",
            required: false,
          },
          {
            schema: {
              type: "number",
            },
            in: "query",
            name: "page",
            required: false,
          },
        ],
        responses: {
          200: {
            description: "Default Response",
            content: {
              "application/json": {
                schema: {
                  additionalProperties: false,
                  type: "object",
                  properties: {
                    featuredProjects: {
                      type: "array",
                      items: {
                        $ref: "#/components/schemas/Project",
                      },
                    },
                    projects: {
                      type: "array",
                      items: {
                        $ref: "#/components/schemas/Project",
                      },
                    },
                  },
                  required: ["featuredProjects", "projects"],
                },
              },
            },
          },
          default: {
            $ref: "#/components/responses/Error",
          },
        },
      },
    },
    "/v1/projects/{projectId}/access": {
      get: {
        operationId: "getProjectAccess",
        summary: "Get project access",
        tags: ["Projects"],
        parameters: [
          {
            schema: {
              type: "string",
            },
            in: "path",
            name: "projectId",
            required: true,
          },
        ],
        security: [
          {},
          {
            BearerAuth: [],
          },
        ],
        responses: {
          200: {
            description: "Default Response",
            content: {
              "application/json": {
                schema: {
                  type: "object",
                  properties: {
                    access: {
                      type: "string",
                      enum: ["viewer", "creator"],
                    },
                  },
                  required: ["access"],
                },
              },
            },
          },
          default: {
            $ref: "#/components/responses/Error",
          },
        },
      },
    },
    "/v1/projects/{projectId}/staticVersions": {
      get: {
        operationId: "getStaticVersions",
        summary: "Get static versions",
        tags: ["Projects"],
        parameters: [
          {
            schema: {
              type: "string",
            },
            in: "path",
            name: "projectId",
            required: true,
          },
        ],
        security: [
          {},
          {
            BearerAuth: [],
          },
        ],
        responses: {
          200: {
            description: "Default Response",
            content: {
              "application/json": {
                schema: {
                  type: "array",
                  items: {
                    $ref: "#/components/schemas/StaticVersion",
                  },
                },
              },
            },
          },
          default: {
            $ref: "#/components/responses/Error",
          },
        },
      },
      post: {
        operationId: "publishStaticVersion",
        summary: "Publish static version",
        tags: ["Projects"],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                additionalProperties: false,
                type: "object",
                properties: {
                  removeScripts: {
                    type: "boolean",
                  },
                },
              },
            },
          },
        },
        parameters: [
          {
            schema: {
              type: "string",
            },
            in: "path",
            name: "projectId",
            required: true,
          },
        ],
        security: [
          {
            BearerAuth: [],
          },
        ],
        responses: {
          200: {
            description: "A published static state of a project.",
            content: {
              "application/json": {
                schema: {
                  title: "StaticVersion",
                  description: "A published static state of a project.",
                  type: "object",
                  properties: {
                    id: {
                      type: "string",
                    },
                    name: {
                      type: "string",
                    },
                    size: {
                      type: "number",
                    },
                    path: {
                      type: "string",
                    },
                    url: {
                      type: "string",
                    },
                    createdBy: {
                      type: "string",
                    },
                    createdAt: {
                      type: "string",
                    },
                  },
                  required: [
                    "id",
                    "name",
                    "size",
                    "path",
                    "createdBy",
                    "createdAt",
                  ],
                  additionalProperties: false,
                },
              },
            },
          },
          default: {
            $ref: "#/components/responses/Error",
          },
        },
      },
    },
    "/v1/projects/{projectId}/public": {
      put: {
        operationId: "updateProjectPublic",
        summary: "Update project public",
        tags: ["Projects"],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                additionalProperties: false,
                type: "object",
                properties: {
                  isPublic: {
                    type: "boolean",
                  },
                },
                required: ["isPublic"],
              },
            },
          },
          required: true,
        },
        parameters: [
          {
            schema: {
              type: "string",
            },
            in: "path",
            name: "projectId",
            required: true,
          },
        ],
        security: [
          {
            BearerAuth: [],
          },
        ],
        responses: {
          200: {
            description: "Default Response",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/Project",
                },
              },
            },
          },
          default: {
            $ref: "#/components/responses/Error",
          },
        },
      },
    },
    "/v1/projects/{projectId}/published": {
      put: {
        operationId: "updateProjectPublished",
        summary: "Update project published",
        tags: ["Projects"],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                additionalProperties: false,
                type: "object",
                properties: {
                  isPublished: {
                    type: "boolean",
                  },
                },
                required: ["isPublished"],
              },
            },
          },
          required: true,
        },
        parameters: [
          {
            schema: {
              type: "string",
            },
            in: "path",
            name: "projectId",
            required: true,
          },
        ],
        security: [
          {
            BearerAuth: [],
          },
        ],
        responses: {
          200: {
            description: "Default Response",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/Project",
                },
              },
            },
          },
          default: {
            $ref: "#/components/responses/Error",
          },
        },
      },
    },
    "/v1/projects/{projectId}/tags": {
      put: {
        operationId: "updateProjectTags",
        summary: "Update project tags",
        tags: ["Projects"],
        requestBody: {
          content: {
            "application/json": {
              schema: {
                additionalProperties: false,
                type: "object",
                properties: {
                  tags: {
                    type: "array",
                    items: {
                      type: "string",
                    },
                  },
                },
                required: ["tags"],
              },
            },
          },
          required: true,
        },
        parameters: [
          {
            schema: {
              type: "string",
            },
            in: "path",
            name: "projectId",
            required: true,
          },
        ],
        security: [
          {
            BearerAuth: [],
          },
        ],
        responses: {
          200: {
            description: "Default Response",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/Project",
                },
              },
            },
          },
          default: {
            $ref: "#/components/responses/Error",
          },
        },
      },
    },
    "/v1/user": {
      get: {
        operationId: "getUser",
        summary: "Get user",
        tags: ["User"],
        security: [
          {
            BearerAuth: [],
          },
        ],
        responses: {
          200: {
            description: "Default Response",
            content: {
              "application/json": {
                schema: {
                  $ref: "#/components/schemas/User",
                },
              },
            },
          },
          default: {
            $ref: "#/components/responses/Error",
          },
        },
      },
    },
  },
} as const;

type SchemaType = Readonly<typeof rootSchema>;
export const mmlEditorAPISchema: SchemaType = rootSchema;
