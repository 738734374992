import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import * as React from "react";

import serverApi from "~/library/serverApi/serverApi";

const DeleteAssetModal = ({
  open,
  onClose,
  projectId,
  assetId,
  onDelete,
}: {
  open: boolean;
  onClose: () => void;
  projectId: string;
  assetId: string;
  onDelete: () => void;
}) => {
  const deleteAsset = async () => {
    const res = await serverApi.deleteAsset({
      parameters: {
        projectId,
        assetId,
      },
      body: null,
    });
    if (res.code === 200) {
      onDelete();
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Delete asset</DialogTitle>

      <DialogContent>
        <DialogContentText>
          This asset will no longer be accessible from your MML document. Are
          you sure you wish to continue?
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ padding: "0 24px 20px 24px" }}>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={deleteAsset}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAssetModal;
