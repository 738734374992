import { IMMLScene, MElement } from "mml-web";
import * as THREE from "three";
import {
  TransformControls,
  TransformControlsGizmo,
} from "three/examples/jsm/controls/TransformControls.js";

import { elementToPath, pathToElement } from "~/library/domUtils";

export default class TransformWidget {
  private disabled: boolean;
  private clickEnabled: boolean;
  private isDragging: boolean;
  private dragCallback?: (dragging: boolean) => void;
  private transformCallback?: (
    element: HTMLElement,
    attributes: object,
  ) => void;
  private deleteCallback?: (elements: Array<HTMLElement>) => void;
  private selectCallback?: (elements: Array<HTMLElement> | null) => void;

  private selectedElementPaths: Array<Array<number>> | null = null;
  private transformControls: TransformControls;

  private rootElement: HTMLElement;
  private elementHolder: HTMLElement;
  private scene: IMMLScene;

  constructor(
    scene: IMMLScene,
    setControlsEnabled: (controlsEnabled: boolean) => void,
    rootElement: HTMLElement,
    elementHolder: HTMLElement,
    dragCallback?: (dragging: boolean) => void,
    transformCallback?: (element: HTMLElement, attributes: object) => void,
    deleteCallback?: (elements: Array<HTMLElement>) => void,
    selectCallback?: (elements: Array<HTMLElement> | null) => void,
  ) {
    this.disabled = false;
    this.scene = scene;
    this.dragCallback = dragCallback;
    this.transformCallback = transformCallback;
    this.deleteCallback = deleteCallback;
    this.selectCallback = selectCallback;
    this.rootElement = rootElement;
    this.elementHolder = elementHolder;

    this.clickEnabled = true;

    this.transformControls = new TransformControls(
      scene.getCamera(),
      rootElement,
    );
    this.setGizmoColors();
    scene.getThreeScene().add(this.transformControls);

    // Re-select selected element on document mutations
    const elementHolderMutationObserver = new MutationObserver(() => {
      const preMutationSelection = this.selectedElementPaths;
      this.deselect();
      if (preMutationSelection) {
        const elems = preMutationSelection.map((elemPath) =>
          pathToElement(elementHolder, elemPath),
        );
        if (elems.length) {
          this.select(elems);
          setTimeout(() => {
            this.selectCallback?.(elems);
          }, 50);
        }
      }
    });
    elementHolderMutationObserver.observe(elementHolder, {
      childList: true,
      subtree: true,
    });

    this.transformControls.addEventListener("dragging-changed", (event) => {
      const isDragging = event.value as boolean;
      if (dragCallback) {
        dragCallback(isDragging);
      }

      setTimeout(() => {
        this.clickEnabled = !isDragging;
      }, 50);
      setControlsEnabled(!isDragging);

      if (!isDragging) {
        if (!this.selectedElementPaths?.length) return;

        const selectedElement = pathToElement(
          this.elementHolder,
          this.selectedElementPaths[0],
        ) as MElement;
        if (!selectedElement) return;

        const pos =
          selectedElement.getContainer()?.position ||
          new THREE.Vector3(0, 0, 0);

        const rot =
          selectedElement.getContainer()?.rotation ||
          new THREE.Vector3(0, 0, 0);

        const scale =
          selectedElement.getContainer()?.scale || new THREE.Vector3(1, 1, 1);

        const round = (num: number) => Math.round(num * 1000) / 1000;

        const x = pos.x === 0.0 ? undefined : round(pos.x);
        const y = pos.y === 0.0 ? undefined : round(pos.y);
        const z = pos.z === 0.0 ? undefined : round(pos.z);
        const rx =
          rot.x === 0.0 ? undefined : round(THREE.MathUtils.RAD2DEG * rot.x);
        const ry =
          rot.y === 0.0 ? undefined : round(THREE.MathUtils.RAD2DEG * rot.y);
        const rz =
          rot.z === 0.0 ? undefined : round(THREE.MathUtils.RAD2DEG * rot.z);
        const sx = scale.x === 1.0 ? undefined : round(scale.x);
        const sy = scale.y === 1.0 ? undefined : round(scale.y);
        const sz = scale.z === 1.0 ? undefined : round(scale.z);

        if (this.transformCallback && selectedElement) {
          this.transformCallback(selectedElement, {
            x,
            y,
            z,
            rx,
            ry,
            rz,
            sx,
            sy,
            sz,
          });
        }
      }
    });

    window.addEventListener("keydown", (event) => {
      if (!this.selectedElementPaths?.length) return;

      // We don't want to trigger these events when typing in an input field for the inspector
      if (
        ["INPUT", "TEXTAREA"].includes((event.target as HTMLElement)?.tagName)
      )
        return;

      switch (event.key) {
        case "q":
          this.transformControls.setSpace(
            this.transformControls.space === "local" ? "world" : "local",
          );
          break;

        case "Shift":
          this.transformControls.setTranslationSnap(1);
          this.transformControls.setRotationSnap(THREE.MathUtils.degToRad(45));
          this.transformControls.setScaleSnap(0.25);
          break;

        case "w":
          this.transformControls.setMode("translate");
          break;

        case "e":
          this.transformControls.setMode("rotate");
          break;

        case "r":
          this.transformControls.setMode("scale");
          break;

        case "=":
        case "+":
          this.transformControls.setSize(this.transformControls.size + 0.1);
          break;

        case "-":
        case "_":
          this.transformControls.setSize(
            Math.max(this.transformControls.size - 0.1, 0.1),
          );
          break;

        case "x":
          this.transformControls.showX = !this.transformControls.showX;
          break;

        case "y":
          this.transformControls.showY = !this.transformControls.showY;
          break;

        case "z":
          this.transformControls.showZ = !this.transformControls.showZ;
          break;

        case "Escape":
          this.deselect();
          this.selectCallback?.(null);
          break;
      }
    });

    window.addEventListener("keyup", (event) => {
      // We don't want to trigger these events when typing in an input field for the inspector
      if (
        ["INPUT", "TEXTAREA"].includes((event.target as HTMLElement)?.tagName)
      )
        return;

      switch (event.key) {
        case "Shift":
          this.transformControls.setTranslationSnap(null);
          this.transformControls.setRotationSnap(null);
          this.transformControls.setScaleSnap(null);
          break;
        case "Delete":
        case "Backspace":
          if (this.deleteCallback && this.selectedElementPaths?.length) {
            const elems = this.selectedElementPaths.map((elemPath) =>
              pathToElement(elementHolder, elemPath),
            );
            this.deselect();
            this.selectCallback?.(null);
            this.deleteCallback(elems);
          }
          break;
      }
    });

    rootElement.addEventListener("mouseup", (event) => {
      if (!this.clickEnabled) return;

      const raycaster = new THREE.Raycaster();
      const pointer = new THREE.Vector2();

      const bounds = (event.target as HTMLElement)?.getBoundingClientRect();
      pointer.x =
        ((event.clientX - bounds.left) / rootElement.clientWidth) * 2 - 1;
      pointer.y =
        -((event.clientY - bounds.top) / rootElement.clientHeight) * 2 + 1;

      raycaster.setFromCamera(pointer, scene.getCamera());

      const intersects = raycaster.intersectObject(
        scene.getRootContainer(),
        true,
      );

      // Find clicked MML Element
      let object: any = intersects[0]?.object;
      let mElement;
      if (object) {
        while (object) {
          mElement = MElement.getMElementFromObject(object);
          if (mElement) {
            break;
          }
          object = object.parent;
        }
      }

      if (mElement) {
        this.select([mElement]);
        this.selectCallback?.([mElement]);
      } else {
        this.deselect();
        this.selectCallback?.(null);
      }
    });
  }

  disable() {
    this.deselect();
    this.disabled = true;
  }

  enable() {
    this.disabled = false;
  }

  deselect() {
    this.transformControls.detach();
    this.selectedElementPaths = null;
  }

  select(elems?: Array<any> | null) {
    if (this.disabled) return;
    if (!elems) {
      this.deselect();
      return;
    }

    const selectableElems = elems.filter((elem) => !!elem.getContainer);
    if (!selectableElems.length) {
      this.deselect();
      return;
    }

    this.selectedElementPaths = selectableElems.map((elem) =>
      elementToPath(this.elementHolder, elem),
    );

    if (selectableElems.length === 1) {
      this.transformControls.attach(selectableElems[0].getContainer());
    } else {
      console.log("Multiple selection, show bounding boxes");
    }
  }

  private setGizmoColors = () => {
    const x = "#F44336";
    const y = "#45B94C";
    const z = "#377BFF";

    const transformControlsGizmo = this.transformControls
      .children[0] as TransformControlsGizmo;

    // Red
    transformControlsGizmo
      .getObjectsByProperty("name", "X")
      .forEach((mesh: any) => {
        mesh.material.color = new THREE.Color(x);
      });
    transformControlsGizmo
      .getObjectsByProperty("name", "YZ")
      .forEach((mesh: any) => {
        mesh.material.color = new THREE.Color(x);
      });

    transformControlsGizmo
      .getObjectsByProperty("name", "Y")
      .forEach((mesh: any) => {
        mesh.material.color = new THREE.Color(y);
      });
    transformControlsGizmo
      .getObjectsByProperty("name", "XZ")
      .forEach((mesh: any) => {
        mesh.material.color = new THREE.Color(y);
      });

    transformControlsGizmo
      .getObjectsByProperty("name", "Z")
      .forEach((mesh: any) => {
        mesh.material.color = new THREE.Color(z);
      });
    transformControlsGizmo
      .getObjectsByProperty("name", "XY")
      .forEach((mesh: any) => {
        mesh.material.color = new THREE.Color(z);
      });
  };
}
