import { MonacoAdapter, TMonacoAdapterConstructionOptions } from "@otjs/monaco";
import {
  EditorClient,
  IEditorAdapter,
  IEditorClient,
  TEditorClientEventArgs,
} from "@otjs/plaintext-editor";
import { Handler } from "mitt";

import {
  OTServerAdapter,
  TServerAdapterConstructionOptions,
} from "./serverAdapter";

export {
  DatabaseAdapterEvent,
  EditorAdapterEvent,
} from "@otjs/plaintext-editor";

export type TEditorConstructionOptions = TServerAdapterConstructionOptions &
  Omit<TMonacoAdapterConstructionOptions, "bindEvents">;

export class OTClient {
  public serverAdapter: OTServerAdapter;
  public editorAdapter: IEditorAdapter;
  public editorClient: IEditorClient;

  public monaco: any;

  private _disposed = false;

  constructor({
    editor,
    userId,
    onOperation,
    onCursor,
  }: TEditorConstructionOptions) {
    this.serverAdapter = new OTServerAdapter({ userId, onOperation, onCursor });

    this.editorAdapter = new MonacoAdapter({
      // Hide labels
      // NOTE: Check Screen component for global .monaco-editor .monaco-editor-overlaymessage style override
      announcementDuration: 0,
      editor,
      bindEvents: true,
    });

    this.editorClient = new EditorClient({
      databaseAdapter: this.serverAdapter,
      editorAdapter: this.editorAdapter,
    });

    this.monaco = editor;
  }

  get disposed(): boolean {
    return this._disposed;
  }

  get text(): string {
    if (this._disposed) return "";

    return this.editorAdapter.getText();
  }

  set text(content: string) {
    if (this._disposed) return;

    this.editorAdapter.setText(content);
  }

  clearHistory(): void {
    this.editorClient.clearUndoRedoStack();
  }

  dispose(): void {
    if (this._disposed) return;

    this.editorClient.dispose();
    this.serverAdapter.dispose();
    this.editorAdapter.dispose();

    // @ts-expect-error - clearing using null
    this.serverAdapter = null;
    // @ts-expect-error - clearing using null
    this.editorAdapter = null;
    // @ts-expect-error - clearing using null
    this.editorClient = null;

    this._disposed = true;
  }

  isHistoryEmpty(): boolean {
    return this.serverAdapter.isHistoryEmpty();
  }

  on<Key extends keyof TEditorClientEventArgs>(
    event: Key,
    listener: Handler<TEditorClientEventArgs[Key]>,
  ): void {
    this.editorClient.on(event, listener);
  }

  off<Key extends keyof TEditorClientEventArgs>(
    event: Key,
    listener?: Handler<TEditorClientEventArgs[Key]>,
  ): void {
    this.editorClient.off(event, listener);
  }
}
