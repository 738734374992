import styled from "@emotion/styled";
import * as React from "react";
type MidSectionElement = {
  icon: string;
  title: string;
  description: string;
  image: string;
  isInverted?: boolean;
  children?: React.ReactNode;
};

const MidSectionElementContainer = styled.div(({ theme }) => ({
  margin: "100px auto 0",
  display: "flex",
  flexDirection: "column",
  textAlign: "left",
  maxWidth: 1200,

  [theme.breakpoints.up("md")]: {
    margin: "150px auto 0",
    flexDirection: "row",
    alignItems: "center",
  },
}));

const DetailsContainer = styled.div<{ isInverted: boolean }>(
  ({ theme, isInverted }) => ({
    paddingBottom: 27,

    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: isInverted ? "flex-end" : "flex-start",
      paddingLeft: 58,
      order: isInverted ? 0 : 2,
      flex: "0 0 50%",
      paddingBottom: 0,
      [isInverted ? "padding-right" : "padding-left"]: 58,
    },
  }),
);

const Icon = styled.img({
  width: 48,
  height: 48,
});

const Title = styled.h3({
  fontSize: "1.5rem",
  fontWeight: 700,
  lineHeight: 1.45833,
  marginTop: 10,
});

const Description = styled.p({
  color: "#FFFFFF77",
  fontWeight: 500,
  lineHeight: 1.25,
});

const Image = styled.img(({ theme }) => ({
  width: "100%",
  height: "100%",
  borderRadius: 5,

  [theme.breakpoints.up("md")]: {
    width: 0,
    height: "auto",
    order: 1,
    flex: "0 0 50%",
  },
}));

export default function MidSectionElementRender({
  icon,
  title,
  description,
  image,
  children,
  isInverted = false,
}: MidSectionElement) {
  return (
    <MidSectionElementContainer>
      <DetailsContainer isInverted={isInverted}>
        <Icon src={icon} />
        <Title>{title}</Title>
        <Description>{description}</Description>
        {children}
      </DetailsContainer>
      <Image src={image} />
    </MidSectionElementContainer>
  );
}
