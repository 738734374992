export async function dataUrlToFile(
  dataUrl: string,
  fileName: string,
): Promise<File> {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return new File([blob], fileName, {
    type: "image/png",
  });
}

export function readFile(file: File): Promise<ArrayBuffer> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    const onLoadFile = () => {
      const buffer = reader.result;
      resolve(buffer as ArrayBuffer);
    };

    reader.addEventListener("load", onLoadFile);
    reader.readAsArrayBuffer(file);
  });
}

export async function hashFile(file: File): Promise<string> {
  const fileData = await readFile(file);

  const hashDigest = await crypto.subtle.digest("SHA-256", fileData);

  return [...new Uint8Array(hashDigest)]
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
}

export function getDraggedFile(evt: React.DragEvent<HTMLElement>) {
  let file;
  if (evt.dataTransfer?.items) {
    const item = evt.dataTransfer.items[0];
    if (item.kind === "file") {
      file = item.getAsFile();
    }
  } else {
    file = evt.dataTransfer?.files[0];
  }

  return file;
}
