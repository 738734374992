import { getAccessToken } from "~/library/serverApi/serverApi";

const mmlEditorAPIUrl = window.serverConfig.API_SERVER_URL;

type ServerAPIUpdateThumbnailParams = {
  projectId: string;
  asset: File;
};

export async function serverApiUpdateThumbnail(
  params: ServerAPIUpdateThumbnailParams,
): Promise<
  | { code: 200; body: { thumbnailUrl: string } }
  | { code: 400; body: { message?: string } }
> {
  const formData = new FormData();
  formData.append("asset", params.asset);

  const accessToken = await getAccessToken();
  if (!accessToken) {
    throw new Error("No access token");
  }
  const request = new Request(
    `${mmlEditorAPIUrl}/v1/projects/${encodeURIComponent(params.projectId)}/thumbnail`,
    {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  const fetchResponse = await fetch(request);
  const responseBodyText = await fetchResponse.text();
  let responseBodyJson;
  if (responseBodyText) {
    responseBodyJson = JSON.parse(responseBodyText);
  }
  return { code: fetchResponse.status as 200 | 400, body: responseBodyJson };
}
