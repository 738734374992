import { IMMLScene } from "mml-web";
import * as THREE from "three";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";

export const defaultClientScene = async (scene: IMMLScene) => {
  const threeScene = scene.getThreeScene();

  threeScene.background = new THREE.Color(0xeeeeee);

  const floorGeometry = new THREE.PlaneGeometry(2000, 2000, 100, 100);
  floorGeometry.rotateX(-Math.PI / 2);
  const floor = new THREE.Mesh(
    floorGeometry,
    new THREE.MeshBasicMaterial({
      color: 0xeeeeee,
      side: THREE.DoubleSide,
    }),
  );
  floor.position.y = -0.02;
  threeScene.add(floor);

  const gridHelper = new THREE.GridHelper(2000, 2000, 0xcccccc, 0xcccccc);
  threeScene.add(gridHelper);

  const fog = new THREE.Fog(0xeeeeee, 25, 100);
  threeScene.fog = fog;

  const ambient = new THREE.AmbientLight(0xcccccc, 0.3);
  threeScene.add(ambient);

  const hdrLoader = new RGBELoader();
  const envMap = await hdrLoader.loadAsync("/ue4sky.hdr");
  envMap.mapping = THREE.EquirectangularReflectionMapping;
  threeScene.environment = envMap;
};

export const defaultEditScene = async (scene: IMMLScene) => {
  const threeScene = scene.getThreeScene();

  threeScene.background = new THREE.Color(0x222222);

  const gridHelper = new THREE.GridHelper(20, 20, 0x666666, 0x444444);
  threeScene.add(gridHelper);

  const hdrLoader = new RGBELoader();
  const envMap = await hdrLoader.loadAsync("/ue4sky.hdr");
  envMap.mapping = THREE.EquirectangularReflectionMapping;
  threeScene.environment = envMap;
};
