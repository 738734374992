import styled from "@emotion/styled";
import { Menu, MenuItem, MenuProps, Stack, Typography } from "@mui/material";
import * as React from "react";

import Icon from "~/components/Icon";

const StyledMenu = styled((props: MenuProps) => (
  <Menu elevation={8} {...props} />
))(() => ({
  zIndex: 100000,
  "& .MuiPaper-root": {
    minWidth: "120px",
    backgroundColor: "#4F4F4F",
    backgroundImage: "none",
    padding: 0,
    marginTop: "4px",
    borderRadius: "2px",
    "& .MuiList-root": {
      padding: 0,
    },
  },
}));

const CustomMenuItem = ({
  title,
  icon,
  onClick,
}: {
  title: string;
  icon?: string;
  onClick: () => void;
}) => {
  return (
    <MenuItem
      onClick={onClick}
      sx={{
        height: "40px",
        padding: "8px",
      }}
    >
      <Stack direction="row" spacing="6px" alignItems="center">
        <Icon icon={icon || ""} size="16px" />
        <Typography fontSize="12px" fontWeight={400}>
          {title}
        </Typography>
      </Stack>
    </MenuItem>
  );
};

const MenuDivider = styled.div(() => ({
  width: "100%",
  height: "1px",
  backgroundColor: "rgba(255, 255, 255, 0.1)",
}));

export type MenuItemType = {
  title: string;
  icon?: string;
  onClick?: () => void;
};

const CustomMenu = ({
  anchorElement,
  alignment,
  items,
  onClose,
  onMenuItemClick,
}: {
  anchorElement?: HTMLElement;
  alignment?: "left" | "right";
  items: MenuItemType[];
  onClose: () => void;
  onMenuItemClick: (item: MenuItemType) => void;
}) => {
  return (
    <StyledMenu
      id="custom-menu"
      anchorEl={anchorElement}
      open={Boolean(anchorElement)}
      marginThreshold={4}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: alignment || "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: alignment || "left",
      }}
    >
      {items.map((item, index) => {
        if (item.title === "-") {
          return <MenuDivider key={`-${index}`} />;
        } else {
          return (
            <CustomMenuItem
              key={item.title}
              title={item.title}
              icon={item.icon}
              onClick={() => onMenuItemClick && onMenuItemClick(item)}
            />
          );
        }
      })}
    </StyledMenu>
  );
};

export default CustomMenu;
