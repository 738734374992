import styled from "@emotion/styled";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useState } from "react";
import * as React from "react";

import appState from "~/library/appState";
import serverApi from "~/library/serverApi/serverApi";

const Divider = styled.hr({
  opacity: 0.2,
  margin: "24px 0",
});

const InputField = styled(TextareaAutosize)({
  padding: "10px",
  backgroundColor: "#2D2600",
  borderRadius: "4px",
  color: "white",
  resize: "none",
});

const CheckboxLabel = styled(Typography)({
  fontSize: 14,
});

const SubmissionStates = {
  NOT_SENT: "not-sent",
  SENDING: "sending",
  SENT: "sent",
  ERROR: "error",
} as const;

type SubmissionState = (typeof SubmissionStates)[keyof typeof SubmissionStates];

const shouldShowInputFields = (submissionsState: SubmissionState) =>
  (
    [SubmissionStates.NOT_SENT, SubmissionStates.ERROR] as SubmissionState[]
  ).includes(submissionsState);

const FeedbackModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [feedbackText, setFeedbackText] = useState("");
  const [canContact, setCanContact] = useState(false);
  const [submissionsState, setSubmissionState] = useState<SubmissionState>(
    SubmissionStates.NOT_SENT,
  );

  const handleFeedbackText = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setFeedbackText(event.target.value);
  };

  const submitFeedback = async () => {
    setSubmissionState(SubmissionStates.SENDING);
    const res = await serverApi.submitFeedback({
      parameters: {},
      body: {
        userId: appState.user?.id as string,
        canContact,
        feedback: feedbackText,
      },
    });
    setSubmissionState(
      res.code === 200 ? SubmissionStates.SENT : SubmissionStates.ERROR,
    );
  };

  const handleClose = () => {
    // This is to prevent closing the modal clicking outside of it when sending the feedback
    if (submissionsState !== SubmissionStates.SENDING) return;

    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Bug Report & Feedback</DialogTitle>

      <DialogContent>
        <Stack spacing={1}>
          <Typography>
            We value your feedback as it helps us improve. If you come across
            any issues or have suggestions for enhancing the MML Editor, please
            share them below. Let&apos;s work together to make your experience
            even better.
          </Typography>
        </Stack>

        <Divider />

        <Stack>
          {shouldShowInputFields(submissionsState) && (
            <InputField
              value={feedbackText}
              onChange={handleFeedbackText}
              minRows={10}
              maxRows={30}
              placeholder="Enter your feedback here..."
            />
          )}
          {submissionsState === SubmissionStates.SENDING && (
            <div style={{ margin: "0 auto" }}>
              <CircularProgress />
            </div>
          )}
          {submissionsState === SubmissionStates.SENT && (
            <Typography>Thank you for your feedback!</Typography>
          )}
          {submissionsState === SubmissionStates.ERROR && (
            <Typography style={{ color: "red" }}>
              There was a problem submitting your feedback. Please try again!
            </Typography>
          )}
        </Stack>

        <Stack style={{ marginTop: 10 }}>
          {shouldShowInputFields(submissionsState) && (
            <FormControlLabel
              label={
                <CheckboxLabel>
                  Check this box if you&apos;re okay with us contacting you
                  about your feedback.
                </CheckboxLabel>
              }
              control={
                <Checkbox
                  checked={canContact}
                  onChange={() => setCanContact(!canContact)}
                  color="secondary"
                />
              }
            />
          )}
          <DialogActions sx={{ padding: "0 24px 20px 24px", marginTop: 4 }}>
            {shouldShowInputFields(submissionsState) && (
              <>
                <Button color="secondary" onClick={onClose}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={submitFeedback}>
                  Send
                </Button>
              </>
            )}
            {submissionsState === SubmissionStates.SENT && (
              <Button variant="contained" onClick={onClose}>
                Close
              </Button>
            )}
          </DialogActions>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default observer(FeedbackModal);
