import styled from "@emotion/styled";
import { Avatar, Box, Button, Stack, Tab, Tabs } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  adjectives,
  animals,
  colors,
  uniqueNamesGenerator,
} from "unique-names-generator";

import config from "~/config";
import appState from "~/library/appState";
import { projectSlug } from "~/library/projectSlug";
import serverApi, { logOut } from "~/library/serverApi/serverApi";

import Icon from "./Icon";
import Logo from "./Logo";
import Menu from "./Menu";

const Container = styled.div({
  height: "50px",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "#222222",
  flexShrink: "0",
  borderBottom: "1px solid #333333",
});

const Divider = styled.div({
  height: "100%",
  width: "1px",
  backgroundColor: "#444444",
});

const StyledTabs = styled(Tabs)(() => ({
  minHeight: 0,
  height: "100%",
  "& .MuiTabs-indicator": {
    backgroundColor: "white",
    transition: "none",
  },
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  minHeight: 0,
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: "14px",
  marginRight: theme.spacing(3),
  padding: 0,
  height: "100%",
  lineHeight: "48px",
  color: "rgba(255, 255, 255, 0.5)",
  "&:hover": {
    color: "rgba(255, 255, 255, 0.75)",
  },
  "&.Mui-selected": {
    color: "white",
  },
  [theme.breakpoints.down("sm")]: {
    marginRight: theme.spacing(2),
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  width: 32,

  [theme.breakpoints.down("sm")]: {
    width: 20,
  },
}));

const UserMenuButton = styled.div<{ menuOpen?: boolean }>(({ menuOpen }) => ({
  display: "flex",
  alignItems: "center",
  height: "100%",
  paddingLeft: "8px",
  paddingRight: "4px",
  backgroundColor: menuOpen ? "rgba(255, 255, 255, 0.1)" : "transparent",
  transition: "background-color 0.1s",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
}));

const PrimaryNavbarButton = styled(Button)(({ theme }) => ({
  height: "30px",
  fontSize: "0.70rem",
  padding: "6px 12px",

  [theme.breakpoints.up("md")]: {
    padding: "6px 16px",
    fontSize: "0.85rem",
  },
})) as typeof Button;

const SecondaryNavbarButton = styled(Button)(({ theme }) => ({
  height: "30px",
  backgroundColor: "#404040",
  "&:hover": {
    backgroundColor: "#505050",
  },
  "span.project-text": {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "inline-flex",
    },
  },
  [theme.breakpoints.down("sm")]: {
    padding: "6px 8px",
  },
})) as typeof Button;

const DefaultNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const userMenuButtonRef = useRef<any>(null);
  const [menuAnchorElem, setMenuAnchorElem] = useState<HTMLElement>();

  const createProject = async () => {
    // Generate random name
    const projectName = uniqueNamesGenerator({
      dictionaries: [adjectives, colors, animals],
      separator: "-",
    });

    // Create real project
    if (appState.user) {
      const res = await serverApi.createProject({
        parameters: {},
        body: {
          name: projectName,
        },
      });
      if (res.code === 200) {
        navigate(
          `/projects/${projectSlug({ name: projectName, id: res.body.id })}`,
        );
      }
    }

    // Open temporary project
    else {
      navigate("/projects/temporary");
    }
  };

  const screenTabs = [
    {
      url: "/projects",
      title: "Projects",
    },
  ];

  if (config.features.explorePage) {
    screenTabs.push({
      url: "/explore",
      title: "Explore",
    });
  }

  const onTabChange = (tabIndex: number) => {
    const tab = screenTabs[tabIndex];
    navigate(tab.url);
  };

  const getCurrentTab = () => {
    const tab = screenTabs.find((screenTab) =>
      location.pathname.startsWith(screenTab.url),
    );
    return tab ? screenTabs.indexOf(tab) : false;
  };

  const userMenuItems = [
    {
      title: "Log out",
      icon: "logout",
      onClick: logOut,
    },
  ];

  return (
    <Container>
      {/* Left */}
      <Stack direction="row" height="100%" alignItems="center">
        <Box width="12px" />
        <Logo />
        <Box width="12px" />

        <Divider />

        <StyledBox />

        <StyledTabs
          value={getCurrentTab()}
          onChange={(_, newTab) => onTabChange(newTab)}
        >
          {screenTabs.map((tab) => (
            <StyledTab key={tab.url} disableRipple label={tab.title} />
          ))}
        </StyledTabs>
      </Stack>

      {/* Right */}
      <Stack direction="row" height="100%" alignItems="center">
        {appState.user ? (
          <>
            <SecondaryNavbarButton
              variant="contained"
              onClick={createProject}
              startIcon={<Icon icon="add" />}
            >
              Create&nbsp;<span className="project-text">project</span>
            </SecondaryNavbarButton>

            <Box width="12px" />
          </>
        ) : null}

        {appState.user ? (
          <>
            <UserMenuButton
              ref={userMenuButtonRef}
              menuOpen={Boolean(menuAnchorElem)}
              onClick={() => {
                setMenuAnchorElem(userMenuButtonRef.current);
              }}
            >
              <Avatar
                src={appState.user.picture}
                sx={{ width: 30, height: 30 }}
              />

              <Box width="4px" />

              <Icon
                icon="arrowMenu"
                size="20px"
                opacity={menuAnchorElem ? 1 : 0.5}
              />
            </UserMenuButton>
            <Box width="4px" />
          </>
        ) : (
          <>
            <PrimaryNavbarButton
              variant="contained"
              href={`/auth/login/google`}
              startIcon={<Icon icon="google" />}
            >
              Sign in with Google
            </PrimaryNavbarButton>

            <Box width="12px" />
          </>
        )}
      </Stack>

      <Menu
        anchorElement={menuAnchorElem}
        alignment="right"
        items={userMenuItems}
        onClose={() => setMenuAnchorElem(undefined)}
        onMenuItemClick={(item) => {
          item.onClick && item.onClick();
          setMenuAnchorElem(undefined);
        }}
      />
    </Container>
  );
};

export default observer(DefaultNavbar);
