import { ThemeProvider } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import * as React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import appState from "~/library/appState";
import serverApi, { refreshApiAuth } from "~/library/serverApi/serverApi";

import routes from "./routes";
import theme from "./theme";

const router = createBrowserRouter(routes);

const App = () => {
  const [authLoaded, setAuthLoaded] = useState(false);

  const loadUser = async () => {
    if (!appState.accessToken) {
      appState.user = undefined;
      return;
    }

    const res = await serverApi.getUser({
      parameters: {},
      body: null,
    });
    if (res.code === 200) {
      appState.user = res.body;
    } else {
      appState.user = undefined;
    }
  };

  const loadAuth = useCallback(async () => {
    await refreshApiAuth();
    await loadUser();

    setAuthLoaded(true);
  }, []);

  useEffect(() => {
    loadAuth();

    const onLocalStorage = (event: StorageEvent) => {
      if (event.key === "loggedOut" || event.key === "loggedIn") {
        loadAuth();
      }
    };
    window.addEventListener("storage", onLocalStorage);

    return () => {
      window.removeEventListener("storage", onLocalStorage);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      {authLoaded && <RouterProvider router={router} />}
    </ThemeProvider>
  );
};

export default observer(App);
