import styled from "@emotion/styled";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import LogoImage from "~/images/logo.svg";

const Container = styled.a({
  height: "20px",
  width: "94px",
  display: "block",
  cursor: "pointer",
});

const Logo = () => {
  const navigate = useNavigate();

  return (
    <Container onClick={() => navigate("/")}>
      <LogoImage />
    </Container>
  );
};

export default Logo;
