import * as React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";

const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  root.render(<App />);
}

// Make other tabs/windows reload auth after logging in
const searchQuery = new URLSearchParams(window.location.search);
if (searchQuery.has("loggedIn")) {
  localStorage.setItem("loggedIn", new Date().toString());
}
