import styled from "@emotion/styled";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { StaticVersion } from "mml-editor-api-schema";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";

import StaticVersionItem from "~/components/StaticVersionItem";
import appState from "~/library/appState";
import serverApi from "~/library/serverApi/serverApi";

const Divider = styled.hr({
  opacity: 0.2,
  margin: "24px 0",
});

const CustomText = styled(DialogContentText)({
  a: {
    color: "white",
  },
});

type WrappedStaticVersionType = StaticVersion & {
  added?: boolean;
};

const StaticVersionsModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [existingVersions, setExistingVersions] = useState<
    Array<WrappedStaticVersionType>
  >([]);
  const [loading, setLoading] = useState(true);
  const [publishing, setPublishing] = useState(false);

  const project = appState.project!;

  const clearAddedStatus = () => {
    setExistingVersions((existing) =>
      existing.map((version) => ({
        ...version,
        added: false,
      })),
    );
  };

  const fetchExistingVersions = useCallback(async () => {
    const res = await serverApi.getStaticVersions({
      parameters: {
        projectId: project.id,
      },
      body: null,
    });
    if (res.code === 200) {
      setExistingVersions(res.body);
    }
    setLoading(false);
  }, [project.id]);

  useEffect(() => {
    fetchExistingVersions();
  }, [project.id, fetchExistingVersions]);

  const publishStaticVersion = () => {
    if (publishing) return;
    setPublishing(true);
    clearAddedStatus();

    // Flush the file changes so that the static version that is then requested to be published is up-to-date
    const flushedCallback = async () => {
      project.session?.v1.off.v1_events_filesFlushed(flushedCallback);
      const res = await serverApi.publishStaticVersion({
        parameters: {
          projectId: project.id,
        },
        body: {},
      });
      setPublishing(false);
      if (res.code === 200) {
        setExistingVersions((existing) => [
          {
            ...res.body,
            added: true,
          },
          ...existing,
        ]);
      }
    };
    project.session?.v1.on.v1_events_filesFlushed(flushedCallback);
    project.session?.v1.v1_requests_flushFileChanges({});
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Static Versions</DialogTitle>

      <DialogContent>
        <Stack spacing={1}>
          <Typography>Publish New Static Version</Typography>
          <CustomText fontSize={13}>
            Publish the current state of your document as a static version. This
            will create a publicly accessible address for the document in its
            current state that will not change even if you make changes to the
            document.
          </CustomText>
          <div>
            <Button
              variant="contained"
              disabled={publishing}
              onClick={() => {
                if (!publishing) {
                  publishStaticVersion();
                }
              }}
            >
              {publishing ? "Publishing..." : "Publish"}
            </Button>
          </div>
        </Stack>

        <Divider />

        <Stack>
          <Typography>Existing Static Versions</Typography>
          {loading && <CustomText fontSize={13}>Loading...</CustomText>}
          {!loading && existingVersions.length === 0 && (
            <CustomText fontSize={13}>No existing static versions.</CustomText>
          )}
          {!loading &&
            existingVersions.map((version: WrappedStaticVersionType) => (
              <StaticVersionItem
                key={version.id}
                projectId={project.id}
                staticVersion={version}
                onDelete={() => {
                  setExistingVersions((existing) =>
                    existing.filter((v) => v.id !== version.id),
                  );
                }}
              />
            ))}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default observer(StaticVersionsModal);
