import { Typography } from "@mui/material";
import * as React from "react";

import Navbar from "~/components/Navbar";
import Screen from "~/components/Screen";

const Explore = () => {
  return (
    <Screen navbar={<Navbar />} center>
      <Typography variant="h1">Explore</Typography>
    </Screen>
  );
};

export default Explore;
