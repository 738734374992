import { observer } from "mobx-react-lite";
import * as React from "react";
import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Screen from "~/components/Screen";
import appState from "~/library/appState";
import Project from "~/library/project";
import { projectSlug } from "~/library/projectSlug";
import serverApi, { refreshApiAuth } from "~/library/serverApi/serverApi";
import { createAssetModal } from "~/modals";

import Layout from "./components/Layout";
import Navbar from "./components/Navbar";
import AssetsPanel from "./panels/Assets";
import CodePanel from "./panels/Code";
import DebugPanel from "./panels/Debug";
import EditPanel from "./panels/Edit";
import InfoPanel from "./panels/Info";
import InspectorPanel from "./panels/Inspector";
import PlayPanel from "./panels/Play";
import ScenePanel from "./panels/Scene";

const Editor = () => {
  const urlParams = useParams();
  const navigate = useNavigate();

  const getProject = async () => {
    const projectId = urlParams.projectId?.split("-").pop();
    if (!projectId) {
      navigate("/projects");
      return;
    }

    if (projectId === "temporary" && !appState.user) {
      console.log("Temp project because not logged in");
    } else {
      const res = await serverApi.getProject({
        parameters: {
          projectId,
        },
        body: null,
      });

      if (res.code === 200) {
        // Connect session if user has collaborator access
        // NOTE: currently only the owner can connect
        if (appState.user?.id === res.body.createdBy) {
          appState.project = new Project(res.body);
          appState.project.connectSession();
        } else {
          // Disallow local execution by default, until allowed by user
          appState.project = new Project(res.body, true, true);
          appState.project.readOnly = true;
        }
      } else {
        navigate("/projects");
      }
    }
  };

  useEffect(() => {
    getProject();

    return () => {
      if (appState.project) {
        appState.project.disconnect();
        appState.project = undefined;
      }
    };
  }, []);

  // Monitor expiry of access token and reconnect session just before expiry
  useEffect(() => {
    if (!appState.project?.connected) return;

    const checkAccessTokenExpiryInterval = setInterval(async () => {
      if (!appState.accessTokenExpiresAt) return;

      const timeUntilExpiry =
        new Date(appState.accessTokenExpiresAt).getTime() -
        new Date().getTime();

      if (timeUntilExpiry < 5000) {
        clearInterval(checkAccessTokenExpiryInterval);

        await refreshApiAuth();

        appState.project?.connectSession();
      }
    }, 1000);

    return () => {
      clearInterval(checkAccessTokenExpiryInterval);
    };
  }, [appState.project?.connected]);

  const slug = useMemo(() => {
    if (appState.project?.name) {
      return projectSlug(appState.project);
    }
  }, [appState.project?.name]);

  useEffect(() => {
    if (slug) {
      const newPathname = `/projects/${slug}`;
      if (window.location.pathname !== newPathname) {
        window.location.pathname = newPathname;
      }
    }
  }, [slug]);

  return (
    <Screen
      navbar={<Navbar />}
      fullWidth
      noPadding
      onDragEnter={() => {
        createAssetModal({});
      }}
    >
      <Layout
        leftPanels={[ScenePanel, AssetsPanel, InfoPanel]}
        centerTopPanels={[EditPanel, PlayPanel]}
        centerBottomPanels={[CodePanel, DebugPanel]}
        rightPanels={[InspectorPanel]}
      />
    </Screen>
  );
};

export default observer(Editor);
