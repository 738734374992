import styled from "@emotion/styled";
import * as React from "react";
import { useEffect } from "react";

import Navbar from "~/components/Navbar";
import Screen from "~/components/Screen";
import Terms from "~/content/terms";

const CONTENT = {
  terms: Terms,
};

const Container = styled.div(({ theme }) => ({
  maxWidth: theme.breakpoints.values.md,
  a: {
    color: theme.palette.primary.light,
  },
  h2: {
    marginBlockStart: "2em",
  },
  h3: {
    marginBlockStart: "1.5em",
  },
  h4: {
    marginBlockStart: "1.2em",
  },
}));

const Markdown = ({ contentKey }: { contentKey: keyof typeof CONTENT }) => {
  const Page = CONTENT[contentKey];

  useEffect(() => {
    document.title = `MML Editor | ${Page.title}`;
  }, []);

  return (
    <Screen navbar={<Navbar />} center>
      <Container>
        <Page.body></Page.body>
      </Container>
    </Screen>
  );
};

export default Markdown;
