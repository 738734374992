import styled from "@emotion/styled";
import * as React from "react";
import { memo } from "react";

import InspectorSegmentedControlInput from "~/screens/Editor/panels/Inspector/InspectorSegmentedControlInput";
import InspectorSelectInput from "~/screens/Editor/panels/Inspector/InspectorSelectInput";
import InspectorTextInput from "~/screens/Editor/panels/Inspector/InspectorTextInput";

export const ToolTipIcon = styled.div({
  position: "absolute",
  top: -3,
  right: 1,
  zIndex: 1,
});

const InspectorInputInternal = ({
  attribute,
  label = attribute,
  type,
  isOtherAttribute,
  updateValue,
  value,
  description,
  options,
}: {
  label?: string;
  attribute: string;
  type?: string;
  isOtherAttribute?: boolean;
  updateValue: (
    value: string | null,
    attribute: string,
    isOtherAttribute?: boolean,
  ) => void;
  value: string;
  description?: string;
  options?: string[];
}) => {
  const handleUpdateValue = (changedValue: string | null) => {
    updateValue(changedValue, attribute, isOtherAttribute);
  };

  if (type === "xs:boolean") {
    return (
      <InspectorSegmentedControlInput
        attribute={attribute}
        value={value}
        updateValue={handleUpdateValue}
        description={description}
      />
    );
  }

  if (options) {
    return (
      <InspectorSelectInput
        attribute={attribute}
        value={value}
        updateValue={handleUpdateValue}
        options={options}
        description={description}
      />
    );
  }

  return (
    <InspectorTextInput
      value={value}
      attribute={attribute}
      label={label}
      type={type}
      updateValue={handleUpdateValue}
      description={description}
    />
  );
};

export const InspectorInput = memo(InspectorInputInternal);
