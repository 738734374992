import MonacoEditor, { loader, Monaco } from "@monaco-editor/react";
import { editor } from "monaco-editor";
import * as React from "react";
import { useEffect, useRef } from "react";

loader.init().then((monaco: Monaco) => {
  monaco.editor.defineTheme("darkTheme", {
    base: "vs-dark",
    inherit: true,
    rules: [],
    colors: {
      "editor.background": "#222222",
    },
  });
});

const CodeEditor = ({
  value,
  onChange,
  onMount,
}: {
  value?: string;
  onChange?: (code: string) => void;
  onMount: (editor: editor.IStandaloneCodeEditor, monaco: Monaco) => void;
}) => {
  const editorRef = useRef<editor.IStandaloneCodeEditor>();

  const resizeEditor = () => {
    if (editorRef.current) {
      editorRef.current.layout({
        height: 0,
        width: 0,
      });
      editorRef.current.layout();
    }
  };

  useEffect(() => {
    window.addEventListener("resize", resizeEditor);
    window.addEventListener("editor-layout", resizeEditor);

    return () => {
      window.removeEventListener("resize", resizeEditor);
      window.removeEventListener("editor-layout", resizeEditor);
    };
  }, []);

  return (
    <MonacoEditor
      theme={"darkTheme"}
      defaultLanguage="html"
      loading=""
      options={{
        wordWrap: "on",
        fontSize: 13,
        tabSize: 2,
        minimap: {
          enabled: false,
        },
        lineNumbersMinChars: 4,
        scrollBeyondLastLine: false,
        scrollbar: {
          vertical: "visible",
          verticalScrollbarSize: 8,
          horizontalScrollbarSize: 8,
        },
      }}
      value={value}
      onChange={(newValue?: string) => {
        onChange && onChange(newValue || "");
      }}
      onMount={(
        standaloneCodeEditor: editor.IStandaloneCodeEditor,
        monaco: Monaco,
      ) => {
        // Set end of line
        const model = standaloneCodeEditor.getModel();
        model?.setEOL(monaco.editor.EndOfLineSequence.LF);
        // console.log('Monaco EOL', editor.getModel()?.getEndOfLineSequence())

        onMount && onMount(standaloneCodeEditor, monaco);
        editorRef.current = standaloneCodeEditor;
      }}
    />
  );
};

export default CodeEditor;
