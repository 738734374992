import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import * as React from "react";

import serverApi from "~/library/serverApi/serverApi";

const DeleteStaticVersionModal = ({
  open,
  onClose,
  projectId,
  staticVersionId,
  onDelete,
}: {
  open: boolean;
  onClose: () => void;
  projectId: string;
  staticVersionId: string;
  onDelete: () => void;
}) => {
  const deleteStaticVersion = async () => {
    const res = await serverApi.deleteStaticVersion({
      parameters: {
        projectId,
        staticVersionId,
      },
      body: null,
    });
    if (res.code === 200) {
      onDelete();
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Delete static version?</DialogTitle>

      <DialogContent>
        <DialogContentText>
          This static version will no longer be accessible. Are you sure you
          wish to continue?
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ padding: "0 24px 20px 24px" }}>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={deleteStaticVersion}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteStaticVersionModal;
