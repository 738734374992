import styled from "@emotion/styled";
import { Stack, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useRef, useState } from "react";

import Icon from "~/components/Icon";
import appState from "~/library/appState";
import * as domUtils from "~/library/domUtils";

const Container = styled.div({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  margin: "2px 0",
  userSelect: "none",
});

const ElemRootContainer = styled.div<{
  selected?: boolean;
  disabled?: boolean;
}>(({ selected, disabled, theme }) => ({
  position: "relative",
  minWidth: "100%",
  height: "32px",
  margin: "0 -0px",
  padding: "0 16px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  transition: "all 0.1s",
  borderRadius: "4px",
  cursor: "pointer",
  border: `1px solid transparent`,
  zIndex: "0",
  ...(selected
    ? {
        borderColor: theme.palette.primary.light,
        backgroundColor: "#555555 !important",
      }
    : {}),
  ...(disabled
    ? {
        pointerEvents: "none",
      }
    : {
        "&:hover": {
          backgroundColor: "#444444",
        },
      }),
}));

const ElemChildrenContainer = styled.div<{ selected?: boolean }>(
  ({ selected }) => ({
    position: "relative",
    minWidth: "calc(100% - 24px)",
    margin: "0 -0px 4px 24px",
    padding: "0 0px 0 0px",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    zIndex: "3",
    ...(selected
      ? {
          backgroundColor: "#3A3A3A !important",
        }
      : {}),
  }),
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NestingIndicator = styled.div(({ theme }) => ({
  position: "absolute",
  left: "0px",
  top: "2px",
  bottom: "2px",
  width: "1px",
  borderLeft: "1px solid rgba(255, 255, 255, 0.05)",
}));

const Name = styled(Typography)({
  fontSize: "13px",
  color: "rgba(255, 255, 255, 0.95)",
  whiteSpace: "nowrap",
}) as typeof Typography;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RootDropZoneIndicator = styled.div(({ theme }) => ({
  position: "absolute",
  right: "8px",
  height: "20px",
  left: "16px",
  bottom: "-2px",
  zIndex: "1",
  paddingTop: "18px",
  // backgroundColor: 'rgba(255, 0, 0, 0.2)'
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ChildDropZoneIndicator = styled.div(({ theme }) => ({
  position: "absolute",
  right: "8px",
  height: "20px",
  left: "16px",
  zIndex: "4",
  top: "-14px",
  paddingTop: "13px",
  // backgroundColor: 'rgba(0, 255, 0, 0.2)'
}));

const DropZoneIndicatorFill = styled.div<{ visible?: boolean }>(
  ({ theme, visible }) => ({
    width: "100%",
    height: "4px",
    backgroundColor: visible ? theme.palette.primary.light : "transparent",
    borderRadius: "2px",
    transition: "all 0.1s",
  }),
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CollapseChildrenButtonContainer = styled.div(({ theme }) => ({
  position: "absolute",
  left: "-4px",
  height: "100%",
  width: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const DropZone = ({
  isChild,
  enabled,
  onCreateElement,
  onMoveElement,
}: {
  isChild?: boolean;
  enabled?: boolean;
  onCreateElement?: (elem: HTMLElement) => void;
  onMoveElement?: (elem: HTMLElement) => void;
}) => {
  const dragEventCount = useRef(0);
  const [draggingOver, setDraggingOver] = useState(false);

  const DropIndicator = isChild
    ? ChildDropZoneIndicator
    : RootDropZoneIndicator;

  return (
    <DropIndicator
      style={{
        pointerEvents: !enabled ? "none" : undefined,
      }}
      onDragEnter={(evt) => {
        evt.preventDefault();

        dragEventCount.current++;
        if (dragEventCount.current === 1) {
          setDraggingOver(true);
        }
      }}
      onDragLeave={(evt) => {
        evt.preventDefault();

        dragEventCount.current--;
        if (dragEventCount.current === 0) {
          setDraggingOver(false);
        }
      }}
      onDragOver={(evt) => {
        evt.preventDefault();
      }}
      onDrop={(evt) => {
        evt.preventDefault();

        dragEventCount.current = 0;
        setDraggingOver(false);

        const itemType = evt.dataTransfer.items?.[0]?.type;

        // Get value of dropped item
        evt.dataTransfer.items[0].getAsString((value) => {
          if (!appState.project?.elementHolder) {
            return;
          }

          // Create new element
          if (itemType === "text/plain") {
            const containerElem = document.createElement("div");
            containerElem.innerHTML = value;
            const mmlElem = containerElem.children[0] as HTMLElement;

            onCreateElement?.(mmlElem);
          }

          // Move element in scene
          else if (itemType === "element-path") {
            const elemPath = JSON.parse(value);
            const draggedElem = domUtils.pathToElement(
              appState.project.elementHolder,
              elemPath,
            );
            onMoveElement?.(draggedElem);
          }
        });
      }}
    >
      <DropZoneIndicatorFill visible={draggingOver} />
    </DropIndicator>
  );
};

const Elem = ({
  elem,
  onClick,
  zIndex,
  enableDropZones,
}: {
  elem: HTMLElement;
  onClick: (elem: HTMLElement) => void;
  zIndex?: number;
  enableDropZones?: boolean;
}) => {
  const tag = elem.tagName.toLowerCase();
  const id = elem.getAttribute("id");
  const children = Array.from(elem.children) as Array<HTMLElement>;

  const [collapsed, setCollapsed] = useState(false);

  // Skip scripts
  if (tag === "script" || tag === "removed-script") {
    return null;
  }

  const isSelected = appState?.project?.selectedElements?.includes(elem);

  const staticDocumentRevision = appState.project?.staticDocumentRevision || 0;

  // NOTE: This determines whether a tag can have children
  // For now, all tags can have children
  const canContainChildren = true; // CONTAINER_ELEM_TAGS.includes(tag)

  const createElementAfter = async (newElem: HTMLElement) => {
    const insertedElem = await appState.project?.insertElement(newElem, elem);
    if (insertedElem) {
      appState.project?.setSelectedElements([insertedElem]);
    }
  };

  const moveElementAfter = async (otherElem: HTMLElement) => {
    const movedElem = await appState.project?.moveElement(otherElem, elem);
    if (movedElem) {
      appState.project?.setSelectedElements([movedElem]);
    }
  };

  const createElementInside = async (newElem: HTMLElement) => {
    const insertedElem = await appState.project?.insertElement(
      newElem,
      undefined,
      elem,
    );
    if (insertedElem) {
      appState.project?.setSelectedElements([insertedElem]);
    }
  };

  const moveElementInside = async (otherElem: HTMLElement) => {
    const movedElem = await appState.project?.moveElement(
      otherElem,
      undefined,
      elem,
    );
    if (movedElem) {
      appState.project?.setSelectedElements([movedElem]);
    }
  };

  const createDragGhost = () => {
    const dragGhostElement = document.createElement("div");
    dragGhostElement.setAttribute("id", "scene-object-drag-ghost");

    dragGhostElement.innerHTML = `<span style="font-weight: 700;">${tag}</span>`;

    dragGhostElement.style.backgroundColor = "black";
    dragGhostElement.style.padding = "8px";
    dragGhostElement.style.borderRadius = "4px";
    dragGhostElement.style.fontSize = "13px";
    dragGhostElement.style.width = "fit-content";

    document.body.appendChild(dragGhostElement);
    return dragGhostElement;
  };

  const removeDragGhost = () => {
    document.getElementById("scene-object-drag-ghost")?.remove();
  };

  return (
    <Container style={{ zIndex: zIndex || 0 }}>
      <ElemRootContainer
        disabled={enableDropZones}
        draggable
        onDragStart={(evt) => {
          if (!appState.project?.elementHolder || !appState.project?.clientId) {
            evt.preventDefault();
            return;
          }

          appState.project?.setSelectedElements(null);

          const elemPath = domUtils.elementToPath(
            appState.project.elementHolder,
            elem,
          );

          evt.currentTarget.style.background = "#555555";

          const ghost = createDragGhost();
          evt.dataTransfer.setDragImage(ghost, 0, 40);
          evt.dataTransfer.setData("element-path", JSON.stringify(elemPath));
        }}
        onDragEnd={(evt) => {
          evt.currentTarget.style.background = "transparent";

          removeDragGhost();
        }}
        onClick={(evt) => {
          evt.stopPropagation();

          if (!appState.project?.clientId) return;

          onClick(elem);
        }}
        selected={isSelected}
      >
        {canContainChildren && children.length > 0 && (
          <CollapseChildrenButtonContainer
            onClick={() => setCollapsed(!collapsed)}
          >
            <Icon
              icon={collapsed ? "arrowRight" : "arrowDown"}
              size="16px"
              color="rgba(255, 255, 255, 0.35)"
            />
          </CollapseChildrenButtonContainer>
        )}

        {/* Left side */}
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          position="relative"
        >
          <Icon icon={tag} size="16px" color="rgba(255, 255, 255, 0.35)" />

          <Name>{id ? id : tag}</Name>
        </Stack>

        {/* Right side */}
        <Stack direction="row" alignItems="center" spacing={1}></Stack>
      </ElemRootContainer>

      {canContainChildren && (
        <ElemChildrenContainer selected={isSelected}>
          {!collapsed && <NestingIndicator />}
          <DropZone
            isChild
            enabled={enableDropZones}
            onCreateElement={createElementInside}
            onMoveElement={moveElementInside}
          />

          {!collapsed &&
            children.map((childElem, index) => (
              <Elem
                key={
                  String(staticDocumentRevision) + index + childElem.outerHTML
                }
                elem={childElem}
                onClick={(el) => onClick(el)}
                zIndex={index}
                enableDropZones={enableDropZones}
              />
            ))}
        </ElemChildrenContainer>
      )}

      <DropZone
        enabled={enableDropZones}
        onCreateElement={createElementAfter}
        onMoveElement={moveElementAfter}
      />
    </Container>
  );
};

export default observer(Elem);
