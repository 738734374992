import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";

import serverApi from "~/library/serverApi/serverApi";

const UpdateProjectDescriptionModal = ({
  open,
  onClose,
  projectId,
  currentDescription,
  onUpdate,
}: {
  open: boolean;
  onClose: () => void;
  projectId: string;
  currentDescription: string;
  onUpdate: (description: string) => void;
}) => {
  const [description, setDescription] = useState(currentDescription);

  const updateProjectDescription = async () => {
    const res = await serverApi.updateProject({
      parameters: {
        projectId,
      },
      body: {
        description,
      },
    });
    if (res.code === 200) {
      onUpdate && onUpdate(description);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Update project description</DialogTitle>

      <DialogContent>
        <TextField
          multiline
          sx={{
            marginTop: "12px",
          }}
          minRows={3}
          maxRows={5}
          color="secondary"
          autoFocus
          id="description"
          placeholder="Enter a description..."
          type="text"
          autoComplete="off"
          fullWidth
          variant="outlined"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </DialogContent>

      <DialogActions sx={{ padding: "0 24px 20px 24px" }}>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={updateProjectDescription}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateProjectDescriptionModal;
