import { User } from "mml-editor-api-schema";
import { configure, makeAutoObservable } from "mobx";

import Project from "~/library/project";

configure({
  enforceActions: "never",
});

class AppState {
  accessToken?: string = undefined;
  accessTokenExpiresAt?: number = undefined;
  user?: User = undefined;
  project?: Project = undefined;

  constructor() {
    makeAutoObservable(this);
  }
}

const appState = new AppState();
export default appState;
