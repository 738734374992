import styled from "@emotion/styled";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import { ChangeEvent, useLayoutEffect, useState } from "react";

import Icon from "~/components/Icon";
import { ToolTipIcon } from "~/screens/Editor/panels/Inspector/InspectorInput";

const InspectorInputContainer = styled.div<{ isError: boolean }>(
  ({ isError }) => ({
    flex: 1,
    display: "flex",
    background: "rgb(85, 85, 85)",
    borderRadius: 4,
    boxSizing: "border-box",
    border: `1px solid ${isError ? "red" : "transparent"}`,
    alignItems: "stretch",
    position: "relative",
    padding: "16px 0 3px",
  }),
);

const InspectorInputLabel = styled.label<{
  isEmpty: boolean;
  isFocused: boolean;
}>(({ isEmpty, isFocused }) => {
  const isLarge = isEmpty && !isFocused;
  return {
    position: "absolute",
    top: isLarge ? 6 : 0,
    left: isLarge ? 8 : 6,
    fontSize: isLarge ? 14 : 11,
    display: "block",
    width: "calc(100% - 16px)",
    cursor: "text",
    color: isFocused ? "rgb(255, 255, 255, 1)" : "rgb(255, 255, 255, 0.5)",
    transition: "all 0.2s ease-in-out", // Conditional animation.
  };
});

const InspectorInputElement = styled.input({
  display: "block",
  width: "100%",
  background: "transparent",
  padding: "0 6px",
  border: "none",
  outline: "none",
  color: "white",
});

const InspectorTextareaElement = styled(TextareaAutosize)({
  display: "block",
  width: "100%",
  background: "transparent",
  padding: "0 6px",
  border: "none",
  outline: "none",
  color: "white",
  resize: "none",
});

const isInputError = (changedValue: string, type?: string) => {
  if (!changedValue) return false;

  if (type === "xs:integer" && !Number.isInteger(Number(changedValue))) {
    return true;
  }

  if (type === "xs:float" && Number.isNaN(Number(changedValue))) {
    return true;
  }

  if (type === "URI") {
    try {
      new URL(changedValue ?? "");
    } catch (_) {
      return true;
    }
  }

  return false;
};

export default function InspectorTextInput({
  attribute,
  label = attribute,
  value: initialValue,
  description,
  updateValue,
  type,
}: {
  label?: string;
  attribute: string;
  value: string;
  updateValue: (value: string | null) => void;
  description?: string;
  type?: string;
}) {
  const [value, setValue] = useState(initialValue);
  const [isError, setIsError] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  useLayoutEffect(() => {
    setIsError(isInputError(initialValue, type));
  }, []);

  const handleInputChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const changedValue = e.target.value;

    setIsError(isInputError(changedValue, type));

    setValue(changedValue);
    updateValue(changedValue);
  };

  const onFocus = () => {
    setIsFocused(true);
  };

  const onBlur = () => {
    setIsFocused(false);
  };

  return (
    <InspectorInputContainer isError={isError}>
      {description && (
        <Tooltip title={description} placement="top">
          <ToolTipIcon>
            <Icon icon="info" size="14px" color="#808080" />
          </ToolTipIcon>
        </Tooltip>
      )}
      <InspectorInputLabel
        isFocused={isFocused}
        isEmpty={!value}
        htmlFor={attribute}
      >
        {label}
      </InspectorInputLabel>
      {["xs:integer", "xs:float"].includes(type as string) ? (
        <InspectorInputElement
          id={attribute}
          value={value ?? ""}
          onChange={handleInputChange}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      ) : (
        <InspectorTextareaElement
          id={attribute}
          value={value ?? ""}
          onChange={handleInputChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        />
      )}
    </InspectorInputContainer>
  );
}
