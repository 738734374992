import styled from "@emotion/styled";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import { ChangeEvent, memo, useEffect, useState } from "react";

import Icon from "~/components/Icon";

const InspectorElementContainer = styled.div({
  flex: 1,
  display: "flex",
  alignItems: "flex-end",
  position: "relative",
  padding: "16px 0 3px",
  background: "rgb(85, 85, 85)",
  borderRadius: 4,
});

const InspectorLabel = styled.label({
  position: "absolute",
  top: 0,
  left: 6,
  fontSize: 11,
  display: "block",
  width: "calc(100% - 16px)",
  cursor: "text",
  color: "rgb(255, 255, 255, 0.5)",
  transition: "all 0.2s ease-in-out",
});

const ToolTipIcon = styled.div({
  position: "absolute",
  top: -3,
  right: 1,
});

const InspectorInputElement = styled.input({
  display: "block",
  width: "100%",
  background: "transparent",
  paddingLeft: 6,
  paddingRight: 20,
  border: "none",
  outline: "none",
  color: "white",
});
const InspectorColorPickerInternal = ({
  attribute,
  label = attribute,
  value: initialValue,
  updateValue,
  description,
}: {
  label?: string;
  attribute: string;
  value: string;
  updateValue: (value: string | null, attribute: string) => void;
  description?: string;
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    updateValue(e.target.value, attribute);
  };

  return (
    <InspectorElementContainer>
      <Tooltip title={description}>
        <ToolTipIcon>
          <Icon icon="info" size="14px" color="#808080" />
        </ToolTipIcon>
      </Tooltip>
      <InspectorLabel htmlFor={attribute}>{label}</InspectorLabel>
      <InspectorInputElement
        type="color"
        id={attribute}
        value={value ?? ""}
        onChange={handleInputChange}
      />
    </InspectorElementContainer>
  );
};

export const InspectorColorPicker = memo(InspectorColorPickerInternal);
