import { useEffect } from "react";

const REDIRECT_URLS = {
  improbablePrivacy: "https://www.improbable.io/privacy-policy",
};

const Redirect = ({
  redirectTo,
}: {
  redirectTo: keyof typeof REDIRECT_URLS;
}) => {
  useEffect(() => {
    const url = REDIRECT_URLS[redirectTo];
    window.location.href = url;
  }, [redirectTo]);

  return null;
};

export default Redirect;
