import styled from "@emotion/styled";
import * as React from "react";

const Container = styled.div<{ fullWidth?: boolean; height?: number }>(
  ({ fullWidth, height }) => ({
    width: fullWidth ? "120%" : "100%",
    height: height ?? "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: fullWidth ? "0 -20px" : "0",
  }),
);

const Divider = styled.div({
  width: "100%",
  height: "1px",
  backgroundColor: "#444444",
});

const PanelDivider = ({
  fullWidth,
  height,
}: {
  fullWidth?: boolean;
  height?: number;
}) => {
  return (
    <Container fullWidth={fullWidth} height={height}>
      <Divider />
    </Container>
  );
};

export default PanelDivider;
