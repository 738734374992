import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";
import * as React from "react";

import serverApi from "~/library/serverApi/serverApi";

const RenameProjectModal = ({
  open,
  onClose,
  projectId,
  currentName,
  onRename,
}: {
  open: boolean;
  onClose: () => void;
  projectId: string;
  currentName: string;
  onRename: (name: string) => void;
}) => {
  const [name, setName] = useState(currentName);

  const renameProject = async () => {
    if (!name.length) return;

    const res = await serverApi.updateProject({
      parameters: {
        projectId,
      },
      body: {
        name,
      },
    });
    if (res.code === 200) {
      onRename(name);
      onClose();
    }
  };

  const checkForSubmit = (e: any) => {
    if (e.keyCode === 13) {
      renameProject();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Rename project</DialogTitle>

      <DialogContent>
        <TextField
          sx={{
            marginTop: "12px",
          }}
          color="secondary"
          autoFocus
          id="name"
          label="Project name"
          type="text"
          autoComplete="off"
          fullWidth
          variant="outlined"
          value={name}
          onKeyUp={checkForSubmit}
          onChange={(e) => setName(e.target.value)}
        />
      </DialogContent>

      <DialogActions sx={{ padding: "0 24px 20px 24px" }}>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={renameProject}
          disabled={!name.length}
        >
          Rename
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RenameProjectModal;
