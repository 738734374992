import { EditableNetworkedDOM } from "@mml-io/networked-dom-document";
import { DOMSanitizer } from "@mml-io/networked-dom-web";
import { IframeObservableDOMFactory } from "@mml-io/networked-dom-web-runner";
import {
  FromBroadcastInstanceMessage,
  NetworkedDOMBroadcastRunner,
  ToBroadcastInstanceMessage,
} from "@mml-io/networked-dom-web-runner-broadcast";
import { LogMessage } from "@mml-io/observable-dom-common";
import debounce from "debounce";
import beautify from "js-beautify";
import {
  Asset as AssetSchema,
  File as FileSchema,
  Project as ProjectSchema,
} from "mml-editor-api-schema";
import { configure, makeObservable, observable } from "mobx";

import appState from "~/library/appState";
import { attributeDiff } from "~/library/diff";
import * as domUtils from "~/library/domUtils";
import { getNextClientId } from "~/library/playClientUtils";
import serverApi from "~/library/serverApi/serverApi";
import SessionApi from "~/library/sessionApi/sessionApi";
import { CLIENT_TYPES, ClientType, PlayClient } from "~/types/playClients";

import ProjectFile from "./file";

configure({
  enforceActions: "never",
});

export default class Project {
  id: string;
  name: string;
  description: string;
  thumbnailUrl?: string;
  content: string;
  sanitisedContent: string;
  createdBy: string;
  isPublic: boolean;
  isPublished: boolean;

  staticDocument: EditableNetworkedDOM;
  staticDocumentRevision: number;

  localDocument?: EditableNetworkedDOM;
  documentBroadcastRunner?: NetworkedDOMBroadcastRunner;
  broadcasting = false;

  audioSource?: AudioBufferSourceNode;

  session?: SessionApi;
  connected: boolean;

  readOnly = false;
  clientIsCreator = false;
  localExecutionAllowed = false;

  clientId?: string;
  clients: {
    [id: string]: {
      id: string;
      color: string;
      user: {
        id?: string;
        name?: string;
        picture?: string;
      };
    };
  };

  files: {
    [fileId: string]: ProjectFile;
  } = {};

  assets: AssetSchema[] = [];

  logs: any[] = [];

  elementHolder?: HTMLElement = undefined;
  selectedElementPaths: Array<Array<number>> | null = null;
  selectedElements: Array<HTMLElement> | null = null;

  playClients: PlayClient[];

  constructor(
    projectData: ProjectSchema,
    local?: boolean,
    disallowLocalExecution?: boolean,
  ) {
    this.id = projectData.id;
    this.name = projectData.name;
    this.description = projectData.description;
    this.thumbnailUrl = projectData.thumbnailUrl;
    this.createdBy = projectData.createdBy;
    this.isPublic = projectData.isPublic;
    this.isPublished = projectData.isPublished;
    this.assets = projectData.assets || [];

    this.connected = false;
    this.clientId = undefined;
    this.clients = {};
    this.localExecutionAllowed = !disallowLocalExecution;
    this.playClients = [
      {
        type: CLIENT_TYPES.FLOATING,
        id: getNextClientId(),
      },
    ];

    if (local) {
      this.localDocument = new EditableNetworkedDOM(
        "index.html",
        IframeObservableDOMFactory,
        true,
        (message) => {
          this.addLogMessage(message);
        },
      );
    }

    this.staticDocument = new EditableNetworkedDOM(
      "index.html",
      IframeObservableDOMFactory,
      true,
    );
    this.staticDocumentRevision = 1;

    for (const fileData of projectData.files || []) {
      this.addFile(fileData);
    }

    this.updateContent();
    this.updateStaticDocument();
    this.updateLocalDocument();

    makeObservable(this, {
      name: observable,
      description: observable,
      thumbnailUrl: observable,
      content: observable,
      sanitisedContent: observable,
      isPublic: observable,
      isPublished: observable,
      connected: observable,
      readOnly: observable,
      clientIsCreator: observable,
      localExecutionAllowed: observable,
      broadcasting: observable,
      clientId: observable,
      clients: observable,
      logs: observable,
      assets: observable,
      selectedElementPaths: observable,
      selectedElements: observable,
      elementHolder: observable,
      staticDocumentRevision: observable,
      playClients: observable,
    });
  }

  addFile = (fileData: FileSchema) => {
    this.files[fileData.id] = new ProjectFile(
      this,
      fileData,
      debounce(() => {
        this.updateContent();
        this.updateLocalDocument();
        this.updateStaticDocument();
      }, 100),
    );
  };

  formatCode = (code?: string) => {
    if (!code) {
      const updatedDocumentHolder = this.getContentAsElem();
      code = updatedDocumentHolder.innerHTML;
    }

    code = beautify.html(code, {
      indent_size: 2,
      indent_char: " ",
      max_preserve_newlines: 2,
      preserve_newlines: true,
      indent_scripts: "normal",
      end_with_newline: false,
      wrap_line_length: 0,
      indent_inner_html: false,
      indent_empty_lines: false,
    });

    this.applyContent(code);
  };

  updateElement = (element: HTMLElement, attributes: object) => {
    if (!this.elementHolder) return;

    // Get path to element within its container
    // NOTE: The sanitized content has the SAME structure as the original
    // therefore the index path obtained here can be used
    const elemPath = domUtils.elementToPath(this.elementHolder, element);

    // Get same element in the new DOM using the path
    const updatedDocumentHolder = this.getContentAsElem();
    const updatedElem = domUtils.pathToElement(updatedDocumentHolder, elemPath);

    // Update element attributes
    for (const attrKey in attributes) {
      const value = (attributes as any)[attrKey];
      if (value) {
        updatedElem.setAttribute(attrKey, value);
      } else {
        updatedElem.removeAttribute(attrKey);
      }
    }

    this.formatCode(updatedDocumentHolder.innerHTML);
  };

  updateElementFromSourceDocument = (
    elemPath: number[],
    attributes: object,
  ) => {
    if (!this.elementHolder) return;

    // Get same element in the new DOM using the path
    const updatedDocumentHolder = this.getContentAsElem();
    const updatedElem = domUtils.pathToElement(updatedDocumentHolder, elemPath);

    if (!updatedElem) return;

    // Update element attributes
    for (const attrKey in attributes) {
      const value = (attributes as any)[attrKey];
      if (value !== undefined && value !== null) {
        updatedElem.setAttribute(attrKey, value);
      } else {
        updatedElem.removeAttribute(attrKey);
      }
    }

    this.formatCode(updatedDocumentHolder.innerHTML);
  };

  deleteElements = (elements: Array<HTMLElement>) => {
    if (!this.elementHolder) return;

    // Get same element in the new DOM using the path
    const updatedDocumentHolder = this.getContentAsElem();

    for (const elem of elements) {
      const elemPath = domUtils.elementToPath(this.elementHolder, elem);

      const updatedElem = domUtils.pathToElement(
        updatedDocumentHolder,
        elemPath,
      );
      updatedElem.remove();
    }

    this.formatCode(updatedDocumentHolder.innerHTML);
  };

  moveElement = (
    element: HTMLElement,
    previousElement?: HTMLElement,
    parentElement?: HTMLElement,
  ): Promise<HTMLElement | undefined> => {
    if (!this.elementHolder) return Promise.resolve(undefined);
    const holder = this.elementHolder;

    const updatedDocumentHolder = this.getContentAsElem();

    const existingElemPath = domUtils.elementToPath(holder, element);
    const existingElem = domUtils.pathToElement(
      updatedDocumentHolder,
      existingElemPath,
    );

    if (previousElement) {
      const previousElemPath = domUtils.elementToPath(holder, previousElement);
      const previousElem = domUtils.pathToElement(
        updatedDocumentHolder,
        previousElemPath,
      );

      previousElem.after("\n", existingElem);
    } else if (parentElement) {
      const parentElemPath = domUtils.elementToPath(holder, parentElement);
      const parentElem = domUtils.pathToElement(
        updatedDocumentHolder,
        parentElemPath,
      );

      parentElem.prepend("\n", existingElem);
    } else {
      updatedDocumentHolder.prepend("\n", existingElem);
    }

    this.formatCode(updatedDocumentHolder.innerHTML);

    const movedElemPath = domUtils.elementToPath(
      updatedDocumentHolder,
      existingElem,
    );

    return this.waitForElementPresence(existingElem, movedElemPath);
  };

  insertElement = (
    element: HTMLElement,
    previousElement?: HTMLElement,
    parentElement?: HTMLElement,
  ): Promise<HTMLElement | undefined> => {
    if (!this.elementHolder) return Promise.resolve(undefined);
    const holder = this.elementHolder;

    const updatedDocumentHolder = this.getContentAsElem();

    if (previousElement) {
      const previousElemPath = domUtils.elementToPath(holder, previousElement);
      const previousElem = domUtils.pathToElement(
        updatedDocumentHolder,
        previousElemPath,
      );

      previousElem.after("\n", element, "\n");
    } else if (parentElement) {
      const parentElemPath = domUtils.elementToPath(holder, parentElement);
      const parentElem = domUtils.pathToElement(
        updatedDocumentHolder,
        parentElemPath,
      );

      parentElem.prepend("\n", element, "\n");
    } else {
      updatedDocumentHolder.prepend("\n", element, "\n");
    }

    this.formatCode(updatedDocumentHolder.innerHTML);

    const insertedElemPath = domUtils.elementToPath(
      updatedDocumentHolder,
      element,
    );

    return this.waitForElementPresence(element, insertedElemPath);
  };

  private waitForElementPresence = (
    element: HTMLElement,
    elemPath: Array<number>,
  ): Promise<HTMLElement | undefined> => {
    return new Promise((resolve) => {
      const CHECK_INTERVAL = 10;
      const STOP_AFTER_ATTEMPTS = 500 / CHECK_INTERVAL;

      // Keep trying to get the inserted element until it's available
      let attempts = 0;
      const getInsertedElemInterval = setInterval(() => {
        // Get same element in the new DOM using the path
        const insertedElem = this.elementHolder
          ? domUtils.pathToElement(this.elementHolder, elemPath)
          : undefined;
        if (insertedElem?.isEqualNode(element)) {
          clearInterval(getInsertedElemInterval);
          resolve(insertedElem);
        }

        // Fail after max attempts
        else if (attempts > STOP_AFTER_ATTEMPTS) {
          clearInterval(getInsertedElemInterval);
          resolve(undefined);
        }
        attempts++;
      }, CHECK_INTERVAL);
    });
  };

  getContentAsElem = () => {
    // Create new instance of the current content
    const holder = document.createElement("div");
    holder.innerHTML = this.content;

    return holder;
  };

  setSelectedElements = (elements: Array<HTMLElement> | null) => {
    if (!this.elementHolder) return;
    const holder = this.elementHolder;

    if (!elements) {
      this.selectedElementPaths = null;
      this.selectedElements = null;
    } else {
      if (!this.clientId) return;

      this.selectedElements = elements;
      this.selectedElementPaths = elements.map((element) => {
        return domUtils.elementToPath(holder, element);
      });
    }
  };

  setSelectedElementsWithPaths = (elemPaths: Array<Array<number>> | null) => {
    if (!this.elementHolder) return;
    const holder = this.elementHolder;

    if (!elemPaths?.length) {
      this.selectedElementPaths = null;
      this.selectedElements = null;
    } else {
      this.selectedElementPaths = elemPaths;
      this.selectedElements = elemPaths.map((elemPath) => {
        return domUtils.pathToElement(holder, elemPath);
      });
    }
  };

  addNewClient = (client: ClientType) => {
    if (this.playClients.length >= 4) return;
    this.playClients.push({ type: client, id: getNextClientId() });
  };

  removeClient = (id: number) => {
    this.playClients = this.playClients.filter((c) => c.id !== id);
  };

  private applyContent = (newContent: string) => {
    const targetFile = this.files[Object.keys(this.files)[0]];
    const diff = attributeDiff(targetFile.content, newContent);

    // TODO: potentially handle multiple files
    // For now, only one file should exist in the project
    targetFile.applyDiff(diff);
  };

  private addLogMessage = (message: any) => {
    // Do not add consecutive restart logs
    if (message.level === "restart" && this.logs[0]?.level === "restart")
      return;

    this.logs.unshift({
      timestamp: new Date().getTime(),
      ...message,
    });
  };

  private updateContent = () => {
    // Combine content of all files
    // TODO: Inject contents of all files into the 'index' file
    const fileContents = [];
    for (const fileId in this.files) {
      const file = this.files[fileId];

      fileContents.push(file.getContent());
    }

    const content = fileContents.join("\n");
    this.content = content;

    this.updateSanitisedContent();
  };

  private updateSanitisedContent = () => {
    const sanitisedDOM = this.getContentAsElem();
    DOMSanitizer.sanitise(sanitisedDOM);
    let content = sanitisedDOM.innerHTML;

    // Remove comments
    content = content.replace(
      /(<!--.*?-->)|(<!--[\S\s]+?-->)|(<!--[\S\s]*?$)/g,
      "",
    );

    // Remove whitespace
    content = content.replace(/>\s+</g, "><").trim();

    // Replace script tags
    content = content.replaceAll(
      "<script></script>",
      "<removed-script></removed-script>",
    );

    this.sanitisedContent = content;
  };

  private updateLocalDocument = () => {
    // Prevent executing scripts locally unless explicitly allowed
    if (!this.localExecutionAllowed) return;

    if (this.localDocument) {
      this.localDocument.load(this.content);

      this.addLogMessage({
        level: "restart",
        content: [],
      });
    }

    this.documentBroadcastRunner?.load({
      htmlContents: this.content,
      htmlPath: "file://test.html",
      ignoreTextNodes: true,
      params: {},
    });
  };

  private updateStaticDocument = () => {
    const newSanitisedContent = this.sanitisedContent;

    this.staticDocument.load(newSanitisedContent);

    const checkLoaded = setInterval(() => {
      if (
        this.elementHolder?.innerHTML === this.sanitisedContent ||
        newSanitisedContent !== this.sanitisedContent
      ) {
        clearInterval(checkLoaded);
        this.staticDocumentRevision = this.staticDocumentRevision + 1;
      }
    }, 10);
  };

  private forceActiveWithAudio = () => {
    if (this.audioSource) return;

    // Create audio source and play it to force tab to stay active when backgrounded
    const audioContext = new AudioContext();
    const buffer = audioContext.createBuffer(
      1,
      audioContext.sampleRate,
      audioContext.sampleRate,
    );
    const nowBuffering = buffer.getChannelData(0);
    for (let i = 0; i < buffer.length; i++) {
      nowBuffering[i] = 0.001;
    }
    this.audioSource = audioContext.createBufferSource();
    this.audioSource.buffer = buffer;
    this.audioSource.loop = true;
    this.audioSource.connect(audioContext.destination);

    if (this.audioSource.context.state !== "suspended") {
      this.audioSource.start();
    } else {
      const startAudio = () => {
        this.audioSource?.start();
        document.removeEventListener("mousedown", startAudio);
        document.removeEventListener("touchstart", startAudio);
      };
      document.addEventListener("mousedown", startAudio);
      document.addEventListener("touchstart", startAudio);
    }
  };

  connectSession() {
    this.session?.dispose();
    this.connected = false;

    this.readOnly = true;

    this.session = new SessionApi({
      baseUrl: window.serverConfig.SESSION_URL,
      projectId: this.id,
      accessToken: appState.accessToken,
      onConnected: () => {
        // no-op
      },
      onDisconnected: () => {
        this.connected = false;
        this.readOnly = true;

        setTimeout(async () => {
          if (this.session) {
            // Trigger reauth if required
            await serverApi.getUser({
              parameters: {},
              body: null,
            });

            // Attempt to reconnect with refreshed access token
            this.session.connect(appState.accessToken);
          }
        }, 1000);
      },
    });

    this.session.v1.on.v1_events_invalidRequest(({ error }) => {
      console.error("Invalid session request:", error);
    });

    this.session.v1.on.v1_events_invalidRequestPayload(
      ({ error, validationErrors }) => {
        console.error(
          "Invalid session request payload:",
          error,
          validationErrors,
        );
      },
    );

    this.session.v1.on.v1_events_clientConnected((connectedClient) => {
      this.clients[connectedClient.id] = connectedClient;

      for (const fileId in this.files) {
        const file = this.files[fileId];
        file.clientConnected();
      }
    });

    this.session.v1.on.v1_events_clientDisconnected((disconnectedClient) => {
      delete this.clients[disconnectedClient.id];

      for (const fileId in this.files) {
        const file = this.files[fileId];
        file.clientDisconnected(disconnectedClient.id);
      }
    });

    this.session.v1.on.v1_events_clients((connected) => {
      this.clients = connected.clients;
      this.clientId = connected.clientId;

      // Consider connected when info about clients is received
      this.connected = true;
      this.readOnly = false;

      // Allow execution because user has explicit access to this project
      this.localExecutionAllowed = true;
      this.updateLocalDocument();

      if (appState.user?.id === this.createdBy) {
        this.clientIsCreator = true;
      }
    });

    this.session.v1.on.v1_events_fileChangeAccepted((fileChange) => {
      const file = this.files[fileChange.fileId];
      if (!file) return;

      file.changeAccepted(fileChange.number);
    });

    this.session.v1.on.v1_events_fileChange((fileChange) => {
      const file = this.files[fileChange.fileId];
      if (!file) return;

      file.change(fileChange.clientId, fileChange.number, fileChange.operation);
    });

    this.session.v1.on.v1_events_file((fileInfo) => {
      const file = this.files[fileInfo.fileId];
      if (!file) return;

      file.init(
        fileInfo.content,
        fileInfo.lastChangeNumber,
        fileInfo.reset,
        fileInfo.clientSelections,
      );
    });

    this.session.v1.on.v1_events_clientFileSelection((clientFileSelection) => {
      const file = this.files[clientFileSelection.fileId];
      if (!file) return;

      file.clientSelection(
        clientFileSelection.clientId,
        clientFileSelection.selection,
      );
    });

    this.session.v1.on.v1_events_executionLogMessage((message) => {
      this.addLogMessage(message as LogMessage);
    });

    this.session.v1.on.v1_events_broadcastRunnerMessage((message) => {
      this.documentBroadcastRunner?.handleMessage(
        message.message as ToBroadcastInstanceMessage,
      );
    });

    this.session.v1.on.v1_events_becomeBroadcastRunner(() => {
      if (this.documentBroadcastRunner) return;

      // Prevent browser throttling when window becomes inactive
      this.forceActiveWithAudio();

      // Create runner
      this.documentBroadcastRunner = new NetworkedDOMBroadcastRunner(
        (fromBroadcastInstanceMessage: FromBroadcastInstanceMessage) => {
          this.session?.v1.v1_requests_broadcastRunnerMessage({
            message: fromBroadcastInstanceMessage,
          });
        },
        IframeObservableDOMFactory,
      );

      this.updateLocalDocument();

      this.broadcasting = true;
    });
  }

  disconnect() {
    this.staticDocument.dispose();
    this.localDocument?.dispose();
    this.documentBroadcastRunner?.dispose();
    this.session?.dispose();
    this.connected = false;

    try {
      this.audioSource?.stop();
    } catch (e) {
      // no-op
    }

    for (const fileId in this.files) {
      const file = this.files[fileId];
      file.detachEditor();
    }
  }

  allowLocalExecution() {
    this.localExecutionAllowed = true;
    this.updateLocalDocument();
  }
}
