import { ComponentTypeFromName } from "openapi-typescript-helpers";

import { mmlEditorSessionSchema } from "./mmlEditorSessionSchema";

export type SessionUser = ComponentTypeFromName<
  typeof mmlEditorSessionSchema,
  "v1_user"
>;

export type ConnectedClientInfo = ComponentTypeFromName<
  typeof mmlEditorSessionSchema,
  "v1_connectedClientInfo"
>;

export type InvalidRequestPayload = ComponentTypeFromName<
  typeof mmlEditorSessionSchema,
  "v1_events_invalidRequestPayload"
>;

export type SessionMessages = {
  [K in keyof (typeof mmlEditorSessionSchema)["components"]["schemas"]]: ComponentTypeFromName<
    typeof mmlEditorSessionSchema,
    K
  >;
};

export const AllSessionMessages: Array<keyof SessionMessages> = Object.keys(
  mmlEditorSessionSchema.components.schemas,
) as unknown as Array<keyof SessionMessages>;
