import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import PlayView from "~/components/PlayView";
import Screen from "~/components/Screen";

const Play = () => {
  const urlParams = useParams();
  const navigate = useNavigate();

  const [projectId, setProjectId] = useState("");

  useEffect(() => {
    const id = urlParams.projectId?.split("-").pop();
    if (!id) {
      navigate("/projects");
      return;
    }
    setProjectId(id);
  }, [navigate, urlParams.projectId]);

  const socketUrl = `${window.serverConfig.EXECUTION_URL}/${projectId}`;

  return (
    <Screen fullWidth noPadding>
      <PlayView url={socketUrl} />
    </Screen>
  );
};

export default Play;
