import type {
  Request as ExpressRequest,
  Response as ExpressResponse,
} from "express";

import { GenericOpenAPIRequest, GenericOpenAPIResponse } from "./handler-types";
import { OpenAPIHandler } from "./handlers";

type ExpressMethods =
  | "get"
  | "head"
  | "post"
  | "put"
  | "delete"
  | "options"
  | "patch"
  | "all";

type ExpressInstance = {
  [key in ExpressMethods]: (
    path: string,
    handler: (req: ExpressRequest, rep: ExpressResponse) => Promise<void>,
  ) => void;
};

export function expressRequestToGenericOpenAPIRequest(
  expressReq: ExpressRequest,
): GenericOpenAPIRequest {
  const headers = new Headers();
  for (const key in expressReq.headers) {
    const value = expressReq.headers[key];
    if (value !== undefined) {
      if (Array.isArray(value)) {
        for (const v of value) {
          headers.append(key, v);
        }
      } else {
        headers.append(key, value);
      }
    }
  }
  const url = `${expressReq.protocol}://${expressReq.get("host")}${expressReq.originalUrl}`;
  return {
    url,
    method: expressReq.method,
    headers,
    bodyString: async () => {
      return Promise.resolve(JSON.stringify(expressReq.body));
    },
  };
}

export function applyGenericOpenAPIResponseToExpressResponse(
  genericRes: GenericOpenAPIResponse,
  expressReply: ExpressResponse,
) {
  expressReply.status(genericRes.statusCode);
  const hdrs: Headers = genericRes.headers;

  hdrs.forEach((value, key) => {
    expressReply.setHeader(key, value);
  });

  if (genericRes.body) {
    expressReply.send(genericRes.body);
  } else {
    expressReply.end();
  }
}

type RequestWithLogging = {
  log?: {
    info: (message: unknown) => void;
    warn: (message: unknown) => void;
    error: (message: unknown) => void;
  };
};

export function registerOpenAPIHandlerToExpress(
  expressInstance: ExpressInstance,
  handler: OpenAPIHandler<ExpressRequest>,
  enableLogging: boolean = true,
) {
  expressInstance[handler.method](
    `${handler.baseUrl}${handler.colonUrlPattern}`,
    async (request: ExpressRequest, reply: ExpressResponse) => {
      const genericReq = expressRequestToGenericOpenAPIRequest(request);
      let response: GenericOpenAPIResponse | Error;
      try {
        const openAPIInfo = {
          openAPIPath: handler.opPath,
          openAPIMethod: handler.method,
          openAPIOperationId: handler.opId,
        };
        if (enableLogging) {
          // Extract the body and log it
          let bodyLog: any;
          const bodyString = await genericReq.bodyString();
          if (bodyString) {
            try {
              const asJSON = JSON.parse(bodyString);
              bodyLog = asJSON;
            } catch {
              (request as RequestWithLogging).log?.warn({
                message: "Failed to parse JSON body",
                body: bodyString,
              });
              bodyLog = bodyString;
            }
          }
          const loggableReq: Exclude<GenericOpenAPIRequest, "bodyString"> & {
            body: any;
            bodyString?: any; // Allows deleting
          } = {
            ...genericReq,
            body: bodyLog,
          };
          delete loggableReq.bodyString;
          (request as RequestWithLogging).log?.info({
            openAPIRequest: loggableReq,
            ...openAPIInfo,
          });
        }
        response = await handler.handle(genericReq, request);
        if (enableLogging) {
          (request as RequestWithLogging).log?.info({
            openAPIResponse: response,
            ...openAPIInfo,
          });
        }
      } catch (err) {
        if (enableLogging) {
          (request as RequestWithLogging).log?.error(err);
        }
        const response: GenericOpenAPIResponse = {
          statusCode: 500,
          headers: new Headers(),
          body: { message: "Internal Server Error" },
        };
        return applyGenericOpenAPIResponseToExpressResponse(response, reply);
      }
      return applyGenericOpenAPIResponseToExpressResponse(response, reply);
    },
  );
}
