import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { Euler, Vector3 } from "three";

import AppState from "~/library/appState";
import { LocalAvatarClient } from "~/library/localAvatarClient/LocalAvatarClient";
import { LocalAvatarServer } from "~/library/localAvatarClient/LocalAvatarServer";
import { getIframeTargetWindow } from "~/library/mml/iframeTarget";

const ClientView = styled.div({
  height: "100%",
  minHeight: 0,
  minWidth: 0,
  position: "relative",
});

function AvatarClient({
  children,
  position,
  rotation,
  server,
  clientId,
}: {
  server: LocalAvatarServer;
  clientId: number;
  position: { x: number; y: number; z: number };
  rotation: { x: number; y: number; z: number };
  children?: React.ReactNode;
}) {
  const [client, setClient] = useState<LocalAvatarClient | null>(null);

  const { project } = AppState;

  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let disposed = false;
    let runnerClient: LocalAvatarClient | null = null;

    getIframeTargetWindow().then((wrapper) => {
      if (!project || disposed) return;

      let remoteHolderElement =
        wrapper.iframeDocument.getElementById("play-panel-holder");
      if (!remoteHolderElement) {
        remoteHolderElement = wrapper.iframeDocument.createElement("div");
        remoteHolderElement.id = "play-panel-holder";
        wrapper.iframeDocument.body.append(remoteHolderElement);
      }

      runnerClient = new LocalAvatarClient(
        server,
        clientId,
        new Vector3(position.x, position.y, position.z),
        new Euler(rotation.x, rotation.y, rotation.z),
      );

      runnerClient.update();
      runnerClient.addDocument(
        project?.localDocument ??
          `${window.serverConfig.EXECUTION_URL}/${project.id}`,
        wrapper.iframeWindow,
        remoteHolderElement,
      );
      setClient(runnerClient);
    });

    return () => {
      disposed = true;
      if (runnerClient) {
        runnerClient.dispose();
      }
    };
  }, [project]);

  useEffect(() => {
    if (elementRef.current && client) {
      client.element.setAttribute("style", "height: 100%");
      elementRef.current.appendChild(client.element);
    }
  }, [elementRef.current, client]);

  if (!client) {
    return null;
  }

  return <ClientView ref={elementRef}>{children}</ClientView>;
}

export default observer(AvatarClient);
