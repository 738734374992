import styled from "@emotion/styled";
import { IconButton, Stack } from "@mui/material";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useNavigate } from "react-router-dom";

import Icon from "~/components/Icon";
import appState from "~/library/appState";
import {
  deleteProjectModal,
  renameProjectModal,
  updateProjectDescriptionModal,
  updateProjectThumbnailModal,
} from "~/modals";

import PanelButton from "../../components/PanelButton";
import PanelDivider from "../../components/PanelDivider";
import PanelHeading from "../../components/PanelHeading";
import PanelText from "../../components/PanelText";

const Container = styled.div(({ theme }) => ({
  flex: "1",
  padding: theme.spacing(2),
  flexDirection: "column",
  overflowX: "hidden",
  display: "flex",
}));

const Thumbnail = styled.div<{ url: string }>(({ url }) => ({
  width: "100%",
  height: "0",
  paddingBottom: "56%",
  backgroundSize: "cover",
  backgroundImage: `url('${url}')`,
  borderRadius: "4px",
}));

const Space = styled.div({
  flex: "1",
});

const InfoPanel = () => {
  const navigate = useNavigate();

  const updateName = () => {
    if (!appState.project) return;

    renameProjectModal({
      projectId: appState.project.id,
      currentName: appState.project.name,
      onRename: (newName: string) => {
        if (!appState.project) return;
        appState.project.name = newName;
      },
    });
  };

  const updateDescription = () => {
    if (!appState.project) return;

    updateProjectDescriptionModal({
      projectId: appState.project.id,
      currentDescription: appState.project.description,
      onUpdate: (description) => {
        if (appState.project) {
          appState.project.description = description;
        }
      },
    });
  };

  const updateThumbnail = () => {
    if (!appState.project) return;

    updateProjectThumbnailModal({});
  };

  const deleteProject = () => {
    if (!appState.project?.id) return;

    deleteProjectModal({
      projectId: appState.project.id,
      onDelete: () => {
        navigate("/projects");
      },
    });
  };

  return (
    <Container>
      {/* Name */}
      <Stack direction="column" spacing={2} alignItems="flex-start">
        <PanelHeading
          rightContent={
            appState.project?.clientIsCreator && (
              <IconButton
                color="secondary"
                sx={{ marginRight: "-8px" }}
                onClick={updateName}
              >
                <Icon icon="edit" size="16px" />
              </IconButton>
            )
          }
        >
          Name
        </PanelHeading>

        <PanelText component="pre">{appState.project?.name}</PanelText>
      </Stack>

      <PanelDivider />

      {/* Description */}
      <Stack direction="column" spacing={2} alignItems="flex-start">
        <PanelHeading
          rightContent={
            appState.project?.clientIsCreator && (
              <IconButton
                color="secondary"
                sx={{ marginRight: "-8px" }}
                onClick={updateDescription}
              >
                <Icon icon="edit" size="16px" />
              </IconButton>
            )
          }
        >
          Description
        </PanelHeading>

        <PanelText component="pre">
          {appState.project?.description &&
          appState.project?.description.length > 0
            ? appState.project.description
            : "No description"}
        </PanelText>
      </Stack>

      <PanelDivider />

      {/* Thumbnail */}
      <Stack direction="column" spacing={2} alignItems="flex-start">
        <PanelHeading
          rightContent={
            appState.project?.clientIsCreator && (
              <IconButton
                color="secondary"
                sx={{ marginRight: "-8px" }}
                onClick={updateThumbnail}
              >
                <Icon icon="edit" size="16px" />
              </IconButton>
            )
          }
        >
          Thumbnail
        </PanelHeading>

        {appState.project?.thumbnailUrl ? (
          <Thumbnail url={appState.project?.thumbnailUrl} />
        ) : (
          <PanelText>No thumbnail</PanelText>
        )}
      </Stack>

      <Space />

      {appState.project?.clientIsCreator && (
        <PanelButton
          variant="outlined"
          color="error"
          fullWidth
          startIcon={<Icon icon="delete" size="16px" color="delete" />}
          onClick={deleteProject}
        >
          Delete project
        </PanelButton>
      )}
    </Container>
  );
};

export default {
  id: "info",
  name: "Info",
  Component: observer(InfoPanel),
};
