import styled from "@emotion/styled";
import { Typography } from "@mui/material";

const PanelText = styled(Typography)({
  fontSize: "13px",
  color: "rgba(255, 255, 255, 0.8)",
  textWrap: "wrap",
  a: {
    color: "white",
  },
}) as typeof Typography;

export default PanelText;
