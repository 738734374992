import styled from "@emotion/styled";
import { Button, Stack, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import * as React from "react";

import appState from "~/library/appState";
import AddNewButton from "~/screens/Editor/panels/Play/AddNewButton";
import ClientView from "~/screens/Editor/panels/Play/ClientView";

import { PanelProps } from "..";

const Container = styled.div({
  flex: "1",
  backgroundColor: "#222222",
  overflow: "hidden",
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const AllowExecutionContainer = styled(Stack)({
  position: "absolute",
  backgroundColor: "rgba(0, 0, 0, 0.6)",
  borderRadius: "4px",
  maxWidth: "70%",
  zIndex: "2",
  display: "flex",
  flexDirection: "column",
  padding: "40px",
  justifyContent: "center",
  alignItems: "center",
}) as typeof Stack;

const PlayPanel = ({ visible }: PanelProps) => {
  const promptToAllowLocalExecution =
    appState.project?.localDocument && !appState.project?.localExecutionAllowed;

  return (
    <Container>
      {promptToAllowLocalExecution && (
        <AllowExecutionContainer direction="column" spacing={3}>
          <Typography fontWeight={500}>Script execution</Typography>
          <Typography fontSize={14} textAlign="center">
            Scripts will be executed in your browser.<br></br>Please proceed
            with caution.
          </Typography>
          <Button
            variant="contained"
            onClick={() => appState.project?.allowLocalExecution()}
          >
            Enable execution
          </Button>
        </AllowExecutionContainer>
      )}

      {visible && <ClientView />}
    </Container>
  );
};

export default {
  id: "play",
  name: "Play",
  MenuComponent: AddNewButton,
  Component: observer(PlayPanel),
};
