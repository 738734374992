import {
  IframeWrapper,
  IframeWrapperResult,
  registerCustomElementsToWindow,
} from "mml-web";

let iframeRemoteSceneWrapperPromise: Promise<IframeWrapperResult>;

export function getIframeTargetWindow(): Promise<IframeWrapperResult> {
  if (iframeRemoteSceneWrapperPromise !== undefined) {
    return iframeRemoteSceneWrapperPromise;
  }
  iframeRemoteSceneWrapperPromise = IframeWrapper.create().then((wrapper) => {
    registerCustomElementsToWindow(wrapper.iframeWindow);
    return wrapper;
  });
  return iframeRemoteSceneWrapperPromise;
}
