import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import * as React from "react";

import Icon from "~/components/Icon";
import appState from "~/library/appState";
import { feedbackModal } from "~/modals";

const FeedbackBubbleContainer = styled.div({
  position: "fixed",
  bottom: "10px",
  right: "20px",
  cursor: "pointer",
});

function FeedbackBubble() {
  if (!appState.user) return null;

  return (
    <FeedbackBubbleContainer onClick={() => feedbackModal({})}>
      <Icon icon="feedback" size="40px" color="var(--home-text-green)" />
    </FeedbackBubbleContainer>
  );
}

export default observer(FeedbackBubble);
