import styled from "@emotion/styled";
import * as React from "react";

import Logo from "~/components/Logo";

const footerLinks = [
  {
    title: "MML Documentation",
    link: "https://www.mml.io",
  },
  {
    title: "Terms of Service",
    link: "https://mmleditor.com/terms",
  },
  {
    title: "Privacy Policy",
    link: "https://mmleditor.com/privacy",
  },
] as const;

const FooterContainer = styled.footer(({ theme }) => ({
  display: "flex",
  backgroundColor: "#333333",
  width: "100vw",
  height: 248,
  flexDirection: "column",
  padding: 20,

  [theme.breakpoints.up("sm")]: {
    padding: "0 40px",
    alignItems: "center",
    height: 50,
    flexDirection: "row",
  },
}));

const Divider = styled.div(({ theme }) => ({
  display: "none",
  height: "100%",
  width: 1,
  background: "#444444",
  marginLeft: 20,
  marginRight: 25,

  [theme.breakpoints.up("sm")]: {
    display: "block",
  },
}));

const FooterLinkList = styled.ul(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: 40,
  listStyle: "none",
  padding: 0,
  marginBottom: 0,

  [theme.breakpoints.up("sm")]: {
    alignItems: "center",
    marginTop: 0,
    flexDirection: "row",
  },
}));

const FooterLinkItem = styled.li(({ theme }) => ({
  fontSize: "0.875rem",
  fontWeight: 400,
  marginBottom: 20,

  a: {
    color: "#FFFFFF",
    textDecoration: "none",
  },

  [theme.breakpoints.up("sm")]: {
    marginBottom: 0,
    marginRight: 40,
  },
}));

const ImprobableText = styled.span(({ theme }) => ({
  fontSize: "0.875rem",
  fontWeight: 400,
  color: "#FFFFFF77",
  marginTop: "auto",

  [theme.breakpoints.up("sm")]: {
    marginTop: 0,
    marginLeft: "auto",
  },
}));

export default function Footer() {
  return (
    <FooterContainer>
      <Logo />
      <Divider />
      <FooterLinkList>
        {footerLinks.map((footerLink) => (
          <FooterLinkItem key={footerLink.title}>
            <a target="_blank" href={footerLink.link} rel="noreferrer">
              {footerLink.title}
            </a>
          </FooterLinkItem>
        ))}
      </FooterLinkList>
      <ImprobableText>© 2023 Improbable</ImprobableText>
    </FooterContainer>
  );
}
