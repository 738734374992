import { SessionMessages } from "mml-editor-api-schema";
import { AllSessionMessages } from "mml-editor-api-schema/src";

export default class SessionApi {
  private baseUrl: string;
  private projectId: string;
  private accessToken: string;

  private socket?: WebSocket;

  private listeners: Record<string, Array<(payload?: any) => void>>;

  disposed = false;

  public v1: {
    [K in keyof SessionMessages]: (payload?: SessionMessages[K]) => boolean;
  } & {
    on: {
      [K in keyof SessionMessages]: (
        listener: (payload: SessionMessages[K]) => void,
      ) => void;
    };
    off: {
      [K in keyof SessionMessages]: (
        listener: (payload: SessionMessages[K]) => void,
      ) => void;
    };
  };

  constructor(config: {
    baseUrl?: string;
    projectId?: string;
    accessToken?: string;
    onConnected?: () => void;
    onDisconnected?: () => void;
  }) {
    this.baseUrl = config?.baseUrl || "";
    this.projectId = config?.projectId || "";
    this.accessToken = config?.accessToken || "";

    this.listeners = {
      open: [],
      close: [],
      message: [],
      error: [],
    };

    if (config.onConnected) {
      this.addSocketListener("open", config.onConnected);
    }
    if (config.onDisconnected) {
      this.addSocketListener("close", config.onDisconnected);
    }

    this.v1 = AllSessionMessages.reduce((acc, messageId) => {
      return {
        ...acc,
        [messageId]: this.request(messageId),
      };
    }, {}) as any;
    this.v1.on = AllSessionMessages.reduce((acc, messageId) => {
      return {
        ...acc,
        [messageId]: this.onEvent(messageId),
      };
    }, {}) as any;
    this.v1.off = AllSessionMessages.reduce((acc, messageId) => {
      return {
        ...acc,
        [messageId]: this.offEvent(messageId),
      };
    }, {}) as any;

    this.connect(this.accessToken);
  }

  connect = (accessToken?: string) => {
    if (this.disposed) return;

    if (accessToken) {
      this.accessToken = accessToken;
    }
    this.socket = new WebSocket(`${this.baseUrl}/${this.projectId}/editor`);

    this.socket.addEventListener("open", () => {
      // Send the access token as the first message
      this.request("v1_requests_authenticate")({
        accessToken: this.accessToken,
      });
      this.notifyOpen();
    });
    this.socket.addEventListener("close", this.notifyClose);
    this.socket.addEventListener("message", this.notifyMessage);
    this.socket.addEventListener("error", this.notifyError);
  };

  disconnect = () => {
    if (this.socket) {
      this.socket.close();
      this.listeners = {
        open: [],
        close: [],
        message: [],
        error: [],
      };
      this.socket = undefined;
    }
  };

  dispose = () => {
    this.disposed = true;
    this.disconnect();
  };

  private addSocketListener = (
    event: "open" | "close" | "message" | "error",
    listener: () => void,
  ) => {
    this.listeners[event].push(listener);
  };

  private notifyOpen = () => {
    setTimeout(() => {
      for (const listener of this.listeners.open) {
        listener();
      }
    }, 100);
  };

  private notifyClose = (evt: CloseEvent) => {
    console.log("Session socket closed", evt);
    for (const listener of this.listeners.close) {
      listener();
    }
  };

  private notifyMessage = (evt: MessageEvent) => {
    let data: ArrayBuffer | string = evt.data;
    if (data instanceof ArrayBuffer) {
      const decoder = new TextDecoder("utf-8");
      data = decoder.decode(data);
    }

    try {
      const { messageId, ...payload } = JSON.parse(data);

      for (const listener of this.listeners.message) {
        listener({
          messageId,
          ...payload,
        });
      }

      for (const listener of this.listeners[messageId] || []) {
        listener(payload);
      }
    } catch (e) {
      console.error("Error parsing session message", e);
    }
  };

  private notifyError = (evt: Event) => {
    console.error("Session socket error", evt);
    for (const listener of this.listeners.error) {
      listener(evt);
    }
  };

  private request = <M extends keyof SessionMessages>(messageId: M) => {
    return (payload?: SessionMessages[M]): boolean => {
      if (!this.socket) return false;

      try {
        const data = {
          messageId,
          ...payload,
        };

        if (this.socket.readyState === WebSocket.OPEN) {
          this.socket.send(JSON.stringify(data));
          return true;
        } else {
          console.log("Socket not yet open");
          return false;
        }
      } catch (e) {
        console.log(e);
        return false;
      }
    };
  };

  private onEvent = <M extends keyof SessionMessages>(messageId: M) => {
    return (listener: (payload: SessionMessages[M]) => void): void => {
      if (!this.listeners[messageId]) this.listeners[messageId] = [];
      this.listeners[messageId].push(listener);
    };
  };

  private offEvent = <M extends keyof SessionMessages>(messageId: M) => {
    return (listener: (payload: SessionMessages[M]) => void): void => {
      if (!this.listeners[messageId]) return;
      this.listeners[messageId] = this.listeners[messageId].filter(
        (l) => l !== listener,
      );
    };
  };
}
