import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

TimeAgo.addDefaultLocale(en);
const ta = new TimeAgo("en-US");

export const timeAgo = (date: Date, short?: boolean) => {
  const now = Date.now();
  const dateTime = date.getTime();

  const ago = ta.format(
    now - (now - dateTime),
    short ? "mini-minute-now" : "round-minute",
  );
  return `${ago}${short ? " ago" : ""}`;
};
