import styled from "@emotion/styled";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import * as React from "react";
import slugify from "slugify";

import Icon from "~/components/Icon";
import appState from "~/library/appState";
import serverApi from "~/library/serverApi/serverApi";

const CustomText = styled(DialogContentText)({
  a: {
    color: "white",
  },
});

const WarningText = styled(DialogContentText)({
  color: "#EB8D2F",
  fontWeight: 500,
});

const ForkProjectModal = ({
  open,
  onClose,
  projectId,
}: {
  open: boolean;
  onClose: () => void;
  projectId: string;
}) => {
  const forkProject = async () => {
    const res = await serverApi.forkProject({
      parameters: {
        projectId,
      },
      body: {},
    });
    if (res.code === 200) {
      const slug = `${slugify(res.body.name, {
        lower: true,
      })}-${res.body.id}`;

      // Open forked project in new window
      window.open(
        `${window.location.origin}/projects/${slug}`,
        "_blank",
        "noopener",
      );

      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Fork project</DialogTitle>

      <DialogContent>
        <Stack spacing={2}>
          <CustomText fontSize={13}>
            Fork this project to create your own copy of it that you can modify.
          </CustomText>

          {/* Require sign in */}
          {!appState.user && (
            <>
              <WarningText fontSize={14}>
                You need to be signed in to fork this project.
              </WarningText>

              <div>
                <Button
                  variant="contained"
                  href={`/auth/login/google`}
                  target="_blank"
                  startIcon={<Icon icon="google" />}
                >
                  Sign in with Google
                </Button>
              </div>
            </>
          )}

          {/* Fork */}
          {appState.user && (
            <div>
              <Button
                variant="contained"
                onClick={forkProject}
                startIcon={<Icon icon="fork" />}
              >
                Fork project
              </Button>
            </div>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default observer(ForkProjectModal);
