import styled from "@emotion/styled";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import { useLayoutEffect, useState } from "react";

import Icon from "~/components/Icon";
import { ToolTipIcon } from "~/screens/Editor/panels/Inspector/InspectorInput";

const isInputError = (changedValue: string) =>
  !!changedValue && !["true", "false"].includes(changedValue);

const InspectorSegmentedControlContainer = styled.div<{ isError: boolean }>(
  ({ isError }) => ({
    alignItems: "flex-start",
    padding: "20px 10px 6px",
    position: "relative",
    gap: 5,
    flex: 1,
    display: "flex",
    background: "rgb(85, 85, 85)",
    borderRadius: 4,
    boxSizing: "border-box",
    border: `1px solid ${isError ? "red" : "transparent"}`,
  }),
);

const InspectorSegmentedControlTitleLabel = styled.span({
  position: "absolute",
  top: 1,
  left: 10,
  fontSize: 11,
});

const InspectorSegmentedControlLabel = styled.label<{ selected: boolean }>(
  ({ selected }) => ({
    flex: 1,
    display: "block",
    textAlign: "center",
    fontSize: 14,
    backgroundColor: selected ? "rgb(128, 128, 128)" : "transparent",
    cursor: "pointer",
    borderRadius: 4,
    transition: "all 0.1s linear",

    "&:hover": {
      backgroundColor: "rgb(128, 128, 128)",
      transition: "all 0.1s linear",
    },
  }),
);

const InspectorSegmentedControlElement = styled.input({
  display: "none",
});

export default function InspectorSegmentedControlInput({
  attribute,
  value,
  description,
  updateValue,
}: {
  attribute: string;
  value: string;
  updateValue: (value: string | null) => void;
  description?: string;
}) {
  const [isError, setIsError] = useState(false);

  useLayoutEffect(() => {
    const shouldSetError = isInputError(value);
    setIsError(shouldSetError);
  }, []);

  const handleSegmentedControl = (changedValue: "true" | "false" | "") => {
    setIsError(isInputError(changedValue));
    updateValue(changedValue);
  };

  return (
    <InspectorSegmentedControlContainer isError={isError}>
      {description && (
        <Tooltip title={description} placement="top">
          <ToolTipIcon>
            <Icon icon="info" size="14px" color="#808080" />
          </ToolTipIcon>
        </Tooltip>
      )}
      <InspectorSegmentedControlTitleLabel>
        {attribute}
      </InspectorSegmentedControlTitleLabel>
      <InspectorSegmentedControlLabel selected={!isError && value === ""}>
        unset
        <InspectorSegmentedControlElement
          type="radio"
          id={attribute}
          value={value ?? ""}
          checked={value === ""}
          onChange={() => handleSegmentedControl("")}
        />
      </InspectorSegmentedControlLabel>
      <InspectorSegmentedControlLabel selected={value === "true"}>
        true
        <InspectorSegmentedControlElement
          type="radio"
          id={attribute}
          checked={value === "true"}
          value={value ?? ""}
          onChange={() => handleSegmentedControl("true")}
        />
      </InspectorSegmentedControlLabel>
      <InspectorSegmentedControlLabel selected={value === "false"}>
        false
        <InspectorSegmentedControlElement
          type="radio"
          id={attribute}
          checked={value === "false"}
          value={value ?? ""}
          onChange={() => handleSegmentedControl("false")}
        />
      </InspectorSegmentedControlLabel>
    </InspectorSegmentedControlContainer>
  );
}
