import styled from "@emotion/styled";
import { InputAdornment, Stack, TextField, Typography } from "@mui/material";
import { Project as ProjectSchema } from "mml-editor-api-schema";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";

import Icon from "~/components/Icon";
import Navbar from "~/components/Navbar";
import ProjectCollection from "~/components/ProjectCollection";
import Screen from "~/components/Screen";
import ViewSwitch from "~/components/ViewSwitch";
import appState from "~/library/appState";
import serverApi from "~/library/serverApi/serverApi";
import { deleteProjectModal } from "~/modals";

const HeaderContainer = styled.div({
  width: "100%",
  height: "64px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "40px",
});

const Projects = () => {
  const [projects, setProjects] = useState<Array<ProjectSchema>>([]);
  const [listView, setListView] = useState(
    localStorage.getItem("projectsListView") === "true",
  );
  const [searchString, setSearchString] = useState("");

  const getProjects = useCallback(async () => {
    if (!appState.user) {
      setProjects([]);
      return;
    }

    const res = await serverApi.getProjects({
      parameters: {},
      body: null,
    });
    if (res.code === 200) {
      setProjects(res.body.projects);
    }
  }, [appState.user]);

  useEffect(() => {
    getProjects();
  }, [getProjects]);

  useEffect(() => {
    localStorage.setItem("projectsListView", listView ? "true" : "false");
  }, [listView]);

  const deleteProject = (project: ProjectSchema) => {
    deleteProjectModal({
      projectId: project.id,
      onDelete: () => {
        const remainingProjects = projects.filter((p) => p.id !== project.id);
        setProjects(remainingProjects);
      },
    });
  };

  const filteredProjects = projects.filter((project) => {
    if (!searchString.length) return true;

    return project.name.toLowerCase().includes(searchString.toLowerCase());
  });

  // Sort project by most recently modified first
  const sortedProjects = filteredProjects.sort(
    (a: ProjectSchema, b: ProjectSchema) => {
      const aUpdatedAt = new Date(a.updatedAt).getTime();
      const bUpdatedAt = new Date(b.updatedAt).getTime();

      return bUpdatedAt - aUpdatedAt;
    },
  );

  return (
    <Screen navbar={<Navbar />}>
      <HeaderContainer>
        <Typography variant="h1">Projects</Typography>

        <Stack direction="row" spacing="16px">
          <TextField
            placeholder="Search..."
            value={searchString}
            onChange={(evt) => setSearchString(evt.target.value)}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon icon="search" opacity={0.5} />
                </InputAdornment>
              ),
            }}
          />

          <ViewSwitch
            listView={listView}
            onChange={(isListView) => setListView(isListView)}
          />
        </Stack>
      </HeaderContainer>

      {!appState.user && (
        <Typography color="grey.500">
          Sign in to access your projects
        </Typography>
      )}

      <ProjectCollection
        projects={sortedProjects}
        rowLayout={listView}
        onDelete={deleteProject}
      />
    </Screen>
  );
};

export default observer(Projects);
