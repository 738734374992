import {
  EditableNetworkedDOM,
  NetworkedDOM,
} from "@mml-io/networked-dom-document";
import {
  NetworkedDOMWebsocket,
  NetworkedDOMWebsocketStatus,
} from "@mml-io/networked-dom-web";
import { FakeWebsocket } from "@mml-io/networked-dom-web-runner";
import { MMLScene, RemoteDocumentWrapper } from "mml-web";

import { MMLStaticScene } from "./staticScene";

export default class MMLWebClient {
  private windowTarget: Window;
  public readonly element: HTMLDivElement;
  private remoteDocumentHolder: HTMLElement;
  private remoteHolderElement: HTMLElement;

  remoteDocumentWrapper?: RemoteDocumentWrapper;
  mScene: MMLScene | MMLStaticScene;

  private connectedState: {
    document?: NetworkedDOM | EditableNetworkedDOM;
    fakeWebsocket?: FakeWebsocket;
    domWebsocket: NetworkedDOMWebsocket;
  } | null = null;

  constructor(
    windowTarget: Window,
    remoteHolderElement: HTMLElement,
    interactive: boolean,
  ) {
    this.windowTarget = windowTarget;
    this.remoteHolderElement = remoteHolderElement;

    // Create scene
    this.mScene = interactive ? new MMLScene() : new MMLStaticScene();

    // Create element the scene will be rendered in
    this.element = document.createElement("div");
    this.element.style.position = "relative";
    this.element.style.width = "100%";
    this.element.style.height = "100%";

    // Create document wrapper to contain MML source
    this.remoteDocumentWrapper = new RemoteDocumentWrapper(
      "http://localhost",
      this.windowTarget,
      this.mScene,
      (element, event) => {
        this.connectedState?.domWebsocket?.handleEvent(element, event);
      },
    );
    this.remoteDocumentHolder = this.remoteDocumentWrapper.remoteDocument;

    this.remoteHolderElement.append(this.remoteDocumentHolder);
    this.element.append(this.mScene.element);

    this.fitContainer();
  }

  public fitContainer() {
    this.mScene.fitContainer();
  }

  public dispose() {
    this.disconnect();
    this.remoteDocumentHolder.remove();
    this.element.remove();
    this.mScene.dispose();
  }

  public disconnect() {
    if (!this.connectedState) {
      return;
    }

    // Empty current document content
    this.remoteDocumentHolder.innerHTML = "";

    // Disconnect local document
    if (this.connectedState.document) {
      this.connectedState.document.removeWebSocket(
        this.connectedState.fakeWebsocket
          ?.serverSideWebsocket as unknown as WebSocket,
      );
    }

    // Disconnect real socket
    else {
      this.connectedState.domWebsocket.stop();
    }

    this.connectedState = null;
  }

  public getScreenshot() {
    const renderer = this.mScene.getRenderer();
    const camera = this.mScene.getCamera();
    const threeScene = this.mScene.getThreeScene();
    renderer.render(threeScene, camera);
    return renderer.domElement.toDataURL("image/jpeg");
  }

  private connectToWebSocket(url: string, factory: (url: string) => WebSocket) {
    return new NetworkedDOMWebsocket(
      url,
      factory,
      this.remoteDocumentHolder,
      (time: number) => {
        this.remoteDocumentWrapper?.setDocumentTime(time);
      },
      (status: NetworkedDOMWebsocketStatus) => {
        if (status === NetworkedDOMWebsocketStatus.Connected) {
          console.log("Socket connected");
        } else {
          console.log("Socket status", NetworkedDOMWebsocketStatus[status]);
        }
      },
    );
  }

  public connectToSocket(url: string) {
    if (this.connectedState) this.disconnect();

    const domWebsocket = this.connectToWebSocket(
      url,
      NetworkedDOMWebsocket.createWebSocket,
    );

    this.connectedState = {
      domWebsocket,
    };
  }

  public connectToDocument(document?: NetworkedDOM | EditableNetworkedDOM) {
    if (!document) return;
    if (this.connectedState) this.disconnect();

    const fakeWebsocket = new FakeWebsocket("networked-dom-v0.1");

    const domWebsocket = this.connectToWebSocket(
      "ws://localhost",
      () => fakeWebsocket.clientSideWebsocket as unknown as WebSocket,
    );

    document.addWebSocket(
      fakeWebsocket.serverSideWebsocket as unknown as WebSocket,
    );

    this.connectedState = {
      document,
      fakeWebsocket,
      domWebsocket,
    };
  }
}
