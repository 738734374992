import styled from "@emotion/styled";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import { Asset as AssetSchema } from "mml-editor-api-schema";
import { observer } from "mobx-react-lite";
import prettyBytes from "pretty-bytes";
import * as React from "react";
import { useEffect, useRef, useState } from "react";

import appState from "~/library/appState";
import { getDraggedFile, hashFile } from "~/library/fileUtils";
import { serverApiCreateAsset } from "~/library/serverApi/serverApiCreateAsset";

const FileContainer = styled.div<{ dragging: boolean }>(
  ({ theme, dragging }) => ({
    position: "relative",
    width: "100%",
    height: "300px",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    border: "1px solid rgba(255, 255, 255, 0.23)",
    transition: "all 0.1s",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    ...(dragging
      ? {
          borderColor: theme.palette.primary.light,
        }
      : {}),
  }),
);

const MAX_FILE_SIZE = 25e6;

const CreateAssetModal = ({
  open,
  onClose,
  onCreate,
}: {
  open: boolean;
  onClose: () => void;
  onCreate: (asset: AssetSchema) => void;
}) => {
  const dragEventCount = useRef(0);

  const [draggingInsideDropZone, setDraggingInsideDropZone] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [selectedFileValid, setSelectedFileValid] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const createAsset = async () => {
    if (!selectedFile || !appState.project) {
      return;
    }

    const hash = await hashFile(selectedFile);

    // Check that file is not already added
    const projectAssetHashes = appState.project.assets.map((a) => a.id);
    if (projectAssetHashes.includes(hash)) {
      setErrorMessage("Asset already exists");
      return;
    }

    setUploading(true);
    setErrorMessage(undefined);

    const res = await serverApiCreateAsset({
      projectId: appState.project.id,
      hash,
      asset: selectedFile,
    });

    setUploading(false);

    if (res.code === 200) {
      if (appState.project) {
        appState.project.assets.push(res.body);
      }

      onCreate && onCreate(res.body);
      onClose();
    } else {
      setErrorMessage(res.body.message);
    }
  };

  const onFileDrop = (evt: React.DragEvent<HTMLDivElement>) => {
    const file = getDraggedFile(evt);
    if (!file) {
      return;
    }

    setSelectedFile(file);
  };

  const browseForFile = () => {
    const inputElem = document.createElement("input");
    inputElem.setAttribute("type", "file");

    inputElem.addEventListener("change", () => {
      const file = inputElem.files?.[0];

      if (file) {
        setSelectedFile(file);
      }
    });

    inputElem.click();
  };

  // Validate selected file
  useEffect(() => {
    setErrorMessage(undefined);
    setSelectedFileValid(false);

    if (!selectedFile || !appState.project) return;

    // Check if file size is valid
    if (selectedFile.size > MAX_FILE_SIZE) {
      setErrorMessage("File too large");
      return;
    }

    setSelectedFileValid(true);
  }, [selectedFile]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!uploading) {
          onClose();
        }
      }}
      fullWidth
      onDragEnter={(evt) => {
        evt.preventDefault();

        dragEventCount.current++;
        if (dragEventCount.current === 1) {
          setDraggingInsideDropZone(true);
        }
      }}
      onDragLeave={(evt) => {
        evt.preventDefault();

        dragEventCount.current--;
        if (dragEventCount.current === 0) {
          setDraggingInsideDropZone(false);
        }
      }}
      onDragOver={(evt) => evt.preventDefault()}
      onDrop={(evt) => {
        evt.preventDefault();

        dragEventCount.current = 0;
        setDraggingInsideDropZone(false);

        onFileDrop(evt);
      }}
      sx={{
        // Stretch modal to fill screen
        // '.MuiDialog-paper': {
        //   maxWidth: '80%',
        //   width: '80%',
        //   maxHeight: '80%',
        //   height: '80%'
        // },
        ".MuiModal-backdrop": {
          transition: "all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important",
          backgroundColor: draggingInsideDropZone
            ? "rgba(95, 149, 255, 0.2)"
            : undefined,
        },
      }}
    >
      <DialogTitle>Upload new asset</DialogTitle>

      <DialogContent>
        <FileContainer dragging={draggingInsideDropZone}>
          {selectedFile ? (
            <Stack direction="column" alignItems="center" spacing={1}>
              <DialogContentText color="white" fontWeight={700}>
                {selectedFile.name}
              </DialogContentText>
              <DialogContentText
                color={
                  selectedFile.size > MAX_FILE_SIZE ? "#E05E57" : undefined
                }
              >
                {prettyBytes(selectedFile.size)}
              </DialogContentText>

              {errorMessage && (
                <DialogContentText color="#E05E57">
                  {errorMessage}
                </DialogContentText>
              )}

              <Button
                variant="outlined"
                color="secondary"
                onClick={browseForFile}
              >
                Select another file
              </Button>
            </Stack>
          ) : (
            <Stack direction="column" alignItems="center" spacing={2}>
              <DialogContentText>Drag & drop or</DialogContentText>

              <Button
                variant="outlined"
                color="secondary"
                onClick={browseForFile}
              >
                Select a file
              </Button>

              <DialogContentText fontSize={12}>{`Up to ${prettyBytes(
                MAX_FILE_SIZE,
              )}`}</DialogContentText>
            </Stack>
          )}
        </FileContainer>
      </DialogContent>

      <DialogActions sx={{ padding: "0 24px 20px 24px" }}>
        <Button color="secondary" onClick={onClose} disabled={uploading}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={createAsset}
          disabled={!selectedFileValid || uploading || !!errorMessage}
        >
          {uploading ? "Uploading..." : "Upload"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default observer(CreateAssetModal);
