import styled from "@emotion/styled";
import { IconButton, Stack, Typography } from "@mui/material";
import { Asset } from "mml-editor-api-schema";
import prettyBytes from "pretty-bytes";
import { useEffect, useState } from "react";
import * as React from "react";

import Icon from "~/components/Icon";
import { copyToClipboard } from "~/library/clipboard";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Container = styled.div(({ theme }) => ({
  position: "relative",
  width: "100%",
  padding: "8px 4px 8px 12px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: "4px",
  backgroundColor: "#444444",
  border: `1px solid transparent`,
  transition: "all 0.1s",
  cursor: "grab",
  overflow: "hidden",
  "&:hover": {
    backgroundColor: "#555555",
  },
  "&:active": {
    backgroundColor: "#666666",
    // borderColor: theme.palette.primary.light
  },
}));

const AssetName = styled(Typography)({
  fontSize: "13px",
  color: "rgba(255, 255, 255, 0.7)",
  fontWeight: 700,
  lineHeight: 1,
  overflowWrap: "anywhere",
});

const AssetInfo = styled(Typography)({
  fontSize: "12px",
  color: "rgba(255, 255, 255, 0.5)",
  lineHeight: 1,
});

const CopiedLabel = styled(Typography)({
  fontSize: "13px",
  color: "#00E49F",
  lineHeight: 1,
});

const ActionsContainer = styled.div({
  display: "flex",
  flexShrink: "0",
});

const CopiedNotice = styled.div<{ show: boolean }>(({ show }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  transition: "all 0.1s",
  backgroundColor: "#1C2C27",
  opacity: show ? "1" : "0",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: "2",
}));

const Asset = ({
  asset,
  onDelete,
  readOnly,
}: {
  asset: Asset;
  onDelete: (id: string) => void;
  readOnly?: boolean;
}) => {
  const [copiedUrl, setCopiedUrl] = useState(false);

  const getAssetType = () => {
    if (asset.type === "application/octet-stream") {
      if (
        asset.name.includes(".gltf") ||
        asset.name.includes(".glb") ||
        asset.name.includes(".fbx") ||
        asset.name.includes(".obj")
      ) {
        return {
          label: "Model",
          elem: "m-model",
        };
      }
    } else if (asset.type.startsWith("image/")) {
      return {
        label: "Image",
        elem: "m-image",
      };
    } else if (asset.type.startsWith("video/")) {
      return {
        label: "Video",
        elem: "m-video",
      };
    } else if (asset.type.startsWith("audio/")) {
      return {
        label: "Audio",
        elem: "m-audio",
      };
    }

    return {
      label: "Unsupported",
      elem: undefined,
    };
  };

  const assetType = getAssetType();

  const copyUrl = () => {
    copyToClipboard(asset.url || "");

    setCopiedUrl(true);
  };

  const getDropData = (): string => {
    return `<${assetType.elem} src="${asset.url}"></${assetType.elem}>`;
  };

  const createDragGhost = () => {
    const elem = document.createElement("div");
    elem.setAttribute("id", "asset-drag-ghost");

    elem.innerHTML = `
      <span style="font-weight: 700;">
        ${asset.name}
      </span> 
      as 
      <span style="font-family: monospace;">
        &lt;${assetType.elem}&gt;
      </span>`;

    elem.style.backgroundColor = "black";
    elem.style.padding = "8px";
    elem.style.borderRadius = "4px";
    elem.style.fontSize = "13px";
    elem.style.width = "fit-content";

    document.body.appendChild(elem);
    return elem;
  };

  const removeDragGhost = () => {
    document.getElementById("asset-drag-ghost")?.remove();
  };

  useEffect(() => {
    if (!copiedUrl) return;

    const changeBackTimeout = setTimeout(() => {
      setCopiedUrl(false);
    }, 1000);

    return () => {
      clearTimeout(changeBackTimeout);
    };
  }, [copiedUrl]);

  return (
    <Container
      draggable
      onDragStart={(evt) => {
        // Only allow dragging assets that are supported
        if (!assetType.elem || readOnly) {
          evt.preventDefault();
          return;
        }

        const ghost = createDragGhost();
        evt.dataTransfer.setDragImage(ghost, 0, 40);
        evt.dataTransfer.setData("text/plain", getDropData());
      }}
      onDragEnd={() => {
        removeDragGhost();
      }}
    >
      <CopiedNotice show={copiedUrl}>
        <CopiedLabel>Copied URL to clipboard</CopiedLabel>
      </CopiedNotice>

      <Stack direction="column" spacing={1}>
        <AssetName>{asset.name}</AssetName>

        <AssetInfo>
          {assetType.label}, {prettyBytes(asset.size || 0)}
        </AssetInfo>
      </Stack>

      <ActionsContainer>
        <IconButton onClick={copyUrl}>
          <Icon icon="copy" size="16px" />
        </IconButton>

        {!readOnly && (
          <IconButton
            onClick={(evt) => {
              evt.stopPropagation();
              onDelete(asset.id);
            }}
          >
            <Icon icon="delete" size="16px" />
          </IconButton>
        )}
      </ActionsContainer>
    </Container>
  );
};

export default Asset;
