import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import { editor } from "monaco-editor";
import * as React from "react";
import { useEffect, useState } from "react";

import CodeEditor from "~/components/CodeEditor";
import appState from "~/library/appState";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Container = styled.div(({ theme }) => ({
  flex: "1",
  display: "flex",
  backgroundColor: "#222222",
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EditorContainer = styled.div(({ theme }) => ({
  flex: "1",
  display: "flex",
}));

const CodePanel = () => {
  const [monacoInstance, setMonacoInstance] =
    useState<editor.IStandaloneCodeEditor>();

  useEffect(() => {
    if (!appState.project) return;

    // Use first file
    // NOTE there is currently only one file
    const file = Object.values(appState.project.files).pop();
    if (file && monacoInstance) {
      file.attachEditor(monacoInstance);
    }
  }, [appState.project, monacoInstance]);

  return (
    <Container>
      <EditorContainer>
        <CodeEditor
          onMount={(standaloneCodeEditor) => {
            setMonacoInstance(standaloneCodeEditor);

            standaloneCodeEditor.onDidFocusEditorText(() => {
              appState.project?.formatCode();
              appState.project?.setSelectedElements(null);
            });

            standaloneCodeEditor.onDidBlurEditorText(() => {
              appState.project?.formatCode();
            });
          }}
        />
      </EditorContainer>
    </Container>
  );
};

export default {
  id: "code",
  name: "Code",
  Component: observer(CodePanel),
};
