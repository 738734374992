import {
  IPlainTextOperation,
  PlainTextOperation,
  TPlainTextOperation,
} from "@otjs/plaintext";
import {
  DatabaseAdapterEvent,
  ICursor,
  IDatabaseAdapter,
  TCursor,
  TDatabaseAdapterEventArgs,
} from "@otjs/plaintext-editor";
import mitt, { Emitter, Handler } from "mitt";

export type TServerAdapterConstructionOptions = {
  userId: string;
  onOperation: (revision: number, operation: any) => void;
  onCursor: (cursor: any) => void;
};

export type TRevision = {
  o: TPlainTextOperation;
};

export type TOperationData = TRevision & object;

export type TCursorData = {
  userId: string;
  cursor: TCursor;
};

export type TParsedRevision = {
  operation: IPlainTextOperation;
};

export class OTServerAdapter implements IDatabaseAdapter {
  protected _emitter: Emitter<TDatabaseAdapterEventArgs> = mitt();
  protected _disposed = false;

  protected _document: IPlainTextOperation = new PlainTextOperation();
  protected _ready = false;
  protected _revision = 0;

  protected _userId = "";
  protected _userCursor: ICursor | null = null;

  protected _onOperation: TServerAdapterConstructionOptions["onOperation"];
  protected _onCursor: TServerAdapterConstructionOptions["onCursor"];

  constructor({
    userId,
    onOperation,
    onCursor,
  }: TServerAdapterConstructionOptions) {
    this._userId = userId;
    this._onOperation = onOperation;
    this._onCursor = onCursor;
  }

  public init = (content: string, revision: number) => {
    this._revision = revision;
    this._document = PlainTextOperation.fromJSON([content]);

    this._trigger(DatabaseAdapterEvent.InitialRevision, undefined);
    this._trigger(DatabaseAdapterEvent.Operation, this._document);

    this._ready = true;

    setTimeout(() => {
      this._trigger(DatabaseAdapterEvent.Ready, true);
    });
  };

  dispose(): void {
    if (this._disposed) {
      return;
    }

    if (!this._ready) {
      this.on(DatabaseAdapterEvent.Ready, () => {
        this.dispose();
      });
      return;
    }

    this._emitter.all.clear();
    // @ts-expect-error - null not assignable
    this._emitter = null;

    // @ts-expect-error - null not assignable
    this._document = null;
    this._userCursor = null;
    this._disposed = true;
  }

  on<Key extends keyof TDatabaseAdapterEventArgs>(
    event: Key,
    listener: Handler<TDatabaseAdapterEventArgs[Key]>,
  ): void {
    return this._emitter.on(event, listener);
  }

  off<Key extends keyof TDatabaseAdapterEventArgs>(
    event: Key,
    listener?: Handler<TDatabaseAdapterEventArgs[Key]>,
  ): void {
    return this._emitter.off(event, listener);
  }

  protected _trigger<Key extends keyof TDatabaseAdapterEventArgs>(
    event: Key,
    payload: TDatabaseAdapterEventArgs[Key],
  ): void {
    return this._emitter.emit(event, payload);
  }

  isHistoryEmpty(): boolean {
    return this._revision === 0;
  }

  getDocument(): IPlainTextOperation | null {
    return this._document;
  }

  setUserId(): void {
    // no-op
  }

  setUserColor(): void {
    // no-op
  }

  setUserName(): void {
    // no-op
  }

  isCurrentUser(clientId: string): boolean {
    return String(this._userId) === String(clientId);
  }

  sendOperation(operation: IPlainTextOperation): Promise<boolean> {
    if (!this._ready) {
      this.on(DatabaseAdapterEvent.Ready, () => {
        this._trigger(DatabaseAdapterEvent.Retry, undefined);
      });
      return Promise.resolve(false);
    }

    if (!this._document.canMergeWith(operation)) {
      const error = new Error(
        "sendOperation() called with an invalid operation.",
      );
      this._trigger(DatabaseAdapterEvent.Error, {
        err: error,
        operation: operation.toString(),
        document: this._document.toString(),
      });
      throw error;
    }

    this._document = this._document.compose(operation);
    this._revision++;

    this._onOperation(this._revision, operation.toJSON());

    return Promise.resolve(true);
  }

  sendCursor(cursor: ICursor | null): Promise<void> {
    if (cursor) {
      this._onCursor([cursor.toJSON().position, cursor.toJSON().selectionEnd]);
    } else {
      this._onCursor(null);
    }
    return Promise.resolve();
  }

  public fileChangeAccepted = () => {
    this._trigger(DatabaseAdapterEvent.Acknowledge, undefined);
  };

  public receiveChange = (clientId: string, number: number, operation: any) => {
    this._document = this._document.compose(
      PlainTextOperation.fromJSON(operation),
    );

    this._revision = number;

    this._trigger(
      DatabaseAdapterEvent.Operation,
      PlainTextOperation.fromJSON(operation),
    );
  };

  public cursorChanged = (
    clientId: string,
    userColor?: string,
    userName?: string,
    cursor?: any,
  ) => {
    if (this._disposed) return;

    if (cursor) {
      this._trigger(DatabaseAdapterEvent.CursorChange, {
        clientId,
        cursor: {
          position: cursor[0],
          selectionEnd: cursor[1],
        },
        userColor,
        userName,
      });
    } else {
      this._trigger(DatabaseAdapterEvent.CursorChange, {
        clientId,
        cursor: null,
      });
    }
  };
}
