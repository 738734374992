import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useRef } from "react";

import Icon from "~/components/Icon";
import appState from "~/library/appState";
import { LocalAvatarServer } from "~/library/localAvatarClient/LocalAvatarServer";
import AvatarClient from "~/screens/Editor/panels/Play/AvatarClient";
import FloatingClient from "~/screens/Editor/panels/Play/FloatingClient";
import { CLIENT_TYPES } from "~/types/playClients";

const CloseButton = styled.button({
  position: "absolute",
  left: "5px",
  top: "5px",
  zIndex: 10,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 0,
  margin: 0,
  width: 20,
  height: 20,
  backgroundColor: "rgb(48, 48, 48)",
  border: "none",
  borderRadius: "4px",
  color: "white",
  fontSize: "13px",
  opacity: 0.3,
  "&:hover": {
    opacity: 1,
  },
  aspectRatio: "1",
  cursor: "pointer",
});

const Container = styled.div({
  height: "100%",
  width: "100%",
  position: "relative",
});

function ClientView() {
  const server = useRef(new LocalAvatarServer());

  if (!appState.project) {
    return null;
  }

  const { playClients } = appState.project;
  const columns = playClients.length < 3 ? 1 : 2; // We want one or two clients per row
  const rows = Math.min(playClients.length, 2); // Calculate the number of rows needed

  const gridStyle = {
    display: "grid",
    gridTemplateColumns: `repeat(${columns}, 1fr)`, // Create as many columns as needed
    gridTemplateRows: `repeat(${rows}, 1fr)`, // Create as many rows as needed
    height: "100%",
    width: "100%",
  };

  return (
    <Container>
      <div style={gridStyle}>
        {appState.project?.playClients.map(({ type, id }, index) => {
          const children = (
            <CloseButton
              onClick={() => appState.project?.removeClient?.(id as number)}
            >
              <Icon icon="close" size="15px" />
            </CloseButton>
          );

          const avatarPositionForIndex = {
            x: [0, 2].includes(index) ? -1 : 1,
            y: 0.5,
            z: [0, 1].includes(index) ? 5 : 7,
          };

          return type === CLIENT_TYPES.FLOATING ? (
            <FloatingClient key={id}>{children}</FloatingClient>
          ) : (
            <AvatarClient
              server={server.current}
              position={avatarPositionForIndex}
              rotation={{ x: 0, y: Math.PI, z: 0 }}
              clientId={id as number}
              key={id}
            >
              {children}
            </AvatarClient>
          );
        })}
      </div>
    </Container>
  );
}

export default observer(ClientView);
