import styled from "@emotion/styled";
import * as React from "react";
import { useLayoutEffect, useState } from "react";

const CookieBannerContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 170px;
  background-color: var(--home-background-green);
  padding: 10px 20px 20px;
  color: white;
  flex-direction: column;
  font-size: 0.75rem;
  font-weight: 500;
  z-index: 1000;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  border-radius: 4px;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    flex-direction: row;
    padding: 20px;
    height: 100px;
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    height: 80px;
  }

  a {
    color: white;
    text-decoration: underline;
    font-weight: 700;
  }
`;

const CookieBannerText = styled.p`
  margin: 0;
`;

const CookieBannerButtonsContainer = styled.div`
  display: inline-flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
`;

const CookieBannerButton = styled.button`
  background-color: var(--home-text-green);
  color: var(--home-text-gray);
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 0.75rem;
  font-weight: 700;
  cursor: pointer;
  width: 84px;
`;

const CookieBannerReject = styled(CookieBannerButton)`
  background-color: rgba(255, 255, 255, 0.467);
`;

export default function CookieBanner() {
  const [hasHandledCookies, setHasHandledCookies] = useState(false);

  const rejectCookies = () => {
    document.cookie = "cookiesAccepted=false;path=/;samesite=strict";
    setHasHandledCookies(true);
  };

  const acceptCookies = () => {
    document.cookie = "cookiesAccepted=true;path=/;samesite=strict";
    setHasHandledCookies(true);
  };

  useLayoutEffect(() => {
    const cookies = document.cookie.split("; ");
    const cookieValue = cookies.find((row) =>
      row.startsWith("cookiesAccepted="),
    );
    if (cookieValue) {
      setHasHandledCookies(true);
    }
  }, []);

  if (hasHandledCookies) {
    return null;
  }

  return (
    <CookieBannerContainer>
      <CookieBannerText>
        We use essential cookies to make our site work. With your consent, we
        may also use non-essential cookies to improve user experience and
        analyse website traffic. By clicking ‘Accept’, you agree to our
        website’s cookie use as described in our{" "}
        <a
          href="https://www.improbable.io/cookie-policy"
          target="_blank"
          rel="noreferrer"
        >
          Cookie Policy
        </a>
        .
      </CookieBannerText>
      <CookieBannerButtonsContainer>
        <CookieBannerButton onClick={acceptCookies}>Accept</CookieBannerButton>
        <CookieBannerReject onClick={rejectCookies}>Reject</CookieBannerReject>
      </CookieBannerButtonsContainer>
    </CookieBannerContainer>
  );
}
