import { Asset as AssetSchema } from "mml-editor-api-schema";

import { getAccessToken } from "~/library/serverApi/serverApi";

const mmlEditorAPIUrl = window.serverConfig.API_SERVER_URL;

type ServerAPICreateAssetParams = {
  projectId: string;
  hash: string;
  asset: File;
};

export async function serverApiCreateAsset(
  params: ServerAPICreateAssetParams,
): Promise<
  { code: 200; body: AssetSchema } | { code: 400; body: { message?: string } }
> {
  const formData = new FormData();
  formData.append("hash", params.hash);
  formData.append("asset", params.asset);

  const accessToken = await getAccessToken();
  if (!accessToken) {
    throw new Error("No access token");
  }
  const request = new Request(
    `${mmlEditorAPIUrl}/v1/projects/${encodeURIComponent(params.projectId)}/assets`,
    {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );
  const fetchResponse = await fetch(request);
  const responseBodyText = await fetchResponse.text();
  let responseBodyJson;
  if (responseBodyText) {
    responseBodyJson = JSON.parse(responseBodyText);
  }
  return { code: fetchResponse.status as 200 | 400, body: responseBodyJson };
}
