import styled from "@emotion/styled";
import { Box, Button, ButtonBase, Card, Typography } from "@mui/material";
import { Project as ProjectSchema } from "mml-editor-api-schema";
import * as React from "react";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";

import { timeAgo } from "~/library/dateUtils";

import Icon from "./Icon";
import Menu from "./Menu";

const Container = styled.div<{ rowLayout?: boolean }>(({ rowLayout }) => ({
  width: "100%",
  height: rowLayout ? "64px" : "250px",
  display: "flex",
  flexDirection: rowLayout ? "row" : "column",
}));

const ThumbnailContainer = styled(Link)<{ rowLayout?: boolean }>(
  ({ rowLayout }) => ({
    minWidth: "110px",
    maxWidth: rowLayout ? "110px" : undefined,
    flex: "1",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
);

const Thumbnail = styled.div<{ url: string }>(({ url }) => ({
  width: "100%",
  height: "100%",
  backgroundSize: "cover",
  backgroundImage: `url('${url}')`,
}));

const ContentContainer = styled.div<{ rowLayout?: boolean }>(
  ({ rowLayout }) => ({
    flex: rowLayout ? "1" : undefined,
    height: "64px",
    display: "flex",
    flexShrink: "0",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#2C2C2C",
  }),
);

const ActionContainer = styled.div({
  display: "flex",
  height: "100%",
  alignItems: "center",
});

const InfoContainer = styled(Link)<{ rowLayout?: boolean }>(
  ({ rowLayout }) => ({
    flex: "1",
    display: "flex",
    flexDirection: rowLayout ? "row" : "column",
    alignItems: rowLayout ? "center" : "flex-start",
    justifyContent: "space-between",
    paddingLeft: "16px",
    paddingRight: "24px",
    minWidth: "0",
    textDecoration: "none",
    color: "inherit",
  }),
);

const TextContainer = styled.div({
  width: "100%",
});

const NameText = styled(Typography)({
  textAlign: "left",
  fontSize: "16px",
  fontWeight: "500",
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
});

const DetailsText = styled(Typography)({
  fontSize: "12px",
  opacity: 0.5,
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
});

const MenuButton = styled.div<{ menuOpen?: boolean }>(({ menuOpen }) => ({
  display: "flex",
  alignItems: "center",
  height: "100%",
  paddingLeft: "8px",
  paddingRight: "8px",
  backgroundColor: menuOpen ? "rgba(255, 255, 255, 0.1)" : "transparent",
  transition: "background-color 0.1s",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
}));

const PrimaryButton = styled(Button)(() => ({
  height: "30px",
})) as typeof Button;

const ProjectCard = ({
  project,
  forkable,
  deletable = true,
  rowLayout,
  href,
  onDelete,
  onFork,
}: {
  project: ProjectSchema;
  forkable?: boolean;
  deletable?: boolean;
  rowLayout?: boolean;
  href: string;
  onDelete?: () => void;
  onFork?: () => void;
}) => {
  const menuButtonRef = useRef<any>(null);
  const [menuAnchorElem, setMenuAnchorElem] = useState<HTMLElement>();

  const menuItems = [];
  if (!forkable && deletable) {
    menuItems.push({
      title: "Delete",
      icon: "delete",
      onClick: onDelete,
    });
  }

  return (
    <Card sx={{ width: "100%", height: "100%" }}>
      <ButtonBase component="div" sx={{ width: "100%", height: "100%" }}>
        <Container rowLayout={rowLayout}>
          <ThumbnailContainer to={href} rowLayout={rowLayout}>
            {project.thumbnailUrl ? (
              <Thumbnail url={project.thumbnailUrl} />
            ) : (
              <Icon icon="code" size="48px" opacity={0.3} />
            )}
          </ThumbnailContainer>

          <ContentContainer rowLayout={rowLayout}>
            <InfoContainer to={href} rowLayout={rowLayout}>
              <TextContainer>
                <NameText>{project.name}</NameText>
              </TextContainer>
              <TextContainer>
                <DetailsText textAlign={rowLayout ? "right" : "left"}>
                  {`Modified ${timeAgo(new Date(project.updatedAt))}`}
                </DetailsText>
              </TextContainer>
            </InfoContainer>

            <ActionContainer>
              {forkable ? (
                <>
                  <PrimaryButton
                    variant="contained"
                    startIcon={<Icon icon="fork" />}
                    onMouseDown={(evt) => {
                      evt.stopPropagation();
                    }}
                    onClick={(evt) => {
                      evt.stopPropagation();
                      onFork && onFork();
                    }}
                  >
                    Fork
                  </PrimaryButton>
                  <Box width="16px" />
                </>
              ) : (
                <>
                  {!!menuItems.length && (
                    <MenuButton
                      ref={menuButtonRef}
                      menuOpen={Boolean(menuAnchorElem)}
                      onMouseDown={(evt) => {
                        evt.stopPropagation();
                      }}
                      onClick={(evt) => {
                        evt.stopPropagation();
                        setMenuAnchorElem(menuButtonRef.current);
                      }}
                    >
                      <Icon
                        icon="menu"
                        size="20px"
                        opacity={menuAnchorElem ? 1 : 0.5}
                      />
                    </MenuButton>
                  )}
                </>
              )}
            </ActionContainer>
          </ContentContainer>
        </Container>
      </ButtonBase>

      <Menu
        anchorElement={menuAnchorElem}
        alignment="right"
        items={menuItems}
        onClose={() => setMenuAnchorElem(undefined)}
        onMenuItemClick={(item) => {
          setMenuAnchorElem(undefined);
          item.onClick && item.onClick();
        }}
      />
    </Card>
  );
};

export default ProjectCard;
