import styled from "@emotion/styled";
import { IconButton, Tab, Tabs, TabsProps } from "@mui/material";
import { useRef, useState } from "react";
import * as React from "react";

import Icon from "~/components/Icon";
import Menu from "~/components/Menu";

import type { Panel } from "../panels";

const GroupContainer = styled.div<{
  width?: string;
  height?: string;
  collapse?: boolean;
  collapseWidth?: boolean;
}>(({ width, height, collapse, collapseWidth }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "flex-end",
  flexDirection: "row",
  backgroundColor: "#303030",
  borderBottomWidth: "1px",
  borderBottomStyle: "solid",
  borderBottomColor: "#444444",

  ...(width
    ? {
        width,
      }
    : {}),

  ...(height
    ? {
        height,
      }
    : {}),

  ...(collapse && collapseWidth
    ? {
        width: "32px",
      }
    : {}),

  ...(collapse && !collapseWidth
    ? {
        height: "30px",
      }
    : {}),
}));

const PanelSlot = styled.div({
  flex: "1",
  display: "flex",
  flexDirection: "row",
});

const PanelDivider = styled.div({
  width: "1px",
  height: "100%",
  display: "flex",
  backgroundColor: "#444444",
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PanelContainer = styled.div(({ theme }) => ({
  flex: "1",
  display: "flex",
  flexDirection: "column",
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PanelContentContainer = styled.div(({ theme }) => ({
  flex: "1",
  position: "relative",
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PanelContentAbsoluteWrapper = styled.div(({ theme }) => ({
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  position: "absolute",
  overflow: "auto",
  display: "flex",
}));

const PanelTabsContainer = styled.div(({ theme }) => ({
  position: "relative",
  width: "100%",
  height: "30px",
  display: "flex",
  padding: theme.spacing(0, 2),
  backgroundColor: "#303030",
  borderBottomWidth: "1px",
  borderBottomStyle: "solid",
  borderBottomColor: "#444444",
}));

const StyledTabs = styled((props: { hideIndicator?: boolean } & TabsProps) => {
  const { ...rest } = props;
  return <Tabs {...rest} />;
})(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ theme, hideIndicator }) => ({
    minHeight: 0,
    "& .MuiTabs-indicator": {
      backgroundColor: hideIndicator ? "transparent" : "white",
      transition: "none",
    },
  }),
);

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "uppercase",
  minWidth: 0,
  minHeight: 0,
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: "12px",
  marginRight: theme.spacing(3),
  padding: 0,
  lineHeight: "30px",
  color: "rgba(255, 255, 255, 0.5)",
  "&:hover": {
    color: "rgba(255, 255, 255, 0.75)",
  },
  "&.Mui-selected": {
    color: "white",
  },
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MenuButtonContainer = styled.div(({ theme }) => ({
  position: "absolute",
  right: 0,
  height: "100%",
  display: "flex",
  alignItems: "center",
}));

const MenuButton = ({
  onClick,
  active,
}: {
  onClick: (buttonElem: HTMLElement) => void;
  active?: boolean;
}) => {
  const buttonRef = useRef<any>(null);

  return (
    <IconButton
      ref={buttonRef}
      disableRipple
      sx={{
        paddingTop: 0,
        paddingBottom: 0,
        height: "100%",
        backgroundColor: active ? "rgba(255, 255, 255, 0.15)" : "transparent",
        borderRadius: "0",
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.1)",
        },
      }}
      onClick={() => onClick(buttonRef.current)}
    >
      <Icon
        icon="menu"
        size="16px"
        color={`rgba(255, 255, 255, ${active ? "0.75" : "0.5"})`}
      />
    </IconButton>
  );
};

const PanelGroup = ({
  panels,
  splitView,
  allowSplitView,
  collapse,
  collapseWidth,
  allowCollapse,
  width,
  height,
  selectedTab,
  onCollapse,
  onExpand,
  onSplitView,
  onTabView,
  onTabChange,
}: {
  panels: Panel[];
  splitView?: boolean;
  allowSplitView?: boolean;
  collapse?: boolean;
  collapseWidth?: boolean;
  allowCollapse?: boolean;
  width?: string;
  height?: string;
  selectedTab?: number;
  onCollapse?: () => void;
  onExpand?: () => void;
  onSplitView?: () => void;
  onTabView?: () => void;
  onTabChange?: (selectedTab: number) => void;
}) => {
  const [menuAnchorElem, setMenuAnchorElem] = useState<HTMLElement>();

  const openMenu = (menuElem: HTMLElement) => {
    setMenuAnchorElem(menuElem);
  };

  const closeMenu = () => {
    setMenuAnchorElem(undefined);
  };

  const menuItems = [];
  if (allowCollapse && !collapse)
    menuItems.push({
      title: "Collapse",
      icon: "collapse",
      onClick: onCollapse,
    });
  if (allowCollapse && collapse)
    menuItems.push({
      title: "Expand",
      icon: "expand",
      onClick: onExpand,
    });
  if (allowCollapse && allowSplitView)
    menuItems.push({
      title: "-",
    });
  if (allowSplitView)
    menuItems.push(
      {
        title: "Split view",
        icon: splitView ? "check" : "",
        onClick: onSplitView,
      },
      {
        title: "Tab view",
        icon: !splitView ? "check" : "",
        onClick: onTabView,
      },
    );

  return (
    <GroupContainer
      width={width}
      height={height}
      collapse={allowCollapse && collapse}
      collapseWidth={collapseWidth}
    >
      {panels.map((panel, index) => {
        const PanelComponent = panel.Component;

        const panelVisible = splitView || (!splitView && index === selectedTab);
        const MenuComponent = panel.MenuComponent;

        return (
          <PanelSlot
            key={panel.id}
            style={{
              display: !panelVisible ? "none" : undefined,
            }}
          >
            <PanelContainer>
              {/* Tabs */}
              <PanelTabsContainer>
                {splitView ? (
                  // Single tab for current panel
                  <StyledTabs value={0} hideIndicator={true}>
                    <StyledTab disableRipple disabled label={panel.name} />
                  </StyledTabs>
                ) : (
                  // Show tabs for all panels
                  <StyledTabs
                    value={selectedTab || 0}
                    onChange={(evt, newTab) =>
                      onTabChange && onTabChange(newTab)
                    }
                  >
                    {panels.map((tabPanel) => (
                      <StyledTab
                        key={tabPanel.id}
                        disableRipple
                        label={tabPanel.name}
                      />
                    ))}
                  </StyledTabs>
                )}

                {/* Menu button */}
                {((splitView && index === panels.length - 1) || !splitView) && (
                  <MenuButtonContainer>
                    {MenuComponent && <MenuComponent />}
                    <MenuButton
                      onClick={openMenu}
                      active={Boolean(menuAnchorElem)}
                    />
                  </MenuButtonContainer>
                )}
              </PanelTabsContainer>

              {/* Panel content */}
              <PanelContentContainer
                style={{
                  display: collapse ? "none" : undefined,
                }}
              >
                <PanelContentAbsoluteWrapper>
                  <PanelComponent visible={panelVisible && !collapse} />
                </PanelContentAbsoluteWrapper>
              </PanelContentContainer>
            </PanelContainer>

            {splitView && index !== panels.length - 1 && <PanelDivider />}
          </PanelSlot>
        );
      })}

      <Menu
        anchorElement={menuAnchorElem}
        items={menuItems}
        onClose={closeMenu}
        onMenuItemClick={(item) => {
          item.onClick && item.onClick();
          closeMenu();
        }}
      />
    </GroupContainer>
  );
};

export default PanelGroup;
