[
  {
    "id": "1Bmy1d",
    "name": "Clickable Cube",
    "description": "",
    "createdBy": "c1e4a55d-b910-49ff-bf46-917fe785df6e",
    "createdByInfo": {
      "name": "MML Examples"
    },
    "createdAt": "2023-09-28T13:48:13.733Z",
    "updatedAt": "2023-10-02T12:56:53.848Z",
    "thumbnailId": "1Bmy1d-d0407be98896b87872d4f2628d71953384760f3b0f09e9a68c62e4f43218bcc3",
    "thumbnailUrl": "https://mmlstorage.com/1Bmy1d-d0407be98896b87872d4f2628d71953384760f3b0f09e9a68c62e4f43218bcc3",
    "isPublic": true,
    "isPublished": false,
    "tags": []
  },
  {
    "id": "22aUHj",
    "name": "Video Example",
    "description": "",
    "createdBy": "c1e4a55d-b910-49ff-bf46-917fe785df6e",
    "createdByInfo": {
      "name": "MML Examples"
    },
    "createdAt": "2023-09-28T10:04:09.045Z",
    "updatedAt": "2023-10-05T15:42:30.252Z",
    "thumbnailId": "22aUHj-b0321107e06ca7b48d5e93b67abe6f733127f700bef7513267d1266135bed6ec",
    "thumbnailUrl": "https://mmlstorage.com/22aUHj-b0321107e06ca7b48d5e93b67abe6f733127f700bef7513267d1266135bed6ec",
    "isPublic": true,
    "isPublished": false,
    "tags": []
  },
  {
    "id": "qEdK7A",
    "name": "Basic MML Example",
    "description": "",
    "createdBy": "c1e4a55d-b910-49ff-bf46-917fe785df6e",
    "createdByInfo": {
      "name": "MML Examples"
    },
    "createdAt": "2023-09-05T18:27:25.559Z",
    "updatedAt": "2023-10-02T13:00:56.763Z",
    "thumbnailId": "qEdK7A-a8581e2215001c796ddf3267a0a117bbf71fb507e7b75c011754497b4ad52704",
    "thumbnailUrl": "https://mmlstorage.com/qEdK7A-a8581e2215001c796ddf3267a0a117bbf71fb507e7b75c011754497b4ad52704",
    "isPublic": true,
    "isPublished": false
  },
  {
    "id": "zolv8R",
    "name": "Bouncing Dice",
    "description": "",
    "createdBy": "c1e4a55d-b910-49ff-bf46-917fe785df6e",
    "createdByInfo": {
      "name": "MML Examples"
    },
    "createdAt": "2023-10-03T10:17:16.176Z",
    "updatedAt": "2023-10-03T10:18:09.366Z",
    "thumbnailId": "zolv8R-3a67b6b1b32a01cfd9d9ac7ba4a99b86d9d86124eb893c17fbefad1ca2e356a4",
    "thumbnailUrl": "https://mmlstorage.com/zolv8R-3a67b6b1b32a01cfd9d9ac7ba4a99b86d9d86124eb893c17fbefad1ca2e356a4",
    "isPublic": true,
    "isPublished": false,
    "tags": []
  }
]
