const rootSchema = {
  openapi: "3.0.0",
  info: {
    title: "Session API",
    version: "1.0.0",
    description:
      "API powering the live collaboration elements of the MML Editor",
  },
  paths: {},
  components: {
    schemas: {
      v1_requests_applyFileChange: {
        type: "object",
        properties: {
          fileId: {
            type: "string",
          },
          number: {
            type: "number",
          },
          operation: {
            type: "array",
            items: {},
          },
          selection: {},
        },
        required: ["fileId", "operation"],
      },
      v1_requests_flushFileChanges: {
        type: "object",
        properties: {},
      },
      v1_requests_setClientFileSelection: {
        type: "object",
        properties: {
          fileId: {
            type: "string",
          },
          selection: {},
        },
        required: ["fileId", "selection"],
      },
      v1_requests_authenticate: {
        additionalProperties: false,
        type: "object",
        properties: {
          accessToken: {
            type: "string",
          },
        },
        required: ["accessToken"],
      },
      v1_requests_broadcastRunnerMessage: {
        type: "object",
        properties: {
          message: {},
        },
        required: ["message"],
      },
      v1_events_invalidRequest: {
        type: "object",
        properties: {
          error: {
            type: "string",
          },
        },
        required: ["error"],
      },
      v1_events_invalidRequestPayload: {
        type: "object",
        properties: {
          error: {
            type: "string",
          },
          validationErrors: {
            type: "object",
            additionalProperties: {},
          },
        },
        required: ["error", "validationErrors"],
      },
      v1_events_clientConnected: {
        $ref: "#/components/schemas/v1_connectedClientInfo",
      },
      v1_events_clientDisconnected: {
        type: "object",
        properties: {
          id: {
            type: "string",
          },
        },
        required: ["id"],
      },
      v1_events_fileChangeAccepted: {
        type: "object",
        properties: {
          fileId: {
            type: "string",
          },
          number: {
            type: "number",
          },
        },
        required: ["fileId", "number"],
      },
      v1_events_fileChange: {
        type: "object",
        properties: {
          fileId: {
            type: "string",
          },
          clientId: {
            type: "string",
          },
          number: {
            type: "number",
          },
          operation: {
            type: "array",
            items: {},
          },
          selection: {},
        },
        required: ["fileId", "clientId", "number", "operation"],
      },
      v1_events_fileChanges: {
        type: "object",
        properties: {
          fileId: {
            type: "string",
          },
          from: {
            type: "number",
          },
          operations: {
            type: "array",
            items: {
              type: "array",
              items: {},
            },
          },
        },
        required: ["fileId", "from", "operations"],
      },
      v1_events_filesFlushed: {
        type: "object",
        properties: {},
      },
      v1_events_clientFileSelection: {
        type: "object",
        properties: {
          fileId: {
            type: "string",
          },
          clientId: {
            type: "string",
          },
          selection: {},
        },
        required: ["fileId", "clientId", "selection"],
      },
      v1_events_file: {
        type: "object",
        properties: {
          fileId: {
            type: "string",
          },
          content: {
            type: "string",
          },
          lastChangeNumber: {
            type: "number",
          },
          reset: {
            type: "boolean",
          },
          clientSelections: {
            type: "object",
            properties: {
              additionalProperties: {
                type: "object",
              },
            },
          },
        },
        required: [
          "fileId",
          "content",
          "lastChangeNumber",
          "reset",
          "clientSelections",
        ],
      },
      v1_events_clients: {
        type: "object",
        properties: {
          clientId: {
            type: "string",
          },
          clients: {
            type: "object",
            additionalProperties: {
              $ref: "#/components/schemas/v1_connectedClientInfo",
            },
          },
        },
        required: ["clientId", "clients"],
      },
      v1_events_executionLogMessage: {
        type: "object",
        properties: {
          level: {
            type: "string",
          },
          content: {
            type: "array",
            items: {},
          },
        },
        required: ["level", "content"],
      },
      v1_events_broadcastRunnerMessage: {
        type: "object",
        properties: {
          message: {},
        },
        required: ["message"],
      },
      v1_events_becomeBroadcastRunner: {
        type: "object",
        properties: {},
      },
      v1_connectedClientInfo: {
        type: "object",
        properties: {
          id: {
            type: "string",
          },
          color: {
            type: "string",
          },
          selection: {},
          user: {
            $ref: "#/components/schemas/v1_user",
          },
        },
        required: ["id", "color", "user"],
      },
      v1_user: {
        $id: "v1_user",
        title: "User",
        description: "Represents a user.",
        type: "object",
        properties: {
          id: {
            type: "string",
          },
          name: {
            type: "string",
          },
          picture: {
            type: "string",
          },
        },
        required: ["id"],
      },
    },
  },
} as const;

type SchemaType = Readonly<typeof rootSchema>;
export const mmlEditorSessionSchema: SchemaType = rootSchema;
