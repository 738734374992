import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import * as React from "react";

import serverApi from "~/library/serverApi/serverApi";

const DeleteProjectModal = ({
  open,
  onClose,
  projectId,
  onDelete,
}: {
  open: boolean;
  onClose: () => void;
  projectId: string;
  onDelete: () => void;
}) => {
  const deleteProject = async () => {
    const res = await serverApi.deleteProject({
      parameters: {
        projectId,
      },
      body: null,
    });
    if (res.code === 200) {
      onDelete();
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Delete project</DialogTitle>

      <DialogContent>
        <DialogContentText>
          The project will become inaccessible and all of its data will be
          removed permanently. Are you sure you wish to continue?
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ padding: "0 24px 20px 24px" }}>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={deleteProject}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteProjectModal;
