import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { observer } from "mobx-react-lite";
import * as React from "react";

import Icon from "~/components/Icon";
import appState from "~/library/appState";
import { createAssetModal, deleteAssetModal } from "~/modals";

import Asset from "./Asset";
import PanelButton from "../../components/PanelButton";
import PanelHeading from "../../components/PanelHeading";
import PanelText from "../../components/PanelText";

const Container = styled.div(({ theme }) => ({
  flex: "1",
  padding: theme.spacing(2),
  flexDirection: "column",
  overflowX: "hidden",
}));

const AssetsPanel = () => {
  const createAsset = () => {
    if (!appState.project) return;

    createAssetModal({});
  };

  const deleteAsset = (assetId: string) => {
    if (!appState.project) return;

    deleteAssetModal({
      projectId: appState.project.id,
      assetId,
      onDelete: () => {
        if (appState.project) {
          appState.project.assets = appState.project.assets.filter(
            (asset) => asset.id !== assetId,
          );
        }
      },
    });
  };

  return (
    <Container>
      {/* Project assets */}
      <Stack direction="column" spacing={2} alignItems="flex-start">
        <PanelHeading
          rightContent={
            appState.project?.clientId && (
              <PanelButton
                variant="text"
                color="secondary"
                sx={{ marginRight: "-8px" }}
                startIcon={<Icon icon="upload" size="16px" />}
                onClick={createAsset}
              >
                Upload
              </PanelButton>
            )
          }
        >
          Project assets
        </PanelHeading>

        {appState.project?.assets.length ? (
          appState.project?.assets.map((asset) => (
            <Asset
              key={asset.id}
              asset={asset}
              onDelete={deleteAsset}
              readOnly={!appState.project?.clientId}
            />
          ))
        ) : (
          <PanelText>No assets</PanelText>
        )}
      </Stack>
    </Container>
  );
};

export default {
  id: "assets",
  name: "Assets",
  Component: observer(AssetsPanel),
};
