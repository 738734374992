import { css, Global } from "@emotion/react";
import styled from "@emotion/styled";
import { CssBaseline } from "@mui/material";
import { useRef } from "react";
import * as React from "react";

import CookieBanner from "~/components/CookieBanner";
import FeedbackBubble from "~/components/FeedbackBubble";

const Container = styled.div(({ theme }) => ({
  width: "100%",
  height: "100vh",
  backgroundColor: theme.palette.background.default,
  display: "flex",
  flexDirection: "column",
}));

const NavbarContainer = styled.div(() => ({
  width: "100%",
  flexShrink: 0,
}));

const ContentContainer = styled.div(
  () =>
    ({
      flex: 1,
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      overflowY: "auto",
    }) as any,
);

const Content = styled.div<{
  center?: boolean;
  fullWidth?: boolean;
  noPadding?: boolean;
}>(({ theme, center, fullWidth, noPadding }) => ({
  flex: 1,
  width: "100%",
  display: "flex",
  flexDirection: "column",
  maxWidth: theme.breakpoints.values.xl,
  padding: theme.spacing(4, 5),

  ...(center
    ? {
        alignItems: "center",
        justifyContent: "center",
      }
    : {}),

  ...(fullWidth
    ? {
        maxWidth: "100%",
      }
    : {}),

  ...(noPadding
    ? {
        padding: 0,
      }
    : {}),
}));

const Screen = ({
  navbar,
  footer,
  center,
  fullWidth,
  noPadding,
  noMinWidth,
  children,
  onDragEnter,
  onDragLeave,
  onDrop,
}: {
  navbar?: JSX.Element;
  footer?: JSX.Element;
  center?: boolean;
  fullWidth?: boolean;
  noPadding?: boolean;
  noMinWidth?: boolean;
  children?: React.ReactNode;
  onDragEnter?: (evt: React.DragEvent<HTMLElement>) => void;
  onDragLeave?: (evt: React.DragEvent<HTMLElement>) => void;
  onDrop?: (evt: React.DragEvent<HTMLElement>) => void;
}) => {
  const dragEventCount = useRef(0);

  return (
    <Container
      onDragEnter={(evt) => {
        if (!evt.dataTransfer.types.includes("Files")) return;
        evt.preventDefault();

        dragEventCount.current++;
        if (dragEventCount.current === 1) {
          onDragEnter && onDragEnter(evt);
        }
      }}
      onDragLeave={(evt) => {
        if (!evt.dataTransfer.types.includes("Files")) return;
        evt.preventDefault();

        dragEventCount.current--;
        if (dragEventCount.current === 0) {
          onDragLeave && onDragLeave(evt);
        }
      }}
      onDragOver={(evt) => {
        evt.preventDefault();
      }}
      onDrop={(evt) => {
        if (!evt.dataTransfer.types.includes("Files")) return;
        evt.preventDefault();

        dragEventCount.current = 0;
        onDrop && onDrop(evt);
      }}
    >
      <CssBaseline />

      <Global
        styles={css`
          :root {
            --home-text-green: #00E49F;
            --home-text-gray: #1C2C27;
            --home-background-green: #182622;
          }
          
          body {
            overflow: hidden;
            min-width: ${noMinWidth ? "0" : "900px"}
            overflow-x: auto;
          }

          .monaco-editor .monaco-editor-overlaymessage {
            display: none !important;
          }
        `}
      />

      {navbar && <NavbarContainer>{navbar}</NavbarContainer>}

      <ContentContainer>
        <Content center={center} fullWidth={fullWidth} noPadding={noPadding}>
          {children}
        </Content>
        {footer && <div>{footer}</div>}
      </ContentContainer>
      <CookieBanner />
      <FeedbackBubble />
    </Container>
  );
};

export default Screen;
