import { Unstable_Grid2 as Grid } from "@mui/material";
import { Project as ProjectSchema } from "mml-editor-api-schema";
import * as React from "react";
import slugify from "slugify";

import ProjectCard from "~/components/ProjectCard";

const ProjectCollection = ({
  projects,
  forkable,
  rowLayout,
  onDelete,
  onFork,
}: {
  projects: ProjectSchema[];
  forkable?: boolean;
  rowLayout?: boolean;
  onDelete?: (project: ProjectSchema) => void;
  onFork?: (project: ProjectSchema) => void;
}) => {
  return (
    <Grid container spacing={rowLayout ? "16px" : "24px"} columns={12}>
      {projects.map((project) => (
        <Grid key={project.id} xs={rowLayout ? 12 : 4} lg={rowLayout ? 12 : 3}>
          <ProjectCard
            rowLayout={rowLayout}
            forkable={forkable}
            project={project}
            href={`${slugify(project.name, {
              lower: true,
            })}-${project.id}`}
            onDelete={() => onDelete && onDelete(project)}
            onFork={() => onFork && onFork(project)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ProjectCollection;
