import { MarkdownPage } from "~/types/markdownPage";

import body from "./body.mdx";

const Terms: MarkdownPage = {
  title: "Terms of use",
  body,
};

export default Terms;
