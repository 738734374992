import styled from "@emotion/styled";
import * as React from "react";
import { useState } from "react";

import PanelHeading from "~/screens/Editor/components/PanelHeading";
import { InspectorTextContainer } from "~/screens/Editor/panels/Inspector/index";

export const NewAttributeContainer = styled.div({
  display: "flex",
  gap: 10,
});
const NewAttributeInput = styled.input({
  background: "rgb(85, 85, 85)",
  display: "block",
  padding: "10px 6px",
  border: "none",
  outline: "none",
  color: "white",
  flex: 1,
  borderRadius: 4,
});
export const NewAttributeButton = styled.button({
  background: "rgb(85, 85, 85)",
  display: "block",
  border: "none",
  outline: "none",
  color: "white",
  flex: "0 0 40px",
  borderRadius: 4,
  cursor: "pointer",
  transition: "all 0.1s linear",

  "&:hover": {
    background: "rgb(128, 128, 128)",
    transition: "all 0.1s linear",
  },
});
const NewAttributeError = styled.div({
  color: "red",
  fontSize: 12,
  marginTop: 4,
});

export function NewAttributeSection(props: {
  handleAddAttribute: (k: string) => boolean;
}) {
  const [newAttribute, setNewAttribute] = useState("");
  const [newAttributeError, setNewAttributeError] = useState<string>("");

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewAttribute(e.target.value);
    setNewAttributeError("");
  };

  const onError = () => {
    setNewAttributeError(
      "Attribute name must start with a letter, colon or underscore and contain only letters, digits, colons, underscores, hyphens and periods",
    );
  };

  const addNewAttribute = () => {
    const hasSubmitted = props.handleAddAttribute(newAttribute);
    if (!hasSubmitted) {
      onError();
      return;
    }
    setNewAttribute("");
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      addNewAttribute();
    }
  };

  return (
    <>
      <InspectorTextContainer>
        <PanelHeading>Add Attribute</PanelHeading>
      </InspectorTextContainer>
      <NewAttributeContainer>
        <NewAttributeInput
          value={newAttribute}
          onChange={onChange}
          placeholder="Attribute name"
          onKeyDown={onKeyDown}
        />
        <NewAttributeButton onClick={addNewAttribute}>+</NewAttributeButton>
      </NewAttributeContainer>
      {newAttributeError && (
        <NewAttributeError>{newAttributeError}</NewAttributeError>
      )}
    </>
  );
}
