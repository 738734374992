import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import * as React from "react";

import LogMessage from "~/components/LogMessage";
import appState from "~/library/appState";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Container = styled.div(({ theme }) => ({
  flex: "1",
  display: "flex",
  backgroundColor: "#222222",
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LogsContainer = styled.div(({ theme }) => ({
  flex: "1",
  display: "flex",
  flexDirection: "column-reverse",
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    width: "9px",
    borderLeft: "1px solid #343434",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "rgba(121, 121, 121, 0.4)",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "rgba(100, 100, 100, 0.7)",
  },
  "&::-webkit-scrollbar-thumb:active": {
    background: "rgba(191, 191, 191, 0.4)",
  },
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const OlderLogsHiddenNotice = styled.div(({ theme }) => ({
  width: "100%",
  height: "50px",
  flexShrink: 0,
  display: "flex",
  padding: "16px",
  alignItems: "center",
  backgroundColor: "rgba(255, 255, 255, 0.05)",
  fontSize: "13px",
  color: "rgba(255, 255, 255, 0.7)",
  fontWeight: 500,
}));

const MAX_MESSAGES = 1000;

const DebugPanel = () => {
  const getLogMessage = (log: any) => {
    if (log.level === "restart") {
      return "Document restarted";
    } else if (log.level === "system") {
      return log.content[0].message;
    } else {
      return log.content.join(" ");
    }
  };

  const logs = appState.project?.logs.slice(0, MAX_MESSAGES) || [];

  return (
    <Container>
      <LogsContainer>
        {logs.map((log) => (
          <LogMessage
            key={log.timestamp + log.content.join("")}
            level={log.level}
            message={getLogMessage(log)}
          />
        ))}

        {(appState.project?.logs.length || 0) > MAX_MESSAGES && (
          <OlderLogsHiddenNotice>
            {`Only the last ${MAX_MESSAGES.toLocaleString()} log messages are shown.`}
          </OlderLogsHiddenNotice>
        )}
      </LogsContainer>
    </Container>
  );
};

export default {
  id: "debug",
  name: "Debug",
  Component: observer(DebugPanel),
};
