import { File } from "mml-editor-api-schema";
import { editor, Range as MonacoRange } from "monaco-editor";

import type { Range } from "~/library/diff";
import { OTClient } from "~/library/ot/client";

import Project from "./index";

export default class ProjectFile {
  project: Project;

  id: string;
  content: string;
  lastChangeNumber: number;

  onChange?: (content: string) => void;

  otClient?: OTClient;

  constructor(
    project: Project,
    fileData: File,
    onChange?: (content: string) => void,
  ) {
    this.project = project;

    this.id = fileData.id;
    this.content = fileData.content;

    this.onChange = onChange;
  }

  public init(
    content: string,
    lastChangeNumber: number,
    reset: boolean,
    clientSelections: {
      [id: string]: any;
    },
  ) {
    this.content = content;
    this.lastChangeNumber = lastChangeNumber;

    if (this.otClient) {
      // Dispose and reattach editor
      const monacoEditor = this.otClient.monaco;
      this.otClient.dispose();
      this.attachEditor(monacoEditor);

      // Set current cursors
      for (const clientId in clientSelections) {
        this.clientSelection(clientId, clientSelections[clientId]);
      }
    }
  }

  public getContent = () => {
    return this.content;
  };

  public applyDiff = (diff: Range[]) => {
    if (!this.otClient) return;

    const model: editor.ITextModel = this.otClient.monaco.getModel();

    for (const change of diff) {
      const from = model.getPositionAt(change.start);
      const to = model.getPositionAt(change.end);

      model.applyEdits([
        {
          range: new MonacoRange(
            from.lineNumber,
            from.column,
            to.lineNumber,
            to.column,
          ),
          text: change.value || "",
          forceMoveMarkers: true,
        },
      ]);
    }
  };

  public appendValue = (value: string) => {
    if (!this.otClient) return;

    const model: editor.ITextModel = this.otClient.monaco.getModel();

    const lineCount = model.getLineCount();
    const lastLineLength = model.getLineMaxColumn(lineCount);

    const range = new MonacoRange(
      lineCount,
      lastLineLength,
      lineCount,
      lastLineLength,
    );

    model.applyEdits([
      {
        range,
        text: value || "",
        forceMoveMarkers: true,
      },
    ]);
  };

  public changeAccepted(number: number) {
    this.lastChangeNumber = number;

    this.otClient?.serverAdapter.fileChangeAccepted();
  }

  public change(clientId: string, number: number, operation: any) {
    this.otClient?.serverAdapter.receiveChange(clientId, number, operation);
  }

  public clientSelection(clientId: string, cursor: any) {
    if (!this.otClient) return;

    const client = this.project.clients[clientId];
    this.otClient.serverAdapter.cursorChanged(
      clientId,
      client?.color,
      client?.user.name,
      cursor,
    );
  }

  public clientConnected() {
    // no-op
  }

  public clientDisconnected(clientId: string) {
    this.clientSelection(clientId, null);
  }

  public attachEditor = (monaco: any) => {
    this.otClient = new OTClient({
      editor: monaco,
      userId: this.project.clientId || "",
      onOperation: (revision: number, operation) => {
        this.sendClientChange(revision, operation);
      },
      onCursor: (cursor) => {
        this.sendClientCursor(cursor);
      },
    });

    // TODO: attach is called multiple time BUT monaco event listeners must not be added multiple times

    this.otClient.monaco.onDidChangeModelContent(() => {
      const newContent = this.otClient?.editorAdapter.getText();

      // Only trigger change if text actually changed
      if (newContent !== undefined && newContent !== this.content) {
        this.content = newContent;
        this.onChange?.(this.content);
      }

      // Force disable readOnly
      // TODO: This should be fixed in @otjs/monaco (monaco-adapter.ts -> _applyChangesToMonaco)
      setTimeout(() => {
        this.otClient?.monaco.updateOptions({
          readOnly: this.project.readOnly,
        });
      });
    });

    this.otClient.serverAdapter.init(this.content, this.lastChangeNumber);
  };

  public detachEditor = () => {
    if (this.otClient) {
      this.otClient.dispose();
      this.otClient = undefined;
    }
  };

  private sendClientChange = (number: number, operation: any) => {
    this.project.session?.v1.v1_requests_applyFileChange({
      fileId: this.id,
      number,
      operation,
    });
  };

  private sendClientCursor = (selection: any) => {
    this.project.session?.v1.v1_requests_setClientFileSelection({
      fileId: this.id,
      selection,
    });
  };
}
