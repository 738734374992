import {
  mdiApplicationImport,
  mdiCubeOutline,
  mdiCursorDefaultClickOutline,
  mdiCylinder,
  mdiDuck,
  mdiFormatText,
  mdiHelp,
  mdiHuman,
  mdiImageOutline,
  mdiLightbulbOnOutline,
  mdiMapMarkerOutline,
  mdiMessageReplyTextOutline,
  mdiSelectGroup,
  mdiSphere,
  mdiSquareOutline,
  mdiVideoOutline,
  mdiVolumeHigh,
} from "@mdi/js";
import {
  AddRounded,
  CheckRounded,
  CloseFullscreenRounded,
  CloseRounded,
  CloudUploadRounded,
  CodeRounded,
  ContentCopyRounded,
  DangerousRounded,
  DeleteRounded,
  EditRounded,
  ExpandMoreRounded,
  Feedback,
  ForkRightRounded,
  Google,
  GridViewRounded,
  HelpOutlineRounded,
  HomeRounded,
  InfoRounded,
  KeyboardArrowDownRounded,
  KeyboardArrowRightRounded,
  LogoutRounded,
  MenuBookRounded,
  MoreVertRounded,
  OpenInFullRounded,
  OpenInNewRounded,
  Publish,
  RestartAltRounded,
  SearchRounded,
  ShareRounded,
  SvgIconComponent,
  ViewListRounded,
  WarningRounded,
} from "@mui/icons-material";
import { createSvgIcon, SvgIcon } from "@mui/material";
import * as React from "react";

const iconFromPath = (p: string, name: string) =>
  createSvgIcon(<path d={p}></path>, name);

const ICON_MAP: Record<string, SvgIconComponent> = {
  arrowRight: KeyboardArrowRightRounded,
  arrowDown: KeyboardArrowDownRounded,
  menu: MoreVertRounded,
  collapse: CloseFullscreenRounded,
  expand: OpenInFullRounded,
  check: CheckRounded,
  add: AddRounded,
  google: Google,
  arrowMenu: ExpandMoreRounded,
  logout: LogoutRounded,
  edit: EditRounded,
  delete: DeleteRounded,
  home: HomeRounded,
  fork: ForkRightRounded,
  code: CodeRounded,
  listView: ViewListRounded,
  gridView: GridViewRounded,
  search: SearchRounded,
  docs: MenuBookRounded,
  share: ShareRounded,
  warning: WarningRounded,
  info: InfoRounded,
  error: DangerousRounded,
  log: KeyboardArrowRightRounded,
  copy: ContentCopyRounded,
  restart: RestartAltRounded,
  help: HelpOutlineRounded,
  upload: CloudUploadRounded,
  publish: Publish,
  openLink: OpenInNewRounded,
  feedback: Feedback,
  close: CloseRounded,
  "m-group": iconFromPath(mdiSelectGroup, "m-group"),
  "m-cube": iconFromPath(mdiCubeOutline, "m-cube"),
  "m-sphere": iconFromPath(mdiSphere, "m-sphere"),
  "m-cylinder": iconFromPath(mdiCylinder, "m-cylinder"),
  "m-light": iconFromPath(mdiLightbulbOnOutline, "m-light"),
  "m-plane": iconFromPath(mdiSquareOutline, "m-plane"),
  "m-model": iconFromPath(mdiDuck, "m-model"),
  "m-character": iconFromPath(mdiHuman, "m-character"),
  "m-frame": iconFromPath(mdiApplicationImport, "m-frame"),
  "m-audio": iconFromPath(mdiVolumeHigh, "m-audio"),
  "m-image": iconFromPath(mdiImageOutline, "m-image"),
  "m-video": iconFromPath(mdiVideoOutline, "m-video"),
  "m-label": iconFromPath(mdiFormatText, "m-label"),
  "m-position-probe": iconFromPath(mdiMapMarkerOutline, "m-position-probe"),
  "m-prompt": iconFromPath(mdiHelp, "m-prompt"),
  "m-interaction": iconFromPath(mdiCursorDefaultClickOutline, "m-interaction"),
  "m-chat-probe": iconFromPath(mdiMessageReplyTextOutline, "m-chat-probe"),
};

const Icon = ({
  icon,
  size,
  color,
  opacity,
  hoverColor,
}: {
  icon: string;
  size?: string;
  color?: string;
  opacity?: number;
  hoverColor?: string;
}) => {
  const Component = ICON_MAP[icon] || SvgIcon;

  return (
    <Component
      sx={{
        width: size || undefined,
        height: size || undefined,
        opacity: opacity || undefined,
        color: color || "white",
        transition: "all 0.1s",
        ...(hoverColor
          ? {
              "&:hover": {
                color: hoverColor,
              },
            }
          : {}),
      }}
    />
  );
};

export default Icon;
