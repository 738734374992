import styled from "@emotion/styled";
import { Box, IconButton } from "@mui/material";
import * as React from "react";

import Icon from "./Icon";

const Container = styled.div({
  display: "flex",
});

const ViewSwitch = ({
  listView,
  onChange,
}: {
  listView?: boolean;
  onChange?: (listView: boolean) => void;
}) => {
  return (
    <Container>
      <IconButton
        disableRipple
        size="small"
        disabled={!listView}
        onClick={() => onChange && onChange(false)}
      >
        <Icon icon="gridView" size="20px" opacity={!listView ? 1 : 0.5} />
      </IconButton>

      <Box width="4px" />

      <IconButton
        disableRipple
        size="small"
        disabled={listView}
        onClick={() => onChange && onChange(true)}
      >
        <Icon icon="listView" size="20px" opacity={listView ? 1 : 0.5} />
      </IconButton>
    </Container>
  );
};

export default ViewSwitch;
