import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import * as React from "react";

import Icon from "./Icon";

const MESSAGE_TYPES = {
  log: {
    icon: "log",
    contentColor: "#B8B8B8",
    backgroundColor: "#222222",
  },
  info: {
    icon: "info",
    contentColor: "#77A5FF",
    backgroundColor: "#252E40",
  },
  warn: {
    icon: "warning",
    contentColor: "#EB8D2F",
    backgroundColor: "#2D2600",
  },
  error: {
    icon: "error",
    contentColor: "#E05E57",
    backgroundColor: "#240000",
  },
  system: {
    icon: "error",
    contentColor: "#E05E57",
    backgroundColor: "#240000",
  },
  restart: {
    icon: "restart",
    contentColor: "#00E49F",
    backgroundColor: "#1C2C27",
  },
};

const Container = styled.div<{ color: string }>(({ color }) => ({
  width: "100%",
  padding: "4px 16px 4px 10px",
  display: "flex",
  alignItems: "center",
  backgroundColor: color,
  borderTop: "1px solid #444444",
}));

const LevelContainer = styled.div({
  height: "100%",
  width: "22px",
  display: "flex",
  flexShrink: 0,
});

const Message = styled(Typography)({
  flex: 1,
  fontSize: "13px",
  fontFamily: 'Menlo, Monaco, "Courier New", monospace',
});

const LogMessage = ({
  level,
  message,
}: {
  level: "log" | "warn" | "error" | "info" | "system" | "restart";
  message: string;
}) => {
  const style = MESSAGE_TYPES[level];

  return (
    <Container color={style.backgroundColor}>
      <LevelContainer>
        <Icon icon={style.icon} color={style.contentColor} size="16px" />
      </LevelContainer>
      <Message color={style.contentColor}>{message}</Message>
    </Container>
  );
};

export default LogMessage;
