import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { Object3D } from "three";

import appState from "~/library/appState";
import MMLWebClient from "~/library/mml/client";
import { getIframeTargetWindow } from "~/library/mml/iframeTarget";
import { defaultEditScene } from "~/library/mml/sceneConfig";
import { MMLStaticScene } from "~/library/mml/staticScene";

import TransformWidget from "./transformWidget";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Container = styled.div(({ theme }) => ({
  flex: "1",
  backgroundColor: "#222222",
  overflow: "hidden",
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ClientView = styled.div(({ theme }) => ({
  width: "100%",
  height: "100%",
}));

const EditPanel = () => {
  const [client, setClient] = useState<MMLWebClient | null>(null);

  const elementRef = useRef<HTMLDivElement>(null);

  const [transformWidget, setTransformWidget] =
    useState<TransformWidget | null>(null);

  const fitContainer = () => {
    client?.fitContainer();
  };

  const onMouseUp = () => {
    //
  };

  const onWindowBlur = () => {
    //
  };

  const setupTransformWidget = (
    scene: MMLStaticScene,
    elementHolder: HTMLElement,
  ) => {
    const widget = new TransformWidget(
      scene,
      (controlsEnabled: boolean) => {
        scene.setControlsEnabled(controlsEnabled);
      },
      scene.element,
      elementHolder,
      () => {
        // no-op
      },
      (element: HTMLElement, attributes) => {
        appState.project?.updateElement(element, attributes);
      },
      (elements: Array<HTMLElement>) => {
        appState.project?.deleteElements(elements);
      },
      (elements: Array<HTMLElement> | null) => {
        appState.project?.setSelectedElements(elements);
      },
    );
    setTransformWidget(widget);
  };

  useEffect(() => {
    let disposed = false;
    let runnerClient: MMLWebClient | null = null;
    let remoteHolderElementBodyInterval: NodeJS.Timeout;

    getIframeTargetWindow().then((wrapper) => {
      if (!appState.project || disposed) return;

      let remoteHolderElement =
        wrapper.iframeDocument.getElementById("edit-panel-holder");
      if (!remoteHolderElement) {
        remoteHolderElement = wrapper.iframeDocument.createElement("div");
        remoteHolderElement.id = "edit-panel-holder";
        wrapper.iframeDocument.body.append(remoteHolderElement);
      }

      runnerClient = new MMLWebClient(
        wrapper.iframeWindow,
        remoteHolderElement,
        false,
      );

      // NOTE FIX: this could be optimised if body tag stayed constant
      remoteHolderElementBodyInterval = setInterval(() => {
        if (!disposed && appState.project && remoteHolderElement) {
          appState.project.elementHolder =
            remoteHolderElement.querySelector("body") || undefined;
        }
      }, 50);

      defaultEditScene(runnerClient.mScene);
      setupTransformWidget(
        runnerClient.mScene as MMLStaticScene,
        remoteHolderElement,
      );
      setClient(runnerClient);
    });

    return () => {
      disposed = true;
      if (runnerClient) {
        clearInterval(remoteHolderElementBodyInterval);
        runnerClient.dispose();
        setClient(null);
      }
    };
  }, [appState.project]);

  useEffect(() => {
    if (transformWidget) {
      if (appState.project?.connected) {
        transformWidget.enable();
      } else {
        transformWidget.disable();
      }
    }
  }, [transformWidget, appState.project?.connected]);

  useEffect(() => {
    if (transformWidget) {
      transformWidget.select(appState.project?.selectedElements);
    }
  }, [transformWidget, appState.project?.selectedElements]);

  useEffect(() => {
    window.addEventListener("resize", fitContainer);
    window.addEventListener("editor-layout", fitContainer);

    return () => {
      window.removeEventListener("resize", fitContainer);
      window.removeEventListener("editor-layout", fitContainer);
    };
  }, [client]);

  useEffect(() => {
    window.addEventListener("blur", onWindowBlur);
    window.addEventListener("mouseup", onMouseUp);

    return () => {
      window.removeEventListener("blur", onWindowBlur);
      window.removeEventListener("mouseup", onMouseUp);

      transformWidget?.deselect();
    };
  }, [transformWidget]);

  const onDrop = (evt: React.DragEvent<HTMLElement>) => {
    if (!appState.project?.clientId) return;

    // Only handle dropping plain text items, e.g. assets
    if (evt.dataTransfer.items?.[0]?.type !== "text/plain") return;

    // Ensure that client is loaded
    if (!client?.mScene || !client.remoteDocumentWrapper?.remoteDocument)
      return;

    // Ensure transform widget is set
    if (!transformWidget) return;

    // Get value of dropped item
    evt.dataTransfer.items[0].getAsString(async (value) => {
      // Create new element
      const containerElem = document.createElement("div");
      containerElem.innerHTML = value;
      const mmlElem = containerElem.children[0] as HTMLElement;

      // Set position of object to be in the middle of the current edit view
      const camera = client.mScene.getCamera();
      const helperObject = new Object3D();
      helperObject.position.copy(camera.position);
      helperObject.rotation.copy(camera.rotation);
      helperObject.translateZ(-10);
      mmlElem.setAttribute("x", helperObject.position.x.toFixed(0));
      mmlElem.setAttribute("z", helperObject.position.z.toFixed(0));
      // NOTE: place objects at y=0, uncomment to position object in center of viewport
      // mmlElem.setAttribute('y', helperObject.position.y.toFixed(0))

      // Insert to file and get back the inserted instance of the element
      const insertedElem = await appState.project?.insertElement(mmlElem);
      if (insertedElem) {
        appState.project?.setSelectedElements([insertedElem]);
      }
    });
  };

  useEffect(() => {
    if (elementRef.current && client) {
      elementRef.current.appendChild(client.element);
      fitContainer();
    }
  }, [elementRef.current, client]);

  useEffect(() => {
    if (client && appState.project) {
      client.connectToDocument(appState.project.staticDocument);
    }
  }, [client, appState.project]);

  return (
    <Container onDrop={onDrop}>
      <ClientView ref={elementRef} />
    </Container>
  );
};

export default {
  id: "edit",
  name: "Edit",
  Component: observer(EditPanel),
};
