import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  adjectives,
  animals,
  colors,
  uniqueNamesGenerator,
} from "unique-names-generator";

import Footer from "~/components/Footer";
import Navbar from "~/components/Navbar";
import ProjectCard from "~/components/ProjectCard";
import Screen from "~/components/Screen";
import appState from "~/library/appState";
import { projectSlug } from "~/library/projectSlug";
import serverApi from "~/library/serverApi/serverApi";
import { forkProjectModal } from "~/modals";
import MidSectionElementRender from "~/screens/Home/components/MidSectionElement";
import {
  CreateProjectButton,
  ExamplesContainer,
  ExamplesSection,
  HomePage,
  LearnMMLLink,
  LearnMMLLinkContainer,
  LogInLink,
  MidSection,
  SecondaryText,
  TomSectionTopImageOpacityOverlay,
  TopSection,
  TopSectionBottomImage,
  TopSectionBottomImageContainer,
  TopSectionBottomImageOpacityOverlay,
  TopSectionButtonsContainer,
  TopSectionMainText,
  TopSectionSubText,
  TopSectionTopImage,
  TopSectionTopImageContainer,
} from "~/screens/Home/components/styledComponents";

import examples from "./examples.json";

const Home = () => {
  const navigate = useNavigate();

  const createProject = async () => {
    // Generate random name
    const projectName = uniqueNamesGenerator({
      dictionaries: [adjectives, colors, animals],
      separator: "-",
    });

    // Create real project
    if (appState.user) {
      const res = await serverApi.createProject({
        parameters: {},
        body: {
          name: projectName,
        },
      });
      if (res.code === 200) {
        navigate(
          `/projects/${projectSlug({ name: projectName, id: res.body.id })}`,
        );
      }
    }

    // Open temporary project
    else {
      navigate("/projects/temporary");
    }
  };

  const forkProject = (projectId: string) => {
    forkProjectModal({
      projectId,
    });
  };

  return (
    <Screen navbar={<Navbar />} footer={<Footer />} noPadding noMinWidth>
      <HomePage>
        <TopSection>
          <TopSectionMainText>
            Create <span>METAVERSAL</span> objects
          </TopSectionMainText>
          <TopSectionSubText>
            Build live, shareable objects and interactive experiences with{" "}
            <a href="https://www.mml.io">Metaverse Markup Language</a> and
            simple drag & drop.
          </TopSectionSubText>
          <TopSectionButtonsContainer>
            {appState.user ? (
              <CreateProjectButton onClick={createProject}>
                Create project
              </CreateProjectButton>
            ) : (
              <LogInLink href={`/auth/login/google`}>Create Project</LogInLink>
            )}
            <LearnMMLLink href="https://www.mml.io">Learn MML</LearnMMLLink>
          </TopSectionButtonsContainer>
          <TopSectionTopImageContainer>
            <TopSectionTopImage src="/images/home/top-image.jpeg" />
            <TomSectionTopImageOpacityOverlay />
          </TopSectionTopImageContainer>
          <TopSectionBottomImageContainer>
            <TopSectionBottomImage src="/images/home/top-image.jpeg" />
            <TopSectionBottomImageOpacityOverlay />
          </TopSectionBottomImageContainer>
        </TopSection>
        <MidSection>
          <SecondaryText>
            {`Build fast with the fusion of
`}
            <span>visual 3D composition</span> and <span>code</span>
          </SecondaryText>
          <MidSectionElementRender
            icon="/images/home/cube-icon.svg"
            title="Import 3D models"
            description="Simply drag & drop your GLBs into the editor."
            image="/images/home/mid-section-1.jpeg"
          />
          <MidSectionElementRender
            icon="/images/home/axis-arrow-icon.svg"
            title="Compose objects visually"
            description="Move, scale, and rotate objects using a simple 3D editor."
            image="/images/home/mid-section-2.jpeg"
            isInverted
          />
          <MidSectionElementRender
            icon="/images/home/tags-icon.svg"
            title="Take control with HTML and JavaScript"
            description="Use the power of MML to create objects with familiar technologies."
            image="/images/home/mid-section-3.jpeg"
          >
            <LearnMMLLinkContainer href="https://www.mml.io">
              Learn MML
            </LearnMMLLinkContainer>
          </MidSectionElementRender>
          <MidSectionElementRender
            icon="/images/home/web-clock-icon.svg"
            title="See your work in real time"
            description="Your creations are always up to date, everywhere."
            image="/images/home/mid-section-4.jpeg"
            isInverted
          />
        </MidSection>
        <ExamplesSection>
          <SecondaryText>
            Get started with <span>examples</span>
          </SecondaryText>
          <div style={{ marginTop: 100, marginBottom: 150 }}>
            <ExamplesContainer>
              {examples.map((example) => (
                <ProjectCard
                  deletable={false}
                  key={example.id}
                  project={example}
                  href={`/projects/${projectSlug(example)}`}
                  onFork={() => {
                    forkProject(example.id);
                  }}
                  forkable={!!appState.user}
                />
              ))}
            </ExamplesContainer>
          </div>
        </ExamplesSection>
      </HomePage>
    </Screen>
  );
};

export default Home;
