import styled from "@emotion/styled";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";

import PlayView from "~/components/PlayView";
import appState from "~/library/appState";
import { dataUrlToFile } from "~/library/fileUtils";
import MMLWebClient from "~/library/mml/client";
import { serverApiUpdateThumbnail } from "~/library/serverApi/serverApiUpdateThumbnail";

const PlayViewContainer = styled.div({
  position: "relative",
  width: "100%",
  height: "0",
  paddingBottom: "56%",
  overflow: "hidden",
  borderRadius: "4px",
});

const PlayViewContent = styled.div({
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: "flex",
});

const UpdateProjectThumbnailModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [client, setClient] = useState<MMLWebClient>();

  if (!appState.project) return null;

  const project = appState.project;

  const updateProjectThumbnail = async () => {
    if (!client) return;

    const screenshot = client.getScreenshot();
    const file = await dataUrlToFile(screenshot, "thumbnail.png");

    const res = await serverApiUpdateThumbnail({
      projectId: project.id,
      asset: file,
    });
    if (res.code === 200) {
      project.thumbnailUrl = res.body.thumbnailUrl;
      onClose();
    }
  };

  const socketUrl = `${window.serverConfig.EXECUTION_URL}/${appState.project.id}`;

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Update thumbnail</DialogTitle>

      <DialogContent>
        <Stack direction="column" spacing={2}>
          <Typography fontSize={13}>
            Adjust the view below as desired and click &apos;Update&apos; to set
            it as the thumbnail.
          </Typography>

          <PlayViewContainer>
            <PlayViewContent>
              <PlayView url={socketUrl} onClientCreated={setClient} />
            </PlayViewContent>
          </PlayViewContainer>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ padding: "0 24px 20px 24px" }}>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={updateProjectThumbnail}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateProjectThumbnailModal;
