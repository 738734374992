import styled from "@emotion/styled";
import { Button } from "@mui/material";
import * as React from "react";

import { copyToClipboard } from "~/library/clipboard";

import Icon from "./Icon";

const Container = styled.div({
  position: "relative",
  width: "100%",
  display: "flex",
});

const InputHolder = styled.div({
  position: "relative",
  boxSizing: "border-box",
  height: "40px",
  marginRight: "6px",
  flexGrow: 1,
  borderRadius: "4px",
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  border: "1px solid rgba(0, 0, 0, 1)",
  transition: "background-color 0.1s",

  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.9)",
  },
});

const Field = styled.input({
  boxSizing: "border-box",
  height: "40px",
  width: "100%",
  padding: "0 10px",
  fontSize: "16px",
  fontWeight: "500",
  backgroundColor: "transparent",
  outline: "none",
  border: "none",
  color: "rgba(0, 0, 0, 0.8)",
});

const CopyButtonContainer = styled.div({
  display: "flex",
  alignItems: "center",
  zIndex: "1",
});

const CopyButton = styled(Button)(() => ({
  height: "40px",
})) as typeof Button;

const URLField = ({ url }: { url: string }) => {
  return (
    <Container>
      <InputHolder>
        <Field
          value={url}
          readOnly
          onClick={(e) => {
            e.stopPropagation();

            const target = e.target as HTMLInputElement;
            target.focus();
            target.setSelectionRange(0, target.value.length);
          }}
        />
      </InputHolder>
      <CopyButtonContainer>
        <CopyButton
          variant="contained"
          startIcon={<Icon icon="copy" />}
          onClick={() => {
            copyToClipboard(url);
          }}
        >
          Copy
        </CopyButton>
      </CopyButtonContainer>
    </Container>
  );
};

export default URLField;
