import * as React from "react";
import { Navigate } from "react-router-dom";

import config from "~/config";
import Editor from "~/screens/Editor";
import Explore from "~/screens/Explore";
import Home from "~/screens/Home";
import Markdown from "~/screens/Markdown";
import Play from "~/screens/Play";
import Projects from "~/screens/Projects";
import Redirect from "~/screens/Redirect";

const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "projects",
    element: <Projects />,
  },
  {
    path: "projects/:projectId",
    element: <Editor />,
  },
  {
    path: "projects/:projectId/play",
    element: <Play />,
  },
  {
    path: "privacy",
    element: <Redirect redirectTo="improbablePrivacy" />,
  },
  {
    path: "terms",
    element: <Markdown contentKey="terms" />,
  },
];

if (config.features.explorePage) {
  routes.push({
    path: "explore",
    element: <Explore />,
  });
}

routes.push({
  path: "*",
  element: <Navigate to="/" replace />,
});

export default routes;
