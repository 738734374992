import styled from "@emotion/styled";
import Tooltip from "@mui/material/Tooltip";
import * as React from "react";
import { ChangeEvent, useLayoutEffect, useState } from "react";

import Icon from "~/components/Icon";
import { ToolTipIcon } from "~/screens/Editor/panels/Inspector/InspectorInput";

const InspectorSelectContainer = styled.div<{ isError: boolean }>(
  ({ isError }) => ({
    alignItems: "flex-start",
    padding: "20px 10px 6px",
    position: "relative",
    gap: 5,
    flex: 1,
    display: "flex",
    background: "rgb(85, 85, 85)",
    borderRadius: 4,
    boxSizing: "border-box",
    border: `1px solid ${isError ? "red" : "transparent"}`,
  }),
);

const InspectorSelectTitleLabel = styled.span({
  position: "absolute",
  top: 1,
  left: 10,
  fontSize: 11,
});

const InspectorSelectElement = styled.select({
  display: "block",
  width: "100%",
  color: "white",
  textAlign: "left",
  background: "#808080",
  border: "none",
});

const InspectorSelectOption = styled.option({
  listStyle: "none",
  cursor: "pointer",
  fontFamily: "monospace",
  padding: 0,
});

const isInputError = (changedValue: string, options: string[]) =>
  !!changedValue && !options.includes(changedValue);

export default function InspectorSelectInput({
  attribute,
  value,
  description,
  updateValue,
  options,
}: {
  attribute: string;
  value: string;
  updateValue: (value: string | null) => void;
  description?: string;
  options: string[];
}) {
  const [isError, setIsError] = useState(false);

  useLayoutEffect(() => {
    const shouldSetError = isInputError(value, options);
    setIsError(shouldSetError);
  }, []);

  const handleSegmentedControl = (event: ChangeEvent<HTMLSelectElement>) => {
    if (!event) return;
    const changedValue = event.target?.value;

    setIsError(isInputError(changedValue, options));
    updateValue(changedValue);
  };

  return (
    <InspectorSelectContainer isError={isError}>
      <InspectorSelectTitleLabel>{attribute}</InspectorSelectTitleLabel>
      <InspectorSelectElement
        value={value || "unset"}
        // Couldn't find a way to make TS happy
        onChange={handleSegmentedControl as any}
      >
        <>
          <InspectorSelectOption value="">unset</InspectorSelectOption>
          {options.map((option) => (
            <InspectorSelectOption key={option} value={option}>
              {option}
            </InspectorSelectOption>
          ))}
        </>
      </InspectorSelectElement>
      {description && (
        <Tooltip title={description} placement="top" style={{ zIndex: 1 }}>
          <ToolTipIcon>
            <Icon icon="info" size="14px" color="#808080" />
          </ToolTipIcon>
        </Tooltip>
      )}
    </InspectorSelectContainer>
  );
}
