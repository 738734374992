import styled from "@emotion/styled";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import * as React from "react";

import PanelGroup from "./PanelGroup";
import type { Panel } from "../panels";

const ENABLE_LEFT_SIDEBAR = true;
const ENABLE_RIGHT_SIDEBAR = true;

const RootContainer = styled.div({
  flex: "1",
  display: "flex",
  flexDirection: "row",
  alignItems: "stretch",
});

const SidebarContainer = styled.div({
  position: "relative",
  display: "flex",
  flexDirection: "row",
  flexShrink: "0",
  overflow: "hidden",
});

const CenterContainer = styled.div({
  flex: "1",
  display: "flex",
  flexDirection: "column",
  borderLeft: "1px solid #444444",
  borderRight: "1px solid #444444",
  overflow: "hidden",
});

const Layout = ({
  leftPanels,
  centerTopPanels,
  centerBottomPanels,
  rightPanels,
}: {
  leftPanels: Panel[];
  centerTopPanels: Panel[];
  centerBottomPanels: Panel[];
  rightPanels: Panel[];
}) => {
  const [leftPanelGroupCollapsed, setLeftPanelGroupCollapsed] = useState(false);
  const [leftPanelGroupTab, setLeftPanelGroupTab] = useState(0);

  const [rightPanelGroupCollapsed, setRightPanelGroupCollapsed] =
    useState(false);
  const [rightPanelGroupTab, setRightPanelGroupTab] = useState(0);

  const centerContainerRef = useRef<any>();
  const [
    centerPanelGroupsSplitViewAllowed,
    setCenterPanelGroupsSplitViewAllowed,
  ] = useState(true);

  const [centerTopPanelGroupCollapsed, setCenterTopPanelGroupCollapsed] =
    useState(false);
  const [centerTopPanelGroupSplitView, setCenterTopPanelGroupSplitView] =
    useState(true);
  const [centerTopPanelGroupTab, setCenterTopPanelGroupTab] = useState(0);

  const [centerBottomPanelGroupCollapsed, setCenterBottomPanelGroupCollapsed] =
    useState(false);
  const [centerBottomPanelGroupSplitView, setCenterBottomPanelGroupSplitView] =
    useState(true);
  const [centerBottomPanelGroupTab, setCenterBottomPanelGroupTab] = useState(0);

  const loadFlag = (key: string) => {
    return localStorage.getItem(key) === "true";
  };

  const saveFlag = (key: string, value: boolean, onlyIfNotSet?: boolean) => {
    if (onlyIfNotSet) if (localStorage.getItem(key) !== null) return;

    localStorage.setItem(key, value ? "true" : "false");
  };

  const loadState = () => {
    // Init flags
    saveFlag("centerTopPanelGroupSplitView", true, true);
    saveFlag("centerBottomPanelGroupSplitView", true, true);

    setLeftPanelGroupCollapsed(loadFlag("leftPanelGroupCollapsed"));
    setRightPanelGroupCollapsed(loadFlag("rightPanelGroupCollapsed"));

    setCenterTopPanelGroupCollapsed(loadFlag("centerTopPanelGroupCollapsed"));
    setCenterTopPanelGroupSplitView(loadFlag("centerTopPanelGroupSplitView"));

    setCenterBottomPanelGroupCollapsed(
      loadFlag("centerBottomPanelGroupCollapsed"),
    );
    setCenterBottomPanelGroupSplitView(
      loadFlag("centerBottomPanelGroupSplitView"),
    );
  };

  const saveState = () => {
    saveFlag("leftPanelGroupCollapsed", leftPanelGroupCollapsed);
    saveFlag("rightPanelGroupCollapsed", rightPanelGroupCollapsed);

    saveFlag("centerTopPanelGroupCollapsed", centerTopPanelGroupCollapsed);
    saveFlag("centerTopPanelGroupSplitView", centerTopPanelGroupSplitView);

    saveFlag(
      "centerBottomPanelGroupCollapsed",
      centerBottomPanelGroupCollapsed,
    );
    saveFlag(
      "centerBottomPanelGroupSplitView",
      centerBottomPanelGroupSplitView,
    );
  };

  const onResize = () => {
    setCenterPanelGroupsSplitViewAllowed(
      centerContainerRef.current.clientWidth > 700,
    );
  };

  const dispatchLayoutChange = () => {
    window.dispatchEvent(new CustomEvent("editor-layout"));
  };

  useLayoutEffect(() => {
    loadState();
    onResize();

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(() => {
    saveState();
    dispatchLayoutChange();
  }, [
    leftPanelGroupCollapsed,
    leftPanelGroupTab,
    rightPanelGroupCollapsed,
    rightPanelGroupTab,
    centerTopPanelGroupCollapsed,
    centerTopPanelGroupSplitView,
    centerTopPanelGroupTab,
    centerBottomPanelGroupCollapsed,
    centerBottomPanelGroupSplitView,
    centerBottomPanelGroupTab,
  ]);

  return (
    <RootContainer>
      {ENABLE_LEFT_SIDEBAR && (
        <SidebarContainer>
          <PanelGroup
            panels={leftPanels}
            width={`260px`}
            collapseWidth
            allowCollapse
            collapse={leftPanelGroupCollapsed}
            selectedTab={leftPanelGroupTab}
            onCollapse={() => setLeftPanelGroupCollapsed(true)}
            onExpand={() => setLeftPanelGroupCollapsed(false)}
            onTabChange={(tab) => setLeftPanelGroupTab(tab)}
          />
        </SidebarContainer>
      )}

      <CenterContainer ref={centerContainerRef}>
        <PanelGroup
          panels={centerTopPanels}
          allowSplitView={centerPanelGroupsSplitViewAllowed}
          allowCollapse
          height={"100%"}
          collapse={centerTopPanelGroupCollapsed}
          selectedTab={centerTopPanelGroupTab}
          onCollapse={() => {
            setCenterTopPanelGroupCollapsed(true);
            if (centerBottomPanelGroupCollapsed) {
              setCenterBottomPanelGroupCollapsed(false);
            }
          }}
          onExpand={() => setCenterTopPanelGroupCollapsed(false)}
          splitView={
            centerPanelGroupsSplitViewAllowed && centerTopPanelGroupSplitView
          }
          onSplitView={() => setCenterTopPanelGroupSplitView(true)}
          onTabView={() => setCenterTopPanelGroupSplitView(false)}
          onTabChange={(tab) => setCenterTopPanelGroupTab(tab)}
        />
        <PanelGroup
          panels={centerBottomPanels}
          allowSplitView={centerPanelGroupsSplitViewAllowed}
          allowCollapse
          height={"100%"}
          collapse={centerBottomPanelGroupCollapsed}
          selectedTab={centerBottomPanelGroupTab}
          onCollapse={() => {
            setCenterBottomPanelGroupCollapsed(true);
            if (centerTopPanelGroupCollapsed) {
              setCenterTopPanelGroupCollapsed(false);
            }
          }}
          onExpand={() => setCenterBottomPanelGroupCollapsed(false)}
          splitView={
            centerPanelGroupsSplitViewAllowed && centerBottomPanelGroupSplitView
          }
          onSplitView={() => setCenterBottomPanelGroupSplitView(true)}
          onTabView={() => setCenterBottomPanelGroupSplitView(false)}
          onTabChange={(tab) => setCenterBottomPanelGroupTab(tab)}
        />
      </CenterContainer>

      {ENABLE_RIGHT_SIDEBAR && (
        <SidebarContainer>
          <PanelGroup
            panels={rightPanels}
            width={`260px`}
            collapseWidth
            allowCollapse
            collapse={rightPanelGroupCollapsed}
            selectedTab={rightPanelGroupTab}
            onCollapse={() => setRightPanelGroupCollapsed(true)}
            onExpand={() => setRightPanelGroupCollapsed(false)}
            onTabChange={(tab) => setRightPanelGroupTab(tab)}
          />
        </SidebarContainer>
      )}
    </RootContainer>
  );
};

export default Layout;
