export const elementToPath = (root: HTMLElement, element: HTMLElement) => {
  const path = [];
  let currentElement: HTMLElement | undefined = element;
  while (currentElement && currentElement !== root) {
    const parentChildren = Array.from(
      currentElement?.parentElement?.children || [],
    );
    path.push(parentChildren.indexOf(currentElement));
    currentElement = currentElement.parentElement || undefined;
  }
  return path.reverse();
};

export const pathToElement = (root: HTMLElement, path: Array<number>) => {
  let currentElement: HTMLElement | undefined = root;
  path.forEach((i) => {
    currentElement = currentElement?.children[i] as HTMLElement;
  });
  return currentElement;
};
