import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useState } from "react";

import Icon from "~/components/Icon";
import appState from "~/library/appState";
import { CLIENT_TYPES, ClientType } from "~/types/playClients";

const IconContainer = styled.button(({ disabled }) => ({
  height: "100%",
  display: "flex",
  alignContent: "center",
  flexWrap: "wrap",
  cursor: "pointer",
  opacity: disabled ? 0.3 : 1,
  backgroundColor: "transparent",
  border: "none",
}));

const DropdownContainer = styled.div({
  position: "absolute",
  right: 1,
  top: "calc(100% + 2px)",
  borderRadius: "4px",
  border: "1px solid rgb(68, 68, 68)",
  zIndex: 10,
  backgroundColor: "rgb(48, 48, 48)",
  width: 130,
});

const DropdownButton = styled.button({
  display: "block",
  width: "100%",
  backgroundColor: "transparent",
  padding: "10px",
  border: "none",
  color: "white",
  cursor: "pointer",
});

const DropdownButtonAvatar = styled(DropdownButton)({
  borderTop: "1px solid rgb(68, 68, 68)",
});

function AddNewButton() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const isDisabled = (appState.project?.playClients?.length ?? 0) >= 4;

  function handleDropdownClick() {
    if (isDisabled) return;

    setIsDropdownOpen((v) => !v);
  }

  function handleAddNewClient(type: ClientType) {
    appState.project?.addNewClient(type);
    setIsDropdownOpen(false);
  }

  return (
    <>
      <IconContainer
        aria-disabled={isDisabled}
        disabled={isDisabled}
        onClick={handleDropdownClick}
      >
        <Icon icon="add" size="20px" />
      </IconContainer>
      {isDropdownOpen && (
        <DropdownContainer>
          <DropdownButton
            onClick={() => handleAddNewClient(CLIENT_TYPES.FLOATING)}
          >
            New client
          </DropdownButton>
          <DropdownButtonAvatar
            onClick={() => handleAddNewClient(CLIENT_TYPES.AVATAR)}
          >
            New Avatar client
          </DropdownButtonAvatar>
        </DropdownContainer>
      )}
    </>
  );
}

export default observer(AddNewButton);
