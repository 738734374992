import styled from "@emotion/styled";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import * as React from "react";

import appState from "~/library/appState";

const CustomText = styled(DialogContentText)({
  a: {
    color: "white",
  },
});

const Notice = styled.div({
  width: "100%",
  padding: "10px",
  backgroundColor: "#2D2600",
  display: "flex",
  alignItems: "center",
  borderRadius: "4px",
});

const NoticeText = styled(DialogContentText)({
  color: "#EB8D2F",
  fontWeight: 500,
  fontSize: "14px",
});

const BroadcastingModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  if (!appState.project) return null;

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Broadcasting live MML document</DialogTitle>

      <DialogContent>
        <Stack spacing={3}>
          <Notice>
            <NoticeText>
              This document is executing{" "}
              <em>
                <strong>in your browser</strong>
              </em>
              . Keep this window/tab open and visible on your screen to ensure
              continuous and performant document execution.
            </NoticeText>
          </Notice>

          <Stack spacing={1}>
            <Typography>What is it?</Typography>
            <CustomText fontSize={13}>
              When you see the &apos;Broadcasting live document&apos; badge, the
              current MML Editor window is executing the live MML document that
              is accessible via the WebSocket URL.
            </CustomText>
          </Stack>

          <Stack spacing={1}>
            <Typography>How does it work?</Typography>
            <CustomText fontSize={13}>
              Your browser executes your MML document and forwards all events
              and state changes to the other connected clients in real time. If
              you close this window, execution will be terminated unless you
              have another window with the same project open.
            </CustomText>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default observer(BroadcastingModal);
